import { gql } from "@apollo/client";
import { useContactIds } from "components/data/session-user/SessionUser.js";
import { sortBy } from "lodash";
import useStdQuery from '../hooks/useStdQuery';
import WSCustomerContactNotificationPreferenceUpdate from "server/api-types/WSCustomerContactNotificationPreferenceUpdate";
import WSCustomerContactNotificationPreferenceInfo from "server/api-types/WSCustomerContactNotificationPreferenceInfo";

export const GET_NOTIFICATION_PREFERENCES = gql`
	query getPreferences(
		$managedCustomerId: ID
		$managedContactId: ID
	) {
		notificationsPreferences(
			managedCustomerId: $managedCustomerId
			managedContactId: $managedContactId
			) {
			smsPhone
			firstName
			contactId
			preferences {
				notificationDescription
				category {
					name
					description
				}
				subCategory {
					name
					description
				}
				notificationType
				optIn
				channels {
					channel
					enabled
					reqType
				}
			}
		}
	}
`;

export const UPDATE_NOTIFICATION_PREFERENCES = gql`
	mutation updatePreference(
		$contactId: ID
		$managedCustomerId: ID
		$phoneNumber: String
		$enabled: Boolean
		$notificationChannel: String
		$notificationTypeKeys: [ String! ]
	){
		updateNotifications(
			contactId: $contactId
			managedCustomerId: $managedCustomerId
			enabled: $enabled
			notificationTypeKeysList: $notificationTypeKeys
			phoneNumber: $phoneNumber
			notificationChannel: $notificationChannel
		)
	}
`;


// By default we will load the current customers notification preferences
export const useNotificationPreferences = (managedCustomerId = null, managedContactId = null) => {
	const queryResult = useStdQuery(GET_NOTIFICATION_PREFERENCES, { variables: { managedCustomerId, managedContactId } });
	const { contactIds: loggedInCustomerContactIds } = useContactIds();

	const wSCustomerContactNotificationPreferenceInfo = queryResult?.data?.notificationsPreferences?.map(pref => new WSCustomerContactNotificationPreferenceInfo(pref));

	const filteredCustomerContactNotificationPreferenceInfos = wSCustomerContactNotificationPreferenceInfo?.filter(
		({ contactId: notificationPrefContactId }) => loggedInCustomerContactIds !== notificationPrefContactId,
	);

	return {
		wSCustomerContactNotificationPreferenceInfo: filteredCustomerContactNotificationPreferenceInfos,
		queryResult,
	};
};
