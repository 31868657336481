import React from 'react';
import StandardMutation from 'components/data/StandardMutation.js';
import {
	GET_NOTIFICATION_PREFERENCES,
	UPDATE_NOTIFICATION_PREFERENCES,
	useNotificationPreferences,
} from "components/data/notification-preferences/NotificationPreferences.query.js";

import PendingChangesPrompt from 'components/PendingChangesPrompt';
import NotificationPreferences from 'components/account/panels/NotificationPreferences.js';
import { ComponentLoading } from 'components/icons/LoadingIcon';


const ManagedAccountNotifications = ({
	managedCustomerLinkInfo :{
		managedCustomerId,
		managedContactId,
	},
}) => {

	const {
		wSCustomerContactNotificationPreferenceInfo,
		queryResult: prefQueryResult,
	} = useNotificationPreferences(managedCustomerId, managedContactId);

	if (prefQueryResult.loading) {
		return <ComponentLoading />;
	}

	return (
		<StandardMutation
			mutation={UPDATE_NOTIFICATION_PREFERENCES}
			refetchQueries={[ {	query: GET_NOTIFICATION_PREFERENCES, variables : { managedCustomerId, managedContactId } } ]}
			showLoadingState={false}
			errorChildren={null}
			awaitRefetchQueries={true}
		>
			{(updateNotifications, loading) => <>
				<PendingChangesPrompt submitting={loading.loading} />
				{wSCustomerContactNotificationPreferenceInfo.map((notificationPreferences, index) =>
					<NotificationPreferences
						key={index}
						managedCustomerId={managedCustomerId}
						notificationPreferences={notificationPreferences}
						updateNotifications={updateNotifications}
					/>,
				)}
			</>}
		</StandardMutation>
	);
};

export default ManagedAccountNotifications;
