import React from 'react';
import CmsContentList from 'components/data/CmsContentList';
import StandardMutation from 'components/data/StandardMutation.js';
import AccountLayout from 'layouts/AccountLayout.js';
import NotificationContacts from 'components/account/panels/NotificationContacts.js';
import { findContentOrFallback } from 'components/data/CmsContentRenderer.js';

import {
	GET_NOTIFICATION_PREFERENCES,
	UPDATE_NOTIFICATION_PREFERENCES,
	useNotificationPreferences,
} from "components/data/notification-preferences/NotificationPreferences.query.js";

import * as notificationContactsStyle from 'components/account/panels/NotificationContacts.module.css';
import { getCharlieCardBreadcrumbs } from '../CardSelection.js';
import { BreadcrumbItem } from 'components/breadcrumb/Breadcrumb.js';
import routeKeys from 'CustomerRouteKeys.js';
import { useBreadcrumbCallback } from 'context/BreadcrumbProvider.js';
import PendingChangesPrompt from 'components/PendingChangesPrompt.js';
import { useAcceptedManagedCustomers } from 'components/data/alternates/Alternates.query';
import LoadingIcon from 'components/icons/LoadingIcon.js';

import NotificationPreferences from 'components/account/panels/NotificationPreferences.js';
import ManagedAccountNotifications from './ManagedAccountNotifications.js';

const cms = {
	header: "miscText.notifications-header",
};

export const getNotificationBreadcrumbs = () =>  [
	...getCharlieCardBreadcrumbs(),
	<BreadcrumbItem
		key={cms.header}
		cmsKey={cms.header}
		fallbackValue={'Security & Login'}
		routeKey={routeKeys.AccountPersonalInformation}
	>
	</BreadcrumbItem>,

];

const Notifications = () => {
	useBreadcrumbCallback(getNotificationBreadcrumbs);

	const { wsManagedCustomerInfos, queryResult } = useAcceptedManagedCustomers();
	const { wSCustomerContactNotificationPreferenceInfo, queryResult: prefQueryResult } = useNotificationPreferences();

	if (queryResult.loading || prefQueryResult.loading) {
		return <LoadingIcon />;
	}

	return (
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (
			<StandardMutation
				mutation={UPDATE_NOTIFICATION_PREFERENCES}
				refetchQueries={[ {	query: GET_NOTIFICATION_PREFERENCES } ]}
				showLoadingState={false}
				errorChildren={null}
				awaitRefetchQueries={true}
			>
				{(updateNotifications, loading) =>
					<AccountLayout
						title={findContentOrFallback(cmsContent, cms.header, "Notifications")}
						additionalClasses={notificationContactsStyle.mainContainer}
					>
						<PendingChangesPrompt submitting={loading.loading} />
						<NotificationContacts
							selectedSms={wSCustomerContactNotificationPreferenceInfo?.[ 0 ].smsPhone}
							updateNotifications={updateNotifications}
						/>

						{wSCustomerContactNotificationPreferenceInfo.map((notificationPreferences, index) =>
							<NotificationPreferences
								key={index}
								isPrimary={index === 0}
								notificationPreferences={notificationPreferences}
								updateNotifications={updateNotifications}
							/>
						)}

						{wsManagedCustomerInfos?.map((member) =>
							<ManagedAccountNotifications
								key={member.linkId}
								managedCustomerLinkInfo={member}
							/>
						)}

					</AccountLayout>
				}
			</StandardMutation>
		)}</CmsContentList>
	);
};

export default Notifications;
