import {
	get,
	clone,
} from "lodash";
import WSNotificationTypeSubCategoryInfo, { WSNotificationTypeSubCategoryInfoType } from "./WSNotificationTypeSubCategoryInfo.js";
import WSNotificationTypeCategoryInfo, { WSNotificationTypeCategoryInfoType } from "./WSNotificationTypeCategoryInfo.js";
import { WSNotificationChannelPreferenceInfoType } from "./WSNotificationChannelPreferenceInfo.js";

// WSNotificationTypePreferenceInfo represents a customer contact notification type preference.
export default class WSNotificationTypePreferenceInfo {
	constructor(obj = {}) {
		this.notificationType = obj.notificationType;
		this.notificationDescription = obj.notificationDescription;
		this.category = get(obj, "category", null);
		this.subCategory = get(obj, "subCategory", null);
		this.optIn = obj.optIn;
		this.mandatory = get(obj, "mandatory", null);
		this.channels = obj.channels;
	}

	static fromBackoffice(data) {
		const result = new WSNotificationTypePreferenceInfo(data);
		result.subCategory = data.subCategory
			? WSNotificationTypeSubCategoryInfo.fromBackoffice(data.subCategory)
			: null;
		result.category = data.category
			? WSNotificationTypeCategoryInfo.fromBackoffice(data.category)
			: null;
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.subCategory = this.subCategory
			? this.subCategory.toResolver()
			: null;
		forGraphql.category = this.category
			? this.category.toResolver()
			: null;
		return forGraphql;
	}
}

export const WSNotificationTypePreferenceInfoType = [
	...WSNotificationTypeCategoryInfoType,
	...WSNotificationTypeSubCategoryInfoType,
	...WSNotificationChannelPreferenceInfoType,
	`
	type WSNotificationTypePreferenceInfo {
		notificationType: String!
		notificationDescription:  String!
		category: WSNotificationTypeCategoryInfo
		subCategory: WSNotificationTypeSubCategoryInfo
		optIn: Boolean!
		channels: [WSNotificationChannelPreferenceInfo]!
	}
` ];
