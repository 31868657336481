import { clone } from "lodash";
import { hashId } from "server/utils/HashUtils.js";

// 2.7.106 WSManagedCustomerInfo
//
export default class WSManagedCustomerInfo {
	constructor({
		linkId,
		managedCustomerId,
		managedContactId,
		firstName,
		lastName,
		email,
		phone,
		linkStatus,
		statusChangeDtm,
	}) {
		// String(40)
		// (Required) Unique identifier generated for
		// alternate contact link
		this.linkId = linkId;

		// string(40)
		// (Required) Unique identifier for customer
		this.managedCustomerId = managedCustomerId;

		// string(40)
		// (Required) Unique identifier for contact
		this.managedContactId = managedContactId;

		// string(60)
		// (Required) The person's first name
		this.firstName = firstName;

		// string(60)
		// (Required) The person's last name
		this.lastName = lastName;

		// string(100)
		// (Required) The contact’s email address.
		this.email = email;

		// string(20)
		// (Required) The phone number in national format.
		this.phone = phone;

		// string(20)
		// (Required) Link Status enum
		this.linkStatus = linkStatus;

		// Date
		// (Required) Datetime of Link status change
		this.statusChangeDtm = statusChangeDtm;
	}

	static fromBackoffice(data) {
		return new WSManagedCustomerInfo(data);
	}

	static fromSerialized(data) {
		return new WSManagedCustomerInfo(data);
	}

	toBackoffice() {
		return clone(this);
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = this.linkId;
		forGraphql.managedCustomerId = hashId(this.managedCustomerId);
		forGraphql.managedContactId = hashId(this.managedContactId);

		// Needed so the child resolver can access this.
		forGraphql.unhashedCustomerId = this.managedCustomerId;

		return forGraphql;
	}
}

export const WSManagedCustomerInfoType = [
	`
		type WSManagedCustomerInfo {
			id: ID!
			linkId: String!
			managedCustomerId: String!
			managedContactId: String!
			firstName: String!
			lastName: String!
			email: String!
			phone: String!
			linkStatus: String!
			statusChangeDtm: DateTime!
		}
	`,
];
