import {
	get,
	map,
	clone,
} from "lodash";
import WSNotificationTypePreferenceInfo, { WSNotificationTypePreferenceInfoType } from "./WSNotificationTypePreferenceInfo.js";
import { hashId } from "server/utils/HashUtils.js";

// WSCustomerContactNotificationPreferenceInfo represents the customer’s contact information
// in CNG along with their notification preferences. This object is to be used as
// part of search results or GET methods.
export default class WSCustomerContactNotificationPreferenceInfo {
	constructor(obj = {}) {
		this.contactId = obj.contactId;
		this.firstName = obj.firstName;
		this.lastName = obj.lastName;
		this.email = obj.email;
		this.smsPhone = obj.smsPhone ?? null;
		this.mandatory = obj.mandatory ?? null;
		this.preferences = obj.preferences ?? null;
	}

	static fromBackoffice(data) {
		const result = new WSCustomerContactNotificationPreferenceInfo(data);
		result.preferences = data.preferences ?
			map(result.preferences, preference => WSNotificationTypePreferenceInfo.fromBackoffice(preference))
			: null;
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.contactId = hashId(this.contactId);

		forGraphql.preferences = this.preferences
			? map(this.preferences, wsNotificationTypePreferenceInfo => wsNotificationTypePreferenceInfo.toResolver())
			: null;

		return forGraphql;
	}
}

export const WSCustomerContactNotificationPreferenceInfoType = [
	...WSNotificationTypePreferenceInfoType,
	`
	type WSCustomerContactNotificationPreferenceInfo {
		contactId: ID!
		firstName: String!
		lastName: String!
		email: String!
		smsPhone: String
		mandatory: Boolean!
		preferences: [WSNotificationTypePreferenceInfo]!
	}
` ];
