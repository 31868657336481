import cx from 'classnames';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
	map,
	filter,
	isEmpty,
	indexOf,
} from 'lodash';
import {
	string as yup_string,
	number as yup_number,
	date as yup_date,
} from "yup";
import Input from 'components/forms/Input';
import { Calendar } from 'components/Icon.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import RadioInput from 'components/forms/inputs/RadioInput.js';
import Panel from 'components/Panel.js';
import Select from 'components/forms/inputs/Select.js';
import { useAutoloadContext } from "context/AutoloadContext.js";

import * as style from './AutoloadBalance.module.css';
import { BIWEEKLY, MONTHLY, RECURRING, THRESHOLD, WEEKLY, sequenceGen } from 'utils/Constants.js';
import {
	BIWEEKLY_FREQUENCY,
	WEEKLY_MONTHLY_FREQUENCY,
} from 'components/account/purchases/AutoLoadCriteriaContext.js';

import { useLanguage } from "components/data/Language.js";
import PublicAppVars, { defaults } from 'utils/PublicAppVars.js';
import {
	centsToDisplay,
	ISODatePart,
	localizeWeekDay,
	makeOrdinal,
} from 'utils/FormatHelpers';

import * as historyFilters from 'components/HistoryFilters.module.css';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';

const cms = {
	balanceWhenThreshold: 'miscText.autoload-balance-when-threshold',
	balanceWhenThresholdSelect: 'miscText.autoload-balance-when-threshold-select',
	balanceWhenDate: 'miscText.autoload-balance-when-date',
	balanceWhenDateSelect: 'miscText.autoload-balance-when-date-select',
	balanceWhenInterval: 'miscText.autoload-balance-when-interval',
	balanceWhenIntervalSelect: 'miscText.autoload-balance-when-interval-select',
	selectWeekly: 'miscText.autoload-balance-when-interval-select-weekly',
	selectBiweekly: 'miscText.autoload-balance-when-interval-select-biweekly',
	selectMonthly: 'miscText.autoload-balance-when-interval-select-monthly',
	balanceWhenRepeatOn: 'miscText.autoload-balance-when-interval-repeat-on',
	balanceWhenStartOn: 'miscText.autoload-balance-when-interval-start-on',
	monthDay: 'miscText.autoload-balance-when-interval-month-day',
	mon: 'miscText.autoload-balance-when-interval-weekly-mon',
	tue: 'miscText.autoload-balance-when-interval-weekly-tue',
	wed: 'miscText.autoload-balance-when-interval-weekly-wed',
	thu: 'miscText.autoload-balance-when-interval-weekly-thu',
	fri: 'miscText.autoload-balance-when-interval-weekly-fri',
	sat: 'miscText.autoload-balance-when-interval-weekly-sat',
	sun: 'miscText.autoload-balance-when-interval-weekly-sun',
	monthDescription: 'miscText.autoload-balance-when-interval-month-description',
};

const optionsThreshold = [
	{
		label: <CmsContentRenderer.Span fallbackValue="$5.00" />,
		value: 500,
	},
	{
		label: <CmsContentRenderer.Span fallbackValue="$10.00" />,
		value: 1000,
	},
	{
		label: <CmsContentRenderer.Span fallbackValue="$25.00" />,
		value: 2500,
	},
	{
		label: <CmsContentRenderer.Span fallbackValue="$50.00" />,
		value: 5000,
	},
];
const optionsRecurrence = [
	{
		label: <CmsContentRendered.Span contentKey={cms.selectWeekly} fallbackValue="Week" />,
		value: WEEKLY,
	},
	{
		label: <CmsContentRendered.Span contentKey={cms.selectBiweekly} fallbackValue="2 Weeks" />,
		value: BIWEEKLY,
	},
	{
		label: <CmsContentRendered.Span contentKey={cms.selectMonthly} fallbackValue="Month" />,
		value: MONTHLY,
	},
];

const optionsDayMonthly = map(sequenceGen(1, 31), day => ({
	label: <CmsContentRenderedInline contentKey={cms.monthDay} fallbackValue={`${makeOrdinal(day)} day of the month`}
		variables={{ day: makeOrdinal(day) }} />,
	value: day.toString(),
}));


export const autoloadFrequencyValidations = (recurring) => {
	let validation;

	if (recurring === RECURRING) {
		validation = {
			recurrence: yup_string().required('Please select a recurrence.'),
			day: yup_string().required('Please select a day.'),
			frequency: yup_string(),
		};
	} else {
		validation = {
			reloadThreshold: yup_number().required('Please select a value.'),
		};
	}

	return {
		type: yup_string().required('Please select a type.'),
		startDate: yup_date(),
		...validation,
	};
};

const getDefaultThresholdValue = (thresholdValue) => {
	const index = indexOf(PublicAppVars.AUTOMATIC_RELOAD_BALANCE_THRESHOLD, thresholdValue);
	return PublicAppVars.AUTOMATIC_RELOAD_BALANCE_THRESHOLD[ index >= 0 ? index : 0 ];
};

const getDefaultWeekBased = (wsAutoloadSubscriptionInfo) => {
	if (!isEmpty(wsAutoloadSubscriptionInfo) && wsAutoloadSubscriptionInfo?.criteria?.recurrence === MONTHLY) {
		return false;
	}
	return true;
};

const getDefaultRecurrence = (wsAutoloadSubscriptionInfo) => {
	if (!isEmpty(wsAutoloadSubscriptionInfo) && wsAutoloadSubscriptionInfo?.criteria?.type === RECURRING) {
		if (wsAutoloadSubscriptionInfo?.criteria?.recurrence === WEEKLY) {
			if (wsAutoloadSubscriptionInfo?.criteria?.frequency === WEEKLY_MONTHLY_FREQUENCY) {
				return WEEKLY;
			} else {
				return BIWEEKLY;
			}
		}
		return MONTHLY;
	}
	return WEEKLY;
};

const getDefaultDay = (wsAutoloadSubscriptionInfo) => {
	if (!isEmpty(wsAutoloadSubscriptionInfo)) {
		return wsAutoloadSubscriptionInfo?.criteria?.day;
	}
	return null;
};

const getDefaultStartDay = (wsAutoloadSubscriptionInfo) => {
	if (!isEmpty(wsAutoloadSubscriptionInfo)) {
		return wsAutoloadSubscriptionInfo?.startDate.split("T")[ 0 ];
	}
	return null;
};

const AutoloadFrequency = ({
	updateFrequency,
	formHelper: parentFormHelper,
}) => {

	const language = useLanguage();
	const location = useLocation();
	const { wsAutoloadSubscriptionInfo } = location?.state ?? {};

	const [ recurring, setRecurring ] = useState(false);
	const [ isWeekBased, setWeekBased ] = useState(getDefaultWeekBased(wsAutoloadSubscriptionInfo));

	const [ threshold, setThreshold ] = useState(getDefaultThresholdValue(wsAutoloadSubscriptionInfo?.criteria?.thresholdValue));
	const [ recurrence, setRecurrence ] = useState(getDefaultRecurrence(wsAutoloadSubscriptionInfo));
	const [ day, setDay ] = useState(getDefaultDay(wsAutoloadSubscriptionInfo));
	const [ start, setStart ] = useState(getDefaultStartDay(wsAutoloadSubscriptionInfo));
	const [ originalStart, setOriginalStart ] = useState(getDefaultStartDay(wsAutoloadSubscriptionInfo));
	const { autoloads } = useAutoloadContext();
	const { currentSubscriptionInfo } = autoloads ?? {};

	useEffect(() => {

		const criteriaProps = recurring
			? {
				recurrence: recurrence,
				day,
				frequency: recurrence === BIWEEKLY ? BIWEEKLY_FREQUENCY : WEEKLY_MONTHLY_FREQUENCY,
			}
			: {
				thresholdValue: threshold,
			};

		const submitObject = {
			...criteriaProps,
			type: recurring ? RECURRING : THRESHOLD,
			startDate: start,
			originalStart,
		};

		updateFrequency(submitObject);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		recurring,
		threshold,
		recurrence,
		day,
		start,
		originalStart,
	]);

	useEffect(() => {
		if (!isEmpty(currentSubscriptionInfo)) {
			if (currentSubscriptionInfo?.criteria?.type === THRESHOLD) {
				setRecurring(false);
			} else {
				setRecurring(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentSubscriptionInfo,
	]);

	const optionsDayWeekly = map(
		sequenceGen(0, 7),
		(day) => {
			return ({
				label: localizeWeekDay(language, day),
				value: localizeWeekDay(defaults.LANGUAGE, day, { weekday: 'short' }).toUpperCase(),
			});
		});

	const onRecurrenceChange = ({ value }) => {
		setWeekBased(value === BIWEEKLY || value === WEEKLY);
		setRecurrence(value);
		setDay(null);
	};

	const getDay = (value) => {
		return isWeekBased ? filter(optionsDayWeekly, opt => opt.value === value) : filter(optionsDayMonthly, opt => opt.value === value);
	};

	const onDayChange = ({ value }) => { setDay(value); };
	const onDateChange = (e) => { setStart(e.target.value); };
	const onThresholdChange = ({ value }) => { setThreshold(value); };

	const today = ISODatePart(new Date());

	return (
		<Panel overrideClass={style.frequencyWrapper}>
			<input type="hidden" name="type" value={recurring ? RECURRING : THRESHOLD} />
			<div className={cx(!recurring ? style.selected : '', style.frequencyOption)}>
				<RadioInput
					name="frequencyType"
					additionalClasses={style.frequencyRadio}
					label={<CmsContentRendered.Span contentKey={cms.balanceWhenThreshold}
						fallbackValue="When my balance falls below a specified amount" />}
					checked={!recurring}
					onChange={({ target }) => target.checked && setRecurring(false)}
				/>

				{!recurring &&
					<div className={cx(style.frequencyOptionContent, style.thresholdSelection)}>
						<div>
							<label htmlFor={"reloadThreshold-selector"}>
								<CmsContentRendered.Span contentKey={cms.balanceWhenThresholdSelect}
									fallbackValue="When my balance falls below " />
							</label>
						</div>
						<div>
							<Select
								name="reloadThreshold"
								hideLabel={true}
								onChange={onThresholdChange}
								options={map(PublicAppVars.AUTOMATIC_RELOAD_BALANCE_THRESHOLD, option => ({
									value: option,
									label: centsToDisplay(option),
								}))}
								defaultValue={threshold}
								error={parentFormHelper.getFieldError("reloadThreshold")} />
						</div>
					</div>
				}
			</div>

			<div className={cx(recurring ? style.selected : '', style.frequencyOption)}>
				<RadioInput
					name="frequencyType"
					additionalClasses={style.frequencyRadio}
					label={<CmsContentRendered.Span contentKey={cms.balanceWhenInterval}
						fallbackValue="At a recurring interval" />}
					checked={recurring}
					onChange={({ target }) => target.checked && setRecurring(true)}
				/>

				{recurring &&
					<div className={style.frequencyOptionContent}>
						<div className={style.frequencySelectionGroup}>
							<Select
								name="recurrence"
								overrideClass={cx(style.frequencySelection)}
								onChange={onRecurrenceChange}
								options={optionsRecurrence}
								defaultValue={recurrence}
								error={parentFormHelper.getFieldError("recurrence")}
								label={<CmsContentRendered.Span contentKey={cms.balanceWhenIntervalSelect}
									fallbackValue="Repeat every" />} />

							<Select
								name="day"
								overrideClass={cx(style.frequencySelection)}
								value={getDay(day)}
								onChange={onDayChange}
								options={isWeekBased ? optionsDayWeekly : optionsDayMonthly}
								defaultValue={day}
								error={parentFormHelper.getFieldError("day")}
								label={<CmsContentRendered.Span contentKey={cms.balanceWhenRepeatOn}
									fallbackValue="Repeat on" />} />

							<div className={historyFilters.calendarInputWrapper}>
								<Input
									label={<CmsContentRendered.Span contentKey={cms.balanceWhenStartOn}
										fallbackValue="Starting on" />}
									name="startDateTime"
									data-qa="allDatesOption"
									type="date"
									error={parentFormHelper.getFieldError("startDateTime")}
									min={today}
									defaultValue={start}
									aria-label={<CmsContentRenderer.Span contentKey={cms.balanceWhenStartOn}
										fallbackValue="Starting on" />}
									onChange={(e) => onDateChange(e)}
									overrideClass={historyFilters.calendarInput}
								/>
								<Calendar
									overrideClass={cx(historyFilters.calendarIcon, historyFilters.dateRange, style.calendarIconOverride)}
									aria-hidden="true"
									data-qa="calendar-icon"
								/>
							</div>

						</div>
						{recurrence === MONTHLY
							? <div>
								<CmsContentRendered.Span contentKey={cms.monthDescription}
									fallbackValue="Autoload will occur on the last day of the month for months with fewer than 31 days." />
							</div>
							: null}
					</div>
				}
			</div>
		</Panel>
	);
};

export default AutoloadFrequency;
