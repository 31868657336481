import React, { useState } from 'react';
import cx from 'classnames';
import {
	values,
	find,
	includes,
	head,
} from 'lodash';
import { Redirect } from 'react-router-dom';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import CmsContentList from 'components/data/CmsContentList.js';
import { CARD_STATUS_SUSPENDED, getCardType } from 'utils/Cards.js';
import {
	isGhostCard,
	findPrimaryToken,
} from 'components/manage-cards/TokenHelpers.js';
import { isActiveOrNegative, isNegativeBalance } from "utils/transit-account-utils/StatusUtils.js";
import { centsToDisplay } from 'utils/FormatHelpers.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import PurseBalanceProvider, { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import { RIDER_CLASSES, useTransitAccount } from 'components/data/transit-account/TransitAccount.js';
import {
	useEmvCanReload,
	useCanResolveBalance,
	useEmvCanAutoload, useCanViewBalance,
	useIsEmvCard,
} from "components/data/transit-account/EMV.helpers.js";
import { IfLoggedIn } from 'components/data/session-user/LoggingIn.js';
import SubscriptionInfo from 'components/data/transit-account/autoload/SubscriptionInfo.query.js';
import { TRAVEL_TOKEN_TYPES } from 'server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js';

import Button, { Primary, buttonTypeStylePlain } from 'components/Button.js';
import {
	Warning,
	SmallCheck,
} from 'components/Icon.js';
import { colorCodeStatus } from 'utils/Styles.js';
import ProductList from './ProductList.js';
import Tooltip from 'components/Tooltip.js';
import TokenExpiryDate from './card/TokenExpiryDate.js';
import TokenStatus from './card/TokenStatus.js';
import { CardImage } from 'components/icons/CardBrands.js';
import TokenNameAndPan from './card/TokenNameAndPan.js';
import { ALT_DATE_FORMAT } from "utils/Constants.js";
import ManageMenu from 'components/account/ManageMenu.js';
import PauseAutoload from 'components/modals/PauseAutoload.js';
import { TYPE_DANGER } from 'components/Dropdown.js';
import { useModalContext } from "context/ModalProvider.js";
import DeleteAutoload from 'components/modals/DeleteAutoload.js';
import { WSAUTOLOAD_TYPES } from 'server/api-types/WSAutoloadFactory.js';
import {
	AutoloadSuspendedStatuses,
	paused,
	active,
	suspendedManually,
	suspendedDueToError,
} from 'server/api-types/WSAutoloadSummary.js';
import { getDateStringSync } from 'components/ServerDate.js';
import { PAYMENT_TYPES } from "server/api-types/WSPayment.js";

import * as manageMenuStyle from 'components/account/ManageMenu.module.css';
import * as tooltipStyle from 'components/Tooltip.module.css';
import * as style from './CardSummary.module.css';

import {
	useAutoloadNextOccurrenceContext,
	AutoloadNextOccurrenceContext,
	AutoloadNextOccurrenceContextProvider,
} from 'context/AutoloadNextOccurrenceContext.js';
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";
import GenericIconAndMaskedValue from "components/card-icons/GenericIconAndMaskedValue.js";
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

// TODO add cms key for paused autoload
const cms = {
	balance: 'miscText.cardinfo-balance',
	balanceToolTip: 'miscHtml.cardinfo-balance-tooltip',
	balanceTransit: 'miscText.cardinfo-balance-transit',
	balanceRefundable: 'miscText.cardinfo-balance-refundable',
	balancePreTax: 'miscText.cardinfo-balance-pretax',
	reloadBalance: 'miscText.cardinfo-reload-balance',
	transitId: 'miscText.cardinfo-transit-id',
	riderClass: 'miscText.cardinfo-fare-type',

	status: 'miscText.cardinfo-status',
	expiry: 'miscText.cardinfo-expiry',
	enablementFee: 'miscText.cardinfo-enablementfee-label',
	enablementFeePaid: 'miscText.cardinfo-enablementfee-paid',

	editAutoload: 'miscText.autoload-manage-edit',
	pauseAutoload: 'miscText.autoload-pause-modal-header',
	resumeAutoload: 'miscText.autoload-resume-modal-header',
	cancelAutoload: 'miscText.autoload-manage-cancel',
	setupAutoload: 'miscText.autoload-setup-cta',
	autoloadTooltip: 'miscText.autoload-tooltip',
	nopassSubheader: 'miscText.cardinfo-nopasses-subheader',
	nopassButton: 'miscText.cardinfo-nopasses-button',

	autoloadWith: 'miscText.autoload-with',
	autoloadThreshold: 'miscText.autoload-threshold',
	autoLoadSetDate: 'miscText.autoload-setdate',
	autoloadRecurring: 'miscText.autoload-recurring',

	autoloadSuspended: 'miscText.autoload-suspended',
	suspendedTooltipBalance: 'miscText.autoload-suspended-tooltip-balance',

	autoloadPausedUntil: 'miscText.autoload-paused-until',
	autoloadPaused: 'miscText.autoload-paused',
};


const isPaused = ({
	wsAutoloadSubscriptionInfo,
	hasNextAutoloadDate,
	autoloadNextOccurrenceDate,
}) => {

	const currentDate = new Date();
	const startDate = wsAutoloadSubscriptionInfo?.startDate
		? new Date(wsAutoloadSubscriptionInfo.startDate)
		: null;

	if (wsAutoloadSubscriptionInfo?.status === active) {
		return false;
	}

	// this is a recurring autoload that starts in the future but its paused
	// or a threshold autoload that was supended manually
	if ([ paused, suspendedDueToError, suspendedManually ].includes(wsAutoloadSubscriptionInfo?.status)) {
		return true;
	}

	// We actually dont need the below logic but leaving in place in case the above fails.

	// handle recurring autoload
	// this is so that a recurring autoload that starts in the future doesnt
	// falsy appear as a paused autoload
	if (hasNextAutoloadDate) {
		return !(new Date(autoloadNextOccurrenceDate).getTime() > currentDate.getTime());
	}

	// NOTE: BO do not support pause indefinitely so we set start date 300 years
	return startDate?.getTime() > currentDate.getTime();
};

const TransitAccountValue = () => {
	const transit_account_id = useTransitAccountIdContext();

	const emvCanReload = useEmvCanReload({ subsystemAccountReference: transit_account_id });

	const {
		transitValue,
		refundableValue,
		preTaxValue,
	} = usePurseBalanceContext();

	// If refundable and pretax are 0, don't show transitValue.
	// https://reflexions.atlassian.net/browse/MBTA-2139
	const showTransitValue = transitValue && (refundableValue !== 0 || preTaxValue !== 0);

	return (<CmsContentList list={values(cms)}>{() =>
		<div className={cx(style.cardInfo, style.transit)}>
			{showTransitValue
				? (
					<div className={style.cardStat}>
						<CmsContentRenderer.P
							contentKey={cms.balanceTransit}
							fallbackValue="Transit Value"
							className={style.cardStatLabel}
						/>

						<div className={style.cardStatValue}>
							{centsToDisplay(transitValue)}
						</div>
					</div>
				)
				: null
			}

			{refundableValue
				? (
					<div className={style.cardStat}>
						<CmsContentRenderer.Span
							contentKey={cms.balanceRefundable}
							fallbackValue="Refundable Transit Value"
							className={style.cardStatLabel}
						/>

						<div className={style.cardStatValue}>
							{centsToDisplay(refundableValue)}
						</div>
					</div>
				)
				: null
			}
			{preTaxValue
				? (
					<div className={style.cardStat}>
						<CmsContentRenderer.Span
							contentKey={cms.balancePreTax}
							fallbackValue="Pre-Tax Transit Value"
							className={style.cardStatLabel}
						/>

						<div className={style.cardStatValue}>
							{centsToDisplay(preTaxValue)}
						</div>
					</div>
				)
				: null
			}
			{emvCanReload
				&& <div className={style.btnWrapper}>
					<Button
						to={{
							pathname: getPathByRoute(routeKeys.AccountCardReloadBalance, { transit_account_id }),
							state: { purchase: true },
						}}
						typeStyle={Primary}
						additionalClassNames={style.colBtn}
					>
						<CmsContentRenderer.Span
							contentKey={cms.reloadBalance}
							fallbackValue="Reload Your Balance"
						/>
					</Button>
				</div>
			}
		</div>
	}</CmsContentList>);
};

const TransitAccountBalance = ({
	transitAccountStatus,
	cmsContent,
}) => {
	const { purseTotal, hasNegativeBalance } = usePurseBalanceContext();

	const statusStyle = hasNegativeBalance
		? style.isSuspended
		: colorCodeStatus(transitAccountStatus, style);

	return (
		<div className={cx(style.cardStat, style.cardStatBalance)}>
			<div className={cx(style.cardStatLabel, style.hasTooltip)}>
				<CmsContentRenderer.P
					contentKey={cms.balance}
					className={style.balanceLabel}
					fallbackValue="Balance"
				/>
				<Tooltip
					tooltipId={'TaBalanceTooltip'}
					ariaLabel={cmsContent[ cms.balance ] || 'Balance'}
					ariaLabelPanel={cmsContent[ cms.balanceToolTip ] ||
						`Transit value can be used to purchase transit passes or pay for
						transit directly as you travel. Refundable transit value may occur when a
						fare vending machine is unable to provide you with change. The difference
						is added to your Charlie Card balance, but can be manually refunded at
						any full functionality fare vending machine. Pre-tax transit value was
						purchased pre-tax, and cannot be refunded or transferred to another card.`}
				>
					<CmsContentRenderer.Div
						className={tooltipStyle.text}
						contentKey={cms.balanceToolTip}
						fallbackValue={`Transit value can be used to purchase transit passes or pay for
							transit directly as you travel. Refundable transit value may occur when a
							fare vending machine is unable to provide you with change. The difference
							is added to your Charlie Card balance, but can be manually refunded at
							any full functionality fare vending machine. Pre-tax transit value was
							purchased pre-tax, and cannot be refunded or transferred to another card.`}
					/>
				</Tooltip>
			</div>

			<div className={cx(style.cardStatValue, style.isLarge, statusStyle)}>
				{centsToDisplay(purseTotal)}
			</div>
		</div>
	);
};

export const getAutoloadOptions = ({
	subsystemAccountReference,
	wsAutoloadSubscriptionInfo,
	subscriptionId,
	productName,
	expirationDateTime,
	onModalClose,
	isBalance = false,
	setModal,
	handleEditAutoload,
	isInSubsystemCatalog = false,
	hasNextAutoloadDate,
	autoloadNextOccurrenceDate,
}) => {

	const paused = isPaused({
		wsAutoloadSubscriptionInfo,
		hasNextAutoloadDate,
		autoloadNextOccurrenceDate,
	});

	return [
		...(isBalance || isInSubsystemCatalog
			? [
				{
					label: <CmsContentRenderer.Span
						contentKey={cms.editAutoload}
						fallbackValue="Edit autoload"
					/>,
					action: () => handleEditAutoload({ wsAutoloadSubscriptionInfo }),
				},
			]
			: []
		),
		{
			label: <CmsContentRendered.Span
				contentKey={paused ? cms.resumeAutoload : cms.pauseAutoload}
				fallbackValue={paused ? 'Resume autoload' : 'Pause autoload'}
			/>,
			action: () => setModal(<PauseAutoload
				pause={paused}
				currentSubscriptionInfo={wsAutoloadSubscriptionInfo}
				currentAccountReference={subsystemAccountReference}
				{...{ onModalClose }}
			/>),
		},
		{
			type: TYPE_DANGER,
			label: <CmsContentRenderer.Span
				contentKey={cms.cancelAutoload}
				fallbackValue="Cancel autoload" />,
			action: () => setModal(<DeleteAutoload {...{
				subsystemAccountReference,
				subscriptionId,
				productName,
				expirationDateTime,
				onModalClose,
				isBalance: isBalance,
			}} />),
		},
	];
};

export const SuspendedAutoload = ({ cmsContent, autoloadId }) => {
	return (
		<>
			<Warning overrideClass={style.cardStatAlert} aria-hidden={true} />
			<div className={style.autoLoadText}>
				<CmsContentRenderer.Span
					contentKey={cms.autoloadSuspended}
					fallbackValue="Autoload suspended"
				/>
			</div>
			<Tooltip
				tooltipId={`${autoloadId}_tooltip`}
				overrideClass={style.tooltipIcon}
				ariaLabel={cmsContent[ cms.autoloadSuspended ] || 'Autoload suspended'}
				ariaLabelPanel={cmsContent[ cms.suspendedTooltipBalance ] || 'Balance autoload was suspended due to a problem either with your payment method, or with your account status. Please update your payment method or contact the MBTA at 617-222-3200 to correct the issue.'}
			>
				<CmsContentRenderer.Span
					className={tooltipStyle.text}
					contentKey={cms.suspendedTooltipBalance}
					fallbackValue="Balance autoload was suspended due to a problem either with your payment method, or with your account status. Please update your payment method or contact the MBTA at 617-222-3200 to correct the issue."
				/>
			</Tooltip>
		</>
	);
};

export const ActiveAutoload = ({
	autoloadInfo,
	autoloadId,
}) => {
	const {
		fundingSourceSet,
		autoload,
		criteria,
	} = autoloadInfo;

	const { hasNextAutoloadDate, autoloadNextOccurrenceDate } = useAutoloadNextOccurrenceContext();

	const threshold = (autoload?.type === WSAUTOLOAD_TYPES.addSubsystemValue && criteria?.thresholdValue)
		? centsToDisplay(criteria?.thresholdValue)
		: null;

	const paymentTokenInfo = {
		...head(fundingSourceSet.fundingSources).creditCard,
		tokenType: TRAVEL_TOKEN_TYPES.bankcard,
		// WSCreditCardReference does not include payment type, adding iconType here to display properly card icon
		iconType: PAYMENT_TYPES.creditCard,
	};

	if (isPaused({
		wsAutoloadSubscriptionInfo: autoloadInfo,
		hasNextAutoloadDate,
		autoloadNextOccurrenceDate,
	})) {
		const pauseUntil = new Date(autoloadInfo.startDate);
		const indefinitelyPause = (pauseUntil.getFullYear() - new Date().getFullYear()) > 200;

		const formattedPausedUntilDate = getDateStringSync({
			dateISO: autoloadInfo.startDate,
			options: { day: "numeric", month: "long", year: 'numeric' },
			altFormat: ALT_DATE_FORMAT,
		});

		return (
			<CmsContentList list={values(cms)}>{({ cmsContent }) => (
				<>
					<SmallCheck overrideClass={style.autoLoadIcon} aria-hidden={true} />
					<div className={style.autoLoadText}>
						<CmsContentRendered.Span
							contentKey={indefinitelyPause ? cms.autoloadPaused : cms.autoloadPausedUntil}
							fallbackValue={indefinitelyPause ? 'Autoload paused' : `Autoload paused until ${formattedPausedUntilDate}`}
							{...indefinitelyPause ? {} : { variables: { date: formattedPausedUntilDate } }}
						/>
					</div>

					<Tooltip
						tooltipId={`${autoloadId}_tooltip`}
						overrideClass={style.tooltipIcon}
						ariaLabel={cmsContent[ cms.setupAutoload ] || 'Set up autoload'}
						ariaLabelPanel={cmsContent[ cms.autoloadTooltip ] || 'Specify a payment method to schedule your balance or passes to automatically reload when your balance is low.'}
					>
						<CmsContentRenderer.Span
							className={tooltipStyle.text}
							contentKey={cms.autoloadTooltip}
							fallbackValue="Specify a payment method to schedule your balance or passes to automatically reload when your balance is low."
						/>
					</Tooltip>
				</>
			)}</CmsContentList>
		);
	}

	/**
	 * @todo: useAutoloadNextOccurrence doesnt work for passes
	 * https://reflexions.slack.com/archives/CCF68M49M/p1677017422114479
	 *
	 * */
	const formattedNextReloadDate = hasNextAutoloadDate
		? getDateStringSync({
			dateISO: autoloadNextOccurrenceDate,
			options: { day: "numeric", month: "long", year: 'numeric' },
			altFormat: ALT_DATE_FORMAT,
		})
		: null;
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<>
				<SmallCheck overrideClass={style.autoLoadIcon} aria-hidden={true} />
				<div className={style.autoLoadText}>
					<CmsContentRenderer.Span
						contentKey={cms.autoloadWith}
						fallbackValue="Autoloads with"
					/>
				</div>
				<GenericIconAndMaskedValue
					showName={false}
					className={style.ccIconAndPanWrapper}
					iconClassName={style.ccIcon}
					{...paymentTokenInfo}
				/>
				<div className={style.autoLoadText}>
					{Boolean(criteria?.thresholdValue) &&
						<CmsContentRenderedInline
							contentKey={cms.autoloadThreshold}
							fallbackValue={`when balance drops below ${threshold}`}
							variables={{ threshold }}
						/>
					}

					{formattedNextReloadDate &&
						<CmsContentRenderedInline
							contentKey={cms.autoloadRecurring}
							fallbackValue={`, next reload on ${formattedNextReloadDate}`}
							variables={{ date: formattedNextReloadDate }}
						/>
					}
				</div>
				<Tooltip
					tooltipId={`${autoloadId}_tooltip`}
					overrideClass={style.tooltipIcon}
					ariaLabel={cmsContent[ cms.setupAutoload ] || 'Set up autoload'}
					ariaLabelPanel={cmsContent[ cms.autoloadTooltip ] || 'Specify a payment method to schedule your balance or passes to automatically reload when your balance is low.'}
				>
					<CmsContentRenderer.Span
						className={tooltipStyle.text}
						contentKey={cms.autoloadTooltip}
						fallbackValue="Specify a payment method to schedule your balance or passes to automatically reload when your balance is low."
					/>
				</Tooltip>
			</>
		)}</CmsContentList>);
};

const TransitAccountInfo = ({
	riderClass,
	riderClassDescription,
	primaryToken,
	subsystemAccountReference,
}) => {
	const isEmv = useIsEmvCard({ subsystemAccountReference });
	const ghostCard = !primaryToken;

	const {
		status,
		feeDueAmount,
	} = ghostCard
		? {}
		: primaryToken.tokenInfo.tokenEnablementFee;

	const tokenInfo = ghostCard
		? null
		: primaryToken.tokenInfo;

	return (
		<CmsContentList list={values(cms)}>{() => (
			<div className={style.cardInfo}>
				<div className={cx(style.statsWrapper, style.leftCol)}>
					{ghostCard
						? null
						: <>
							<div className={style.cardStat}>
								<CmsContentRenderer.P
									contentKey={cms.status}
									className={style.cardStatLabel}
									fallbackValue="Status"
								/>
								{primaryToken.frontendStatus === CARD_STATUS_SUSPENDED
									? <Warning overrideClass={style.cardStatAlert} aria-hidden={true} />
									: null}
								<div className={style.cardStatLabel}>
									<TokenStatus
										frontendStatus={primaryToken.frontendStatus}
										statusDescription={primaryToken.statusDescription}
										statusReasonCodeDesc={primaryToken.statusReasonCodeDesc}
									/>
								</div>
							</div>

							<div className={style.cardStat}>
								<CmsContentRenderer.P
									contentKey={cms.expiry}
									className={style.cardStatLabel}
									fallbackValue="Expires"
								/>
								<div className={style.cardStatValue}>
									<TokenExpiryDate isEmv={isEmv}tokenInfo={tokenInfo} />
								</div>
							</div>

							{tokenInfo.tokenType === TRAVEL_TOKEN_TYPES.smartcard ?
								<div className={style.cardStat}>
									<CmsContentRenderer.P
										contentKey={cms.enablementFee}
										className={style.cardStatLabel}
										fallbackValue="Card fee"
									/>
									<div className={style.cardStatValue}>
										{`${status} ${centsToDisplay(feeDueAmount)}`}
									</div>
								</div>
								: null
							}
						</>
					}
					<div className={style.cardStat}>
						<CmsContentRenderer.P
							className={style.cardStatLabel}
							contentKey={cms.transitId}
							fallbackValue="Transit ID"
						/>
						<div className={style.cardStatValue}>
							{subsystemAccountReference}
						</div>
					</div>
					{(!ghostCard && riderClass !== RIDER_CLASSES.fullFare) &&
						<div className={style.cardStat}>
							<CmsContentRenderer.Span
								className={style.cardStatLabel}
								contentKey={cms.riderClass}
								fallbackValue="Reduced fare"
							/>
							<div className={style.cardStatValue}>
								{riderClassDescription}
							</div>
						</div>
					}

				</div>
			</div>
		)}</CmsContentList>
	);
};

const SetupAutoload = ({ transit_account_id }) =>
	<CmsContentList list={values(cms)}>{({ cmsContent }) =>
		<div className={style.autoLoad}>
			<Button
				to={getPathByRoute(routeKeys.AutoloadBalance, { transit_account_id })}
				typeStyle={buttonTypeStylePlain}
			>
				<CmsContentRenderer.Span
					contentKey={cms.setupAutoload}
					fallbackValue="Set Up Autoload"
				/>
			</Button>
			<Tooltip
				tooltipId={'autoloadBalanceToolTip'}
				overrideClass={style.tooltipIcon}
				ariaLabel={cmsContent[ cms.setupAutoload ] || 'Set up autoload'}
				ariaLabelPanel={cmsContent[ cms.autoloadTooltip ] || 'Specify a payment method to schedule your balance or passes to automatically reload when your balance is low.'}
			>
				<CmsContentRenderer.Span
					className={tooltipStyle.text}
					contentKey={cms.autoloadTooltip}
					fallbackValue="Specify a payment method to schedule your balance or passes to automatically reload when your balance is low."
				/>
			</Tooltip>
		</div>
	}</CmsContentList>;

const TransitCardProducts = ({ cardHasProducts, canAutoload, transit_account_id }) =>
	cardHasProducts ?
		<div className={style.products}>
			<ProductList loggedIn />
		</div>
		: canAutoload ?
			<div className={style.products}>
				<CmsContentRenderer.Span
					contentKey={cms.nopassSubheader}
					fallbackValue="No Passes Loaded"
					className={style.noPassesText}
				/>
				<div className={style.btnAddPassesWrapper}>
					<Button
						to={{
							pathname: getPathByRoute(routeKeys.AccountPurchaseProduct, { transit_account_id }),
							state: { purchase: true },
						}}
						typeStyle={Primary}
						additionalClassNames={style.btnAddPasses}
					>
						<CmsContentRenderer.Span
							contentKey={cms.nopassButton}
							fallbackValue="Buy a pass"
						/>
					</Button>
				</div>
			</div>
			: null;



const CardSummary = () => {
	const [ redirect, setRedirect ] = useState(null);
	const transit_account_id = useTransitAccountIdContext();
	const { setModal } = useModalContext();

	const emvCanReload = useEmvCanReload({ subsystemAccountReference: transit_account_id });
	const emvCanAutoload = useEmvCanAutoload({ subsystemAccountReference: transit_account_id });
	const canViewBalance = useCanViewBalance({ subsystemAccountReference: transit_account_id });
	const canResolveBalance = useCanResolveBalance({ subsystemAccountReference: transit_account_id });

	const onModalClose = () => {
		setModal(null);
	};

	const handleEditAutoload = ({
		wsAutoloadSubscriptionInfo,
	}) => {

		setRedirect(<Redirect push to={{
			pathname: getPathByRoute(routeKeys.AutoloadBalance, { transit_account_id }),
			state: { wsAutoloadSubscriptionInfo, updateAutoload: true },
		}} />);
	};

	const {
		data: transitAccountQData,
		loading: transitAccountQLoading,
	} = useTransitAccount({ subsystemAccountReference: transit_account_id, subscriptions: true });


	if (transitAccountQLoading) {
		return <LoadingIcon size={SIZES.component} />;
	}

	const {
		tokens,
		passes,
		subscriptions,
		riderClass,
		riderClassDescription,
	} = transitAccountQData.transitAccountQ;

	// replaced cards dont have tokens
	// https://reflexions.atlassian.net/browse/MBTA-2758
	const ghostCard = isGhostCard(transitAccountQData.transitAccountQ);

	const card = {
		products: passes ?? [],
		services: [],
		group: {},
	};

	const autoloadBalance = find(subscriptions ?? [], wsAutoloadSummary => wsAutoloadSummary.autoload.type === WSAUTOLOAD_TYPES.addSubsystemValue);
	const primaryToken = ghostCard
		? null
		: findPrimaryToken(tokens);
	const frontendStatus = ghostCard
		? null
		: primaryToken?.frontendStatus;

	const mediaType = ghostCard
		? null
		: primaryToken?.mediaType;

	const cardType = ghostCard
		? null
		: getCardType({
			tokenInfo: primaryToken.tokenInfo,
			isMonochrome: frontendStatus === CARD_STATUS_SUSPENDED,
			mediaType,
		});
	const cardHasProducts = card.products.length > 0;

	const mobileWalletType = ghostCard
		? null
		: primaryToken.tokenInfo?.mobileWalletType;

	// Card is active OR the card pending
	const cardCanReload = canResolveBalance && isActiveOrNegative(transitAccountQData.transitAccountQ);
	const canAutoload = emvCanAutoload && emvCanReload && cardCanReload;

	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) =>
			<PurseBalanceProvider subsystemAccountReference={transit_account_id}>
				<div className={cx(style.container, colorCodeStatus(frontendStatus, style))}>
					<div className={style.cardHeader}>
						<CardImage
							mobileWalletType={mobileWalletType}
							creditCardType={cardType}
							status={primaryToken?.frontendStatus}
						/>

						<h3 className={style.cardTitle}>
							{ghostCard
								? null
								: <TokenNameAndPan
									tokenInfo={primaryToken.tokenInfo}
									mediaType={primaryToken.mediaType}
									elementType="span"
								/>}
						</h3>
					</div>

					<div className={style.contentContainer}>
						<div className={cx(style.cardIconDiv, style.hiddenMobile)}>
							<CardImage
								mobileWalletType={mobileWalletType}
								className={style.cardMedia}
								creditCardType={cardType}
								status={primaryToken?.frontendStatus}
							/>
						</div>

						<div className={style.defaultWrapper}>
							{canViewBalance &&
								<TransitAccountBalance
									transitAccountStatus={frontendStatus}
									{...{ cmsContent }}
								/>}

							<div className={style.cardContent}>
								{canViewBalance && <TransitAccountValue />}
								<TransitAccountInfo {...{
									riderClass,
									riderClassDescription,
									primaryToken,
									subsystemAccountReference: transit_account_id,
								}} />
							</div>
						</div>
					</div>
					<IfLoggedIn>
						<div
							className={cx(style.autoLoadContainer, (!cardHasProducts && canAutoload && style.noProduct))}
						>
							{canAutoload ?
								Boolean(autoloadBalance) ?
									<SubscriptionInfo {...{
										subsystemAccountReference: transit_account_id,
										subscriptionId: autoloadBalance.subscriptionId,
									}}>
										{(wsAutoloadSubscriptionInfo, { loading }) => {
											const autoLoadStatus = autoloadBalance.status;
											const autoLoadSuspended = includes(AutoloadSuspendedStatuses, autoLoadStatus);

											return loading
												? <LoadingIcon size={SIZES.button} />
												: <AutoloadNextOccurrenceContextProvider {...{
													wsAutoloadSubscriptionInfo,
													autoLoadSuspended,
												}}>
													<>
														<div className={style.autoLoad}>
															{/* TODO consider paused autoload */}
															{/* TODO currently display both autoload stasuses "FulfillmentPending" and "Active" similarly;
																consider Pending autoload separately from Active status after BO will fix fulfilment issue
																see slack thread https://reflexions.slack.com/archives/CCF68M49M/p1650034472308169
															*/}
															{autoLoadSuspended
																? <SuspendedAutoload {...{
																	cmsContent,
																	autoloadId: wsAutoloadSubscriptionInfo.subscriptionId,
																}} />
																: <ActiveAutoload {...{
																	autoloadInfo: wsAutoloadSubscriptionInfo,
																	subsystemAccountReference: transit_account_id,
																}} />
															}
														</div>

														<div className={style.manageDropdown}>
															<AutoloadNextOccurrenceContext.Consumer>{({
																hasNextAutoloadDate,
																autoloadNextOccurrenceDate,
															}) => (
																<ManageMenu
																	id={'cardSummaryBalanceAutoloadManageMenu'}
																	ariaLabel={cmsContent[ cms.setupAutoload ] || 'Manage this autoload method'}
																	options={getAutoloadOptions({
																		subsystemAccountReference: transit_account_id,
																		wsAutoloadSubscriptionInfo,
																		subscriptionId: wsAutoloadSubscriptionInfo.subscriptionId,
																		onModalClose,
																		isBalance: true,
																		setModal,
																		handleEditAutoload,
																		hasNextAutoloadDate,
																		autoloadNextOccurrenceDate,
																	})}
																	additionalClasses={manageMenuStyle.cardSummary}
																/>
															)}</AutoloadNextOccurrenceContext.Consumer>
														</div>
													</>
												</AutoloadNextOccurrenceContextProvider>;
										}}</SubscriptionInfo>
									: <SetupAutoload {...{ transit_account_id }} />
								: null}
						</div>
					</IfLoggedIn>
					<TransitCardProducts {...{ cardHasProducts, canAutoload, transit_account_id }} />
				</div>
			</PurseBalanceProvider>
		}</CmsContentList>
	);
};

export default CardSummary;
