import React, {
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
	capitalize,
	head,
	values,
	clone,
	filter,
	reduce,
	map,
} from 'lodash';

import {
	levels,
	log,
} from 'utils/Logger.js';

import HistoryFilters from 'components/HistoryFilters.js';
import DataTable, { TABLE_TRAVEL } from 'components/DataTable.js';

import { useRideHistory } from 'components/data/transit-account/RidesHistory.query.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import {
	NO_FILTERS,
	FILTER_TYPE_TRIP,
} from 'components/FiltersConstants.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { useTapsAlertsContext } from "context/TapsAlertsContext.js";
import ServerDate from 'components/ServerDate.js';
import {
	useLoginStage,
} from 'components/data/session-user/LoggingIn.js';
import loginStages from "components/data/session-user/LoginStages.js";
import { ALT_DATE_FORMAT } from 'utils/Constants.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import {
	InspectionTap,
	RejectedTap,
	REJECTION_REASON,
	INSPECTION_REASON,
	ALLOWED_REJECTION_REASONS,
	ALLOWED_INSPECTION_REASONS,
} from 'server/api-types/WSRideHistoryFactory.js';
import { useModalContext } from 'context/ModalProvider.js';

import DataTableZeroState from 'components/account/data-tables/DataTableZeroState.js';
import Tooltip from 'components/Tooltip.js';
import { getTransitModeIcon } from 'components/icons/TransitModes.js';
import ManageTap from 'components/forms/ManageTap.js';
import {
	Station,
	getDefaultFilterDates,
	isAutoFilledTap as isAutoFilledTapFunc,
	isOldAutoFilledTap,
	isMissedTap,
	isOldMissedTap,
	isCorrectionPending,
	isCorrectionCompleted,
	isEligibleToCorrect,
} from 'utils/TripHistory.js';

import * as tables from 'components/DataTable.module.css';
import {
	MQ_TABLET,
	useMediaQueryMatches,
} from "utils/Breakpoints.js";
import Button from 'components/Button.js';

// TODO add cms keys
const cms = {
	filterLabel: 'miscText.triphistory-filter-label',
	colTime: "miscText.triphistory-table-time",
	colMode: "miscText.triphistory-table-mode",
	colTripProduct: "miscText.triphistory-table-product",
	colFare: "miscText.triphistory-table-fare",
	fareDescription: 'miscText.triphistory-table-fare-description',

	today: "miscText.triphistory-time-today",
	allModes: "miscText.triphistory-filter-mode-all",
	empty: "miscHtml.triphistory-empty",
	noResults: "miscHtml.triphistory-noresults",

	resolve: 'miscText.triphistory-trip-resolve-tap',
	dispute: "miscText.triphistory-trip-dispute-cta",

	sortAsc: 'miscText.triphistory-table-sortAsc',
	sortDesc: 'miscText.triphistory-table-sortDesc',

	productDeclined: 'miscText.triphistory-product-declined',
	declinedTooltip: 'miscText.triphistory-product-declined-tooltip',
	expiredReason: 'miscText.triphistory-product-declined-3',
	riskReason: 'miscText.triphistory-product-declined-4',
	passbackReason: 'miscText.triphistory-product-declined-5',
	badBinReason: 'miscText.',
	unknownCardReason: 'miscText.',
	notValidReason: 'miscText.',

	autoResolvedTap: 'miscText.triphistory-trip-autocorrected-tap',
	autocorrectedTooltip: 'miscHtml.triphistory-trip-autocorrected-tap-tooltip',
	missedTap: 'miscText.triphistory-trip-missed-tap',
	missedTapTooltip: 'miscHtml.triphistory-trip-missed-tap-tooltip',
	fareMissedTap: 'miscText.triphistory-trip-missed-tap-fare',
	oldMissedTap: 'miscText.triphistory-trip-oldmissed-tap',

	inspection: 'miscText.triphistory-product-inspection',
	warningNotice: 'miscText.triphistory-product-inspection-notice-warning',
	citationNotice: 'miscText.triphistory-product-inspection-notice-citation',
	surchargeNotice: 'miscText.triphistory-product-inspection-notice-surcharge',
	surchargeTooltip: 'miscText.triphistory-product-inspection-notice-surcharge-tooltip',
	penaltyFareNotice: 'miscText.triphistory-product-inspection-notice-penalty',
	penaltyFareNoticeTooltip: 'miscText.triphistory-product-inspection-notice-penalty-tooltip',

	pendingStatus: 'miscText["triphistory-trip-dispute-status.VoidPending"]',
	resolvedStatus: 'miscText.triphistory-trip-resolved-tap',

	balance: 'miscText.purchasehistory-table-product-balance',
};


const defaultFilterDates = getDefaultFilterDates({ isAlerts: false });

export const TRANSIT_PRODUCT = 'Transit Product';
export const FARE_DESCRIPTIONS = 'Fare description';

export const getSortedLineItems = (lineItems, ascOrder, tableType) => {
	if (!lineItems) {
		return null;
	}

	const isTravelTable = tableType === TABLE_TRAVEL;
	return lineItems.slice().sort((a, b) => {
		const aTime = new Date(isTravelTable ? a.startDateTime : a.paymentHistoryDateTime).getTime();
		const bTime = new Date(isTravelTable ? b.startDateTime : b.paymentHistoryDateTime).getTime();

		if (ascOrder) {
			return aTime - bTime;
		}

		return bTime - aTime;
	});
};

const getInspectedProductCmsKeyValue = (status) => {
	const inspectionReason = ALLOWED_INSPECTION_REASONS[ status ];
	const {
		warning,
		citation,
		onboardSurcharge,
		penaltyFare,
	} = INSPECTION_REASON;

	const mapping = {
		[ warning ]: cms.warningNotice,
		[ citation ]: cms.citationNotice,
		[ onboardSurcharge ]: cms.surchargeNotice,
		[ penaltyFare ]: cms.penaltyFareNotice,
	};

	if (!mapping[ inspectionReason ]) {
		log(null, levels.error, `Unexpected declined transaction status ${inspectionReason}`);
	}

	return {
		cmsKey: mapping[ inspectionReason ] ?? mapping[ warning ],
		fallbackValue: inspectionReason ?? warning,
	};
};

const checkValidOrWarningInspection = (status) => (
	ALLOWED_INSPECTION_REASONS[ status ] === INSPECTION_REASON.inspection
		|| ALLOWED_INSPECTION_REASONS[ status ] === INSPECTION_REASON.warning
);

const InspectedProduct = ({
	travelPresenceEvents,
}) => {
	const { status } = head(travelPresenceEvents);

	const isSurcharge = ALLOWED_INSPECTION_REASONS[ status ] === INSPECTION_REASON.onboardSurcharge;
	const isPenaltyFare = ALLOWED_INSPECTION_REASONS[ status ] === INSPECTION_REASON.penaltyFare;

	const isNotValidInspection = ALLOWED_INSPECTION_REASONS[ status ] !== INSPECTION_REASON.inspection;
	const validOrWarning = checkValidOrWarningInspection(status);

	return (
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (
			<div className={tables.declinedProductWrapper}>
				<div className={tables.declinedProduct}>
					<CmsContentRenderer.Span
						className={cx(tables.cellTransitProduct, tables.cellDeclined, validOrWarning && tables.validInspection)}
						contentKey={cms.inspection}
						fallbackValue="Fare verification"
					/>
				</div>
				<div className={tables.declinedProduct}>
					{isNotValidInspection &&
						<CmsContentRenderer.P
							className={cx(tables.cellTransitProduct, tables.cellDeclined, validOrWarning && tables.validInspection, tables.reason)}
							contentKey={getInspectedProductCmsKeyValue(status).cmsKey}
							fallbackValue={getInspectedProductCmsKeyValue(status).fallbackValue}
						/>
					}
					{isSurcharge &&
						<Tooltip
							tooltipId={`${head(travelPresenceEvents).travelPresenceEvents}_surchargeToolTip`}
							overrideClass={tables.tooltipIcon}
							ariaLabelPanel={cmsContent[ cms.surchargeTooltip ] || 'You did not tap as required to use your pass or free transfer and were charged this fee after fare verification.'}
						>
							<CmsContentRenderer.P
								className={tables.tooltip}
								contentKey={cms.surchargeTooltip}
								fallbackValue="You did not tap as required to use your pass or free transfer and were charged this fee after fare verification."
							/>
						</Tooltip>
					}
					{isPenaltyFare &&
						<Tooltip
							tooltipId={`${head(travelPresenceEvents).travelPresenceEvents}_penaltyFareToolTip`}
							overrideClass={tables.tooltipIcon}
							ariaLabelPanel={cmsContent[ cms.penaltyFareNoticeTooltip ] || 'You did not tap as required to use your pass or free transfer and were charged this fee after fare verification'}
						>
							<CmsContentRenderer.P
								className={tables.tooltip}
								contentKey={cms.penaltyFareNoticeTooltip}
								fallbackValue="You did not tap as required to use your pass or free transfer and were charged this fee after fare verification"
							/>
						</Tooltip>
					}
				</div>
			</div>
		)}</CmsContentList>
	);
};

const getRejectedProductCmsKeyValue = (status) => {
	const rejectionReason = ALLOWED_REJECTION_REASONS[ status ];
	const {
		expired,
		risk,
		passback,
		badBin,
		unknownCard,
		notValid,
	} = REJECTION_REASON;

	const mapping = {
		[ expired ]: cms.expiredReason,
		[ risk ]: cms.riskReason,
		[ passback ]: cms.passbackReason,
		[ badBin ]: cms.badBinReason,
		[ unknownCard ]: cms.unknownCardReason,
		[ notValid ]: cms.notValidReason,
	};

	if (!mapping[ rejectionReason ]) {
		log(null, levels.error, `Unexpected declined transaction status ${rejectionReason}`);
	}

	return {
		cmsKey: mapping[ rejectionReason ] ?? mapping[ notValid ],
		fallbackValue: rejectionReason ?? notValid,
	};
};



const RejectedProduct = ({
	travelPresenceEvents,
}) => {
	const { status } = head(travelPresenceEvents);

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<div className={tables.declinedProductWrapper}>
				<div className={tables.declinedProduct}>
					<CmsContentRenderer.Span
						className={cx(tables.cellTransitProduct, tables.cellDeclined)}
						contentKey={cms.productDeclined}
						fallbackValue="Declined tap"
					/>
					<Tooltip
						tooltipId={`${head(travelPresenceEvents).travelPresenceEvents}_rejectToolTip`}
						overrideClass={tables.tooltipIcon}
						ariaLabel={cmsContent[ cms.productDeclined ] || 'Declined tap'}
						ariaLabelPanel={cmsContent[ cms.declinedTooltip ] || 'Your tap was declined. If you are still having trouble using your card, please contact the MBTA at 617-222-3200 for assistance.'}
					>
						<CmsContentRenderer.P
							className={tables.tooltip}
							contentKey={cms.declinedTooltip}
							fallbackValue="Your tap was declined. If you are still having trouble using your card, please contact the MBTA at 617-222-3200 for assistance."
						/>
					</Tooltip>
				</div>
				<CmsContentRenderer.P
					className={cx(tables.cellTransitProduct, tables.cellDeclined, tables.reason)}
					contentKey={getRejectedProductCmsKeyValue(status).cmsKey}
					fallbackValue={getRejectedProductCmsKeyValue(status).fallbackValue}
				/>
			</div>
		)}</CmsContentList>
	);
};

export const TransitProductCell = ({ wsTripHistory }) => {
	const {
		tripPayments,
		type,
		travelPresenceEvents,
	} = wsTripHistory;

	const {
		purseDescription,
		productDescription,
	} = tripPayments
		? head(tripPayments)
		: {};

	switch (type) {
		case InspectionTap:
			return <InspectedProduct {...{ travelPresenceEvents }} />;
		case RejectedTap:
			return <RejectedProduct {...{ travelPresenceEvents }} />;
		default: {
			return <div className={tables.cellTransitProduct}>
				<CmsContentRenderer.Span
					contentKey={cms.balance}
					fallbackValue={"Transit value"}
				/>
			</div>;
		};
	}
};

const AutofilledTapTooltip = ({ cmsContent, rideId }) => {
	return (
		<Tooltip
			tooltipId={`${rideId}_autofilledToolTip`}
			overrideClass={tables.tooltipIcon}
			ariaLabelPanel={cmsContent[ cms.autocorrectedTooltip ] || 'In order to properly calculate fare, some modes require a tap on and off. If you miss one of these taps, we may auto-populate it based on your travel history, but the fare charge may be inaccurate. <a href="https://www.mbta.com/learnmore_missed-tap">Click here for more info about missed taps</a>'}
		>
			<CmsContentRenderer.Div rawHtml
				className={tables.tooltip}
				contentKey={cms.autocorrectedTooltip}
				fallbackValue='<p>In order to properly calculate fare, some modes require a tap on and off. If you miss one of these taps, we may auto-populate it based on your travel history, but the fare charge may be inaccurate. <a href="https://www.mbta.com/learnmore_missed-tap">Click here for more info about missed taps</a></p>'
			/>
		</Tooltip>
	);
};


const MissedTapTooltip = ({ cmsContent, rideId }) => {
	return (
		<div>
			<CmsContentRenderer.Span
				className={tables.cellDeclined}
				contentKey={cms.missedTap}
				fallbackValue="Missing Tap"
			/>
			<Tooltip
				tooltipId={`${rideId}_missedToolTip`}
				overrideClass={tables.tooltipIcon}
				ariaLabel={cmsContent[ cms.missedTap ] || 'Missing Tap'}
				ariaLabelPanel={cmsContent[ cms.missedTapTooltip ] || 'To properly calculate fare, some modes require a tap on and off. If you miss one of these taps, you will be charged for the maximum distance possible from where you tapped. <a href="https://www.mbta.com/charlie/missing-tap">Click here for more info about missed taps</a>'}
			>
				<CmsContentRenderer.Div rawHtml
					className={tables.tooltip}
					contentKey={cms.missedTapTooltip}
					fallbackValue='<p>To properly calculate fare, some modes require a tap on and off. If you miss one of these taps, you will be charged for the maximum distance possible from where you tapped. <a href="https://www.mbta.com/charlie/missing-tap">Click here for more info about missed taps</a></p>'
				/>
			</Tooltip>
		</div>
	);
};

const ServiceLocationCell = ({
	value,
	row,
	setModal,
	subsystemAccountReference,
	removeAlert,
	loggedIn,
}) => {
	const {
		startLocationDescription,
		endLocationDescription,
		travelModeDescription,
	} = row?.original ?? {};


	const onResolveTrip = async () => {
		setModal(<ManageTap {...{
			wsTripRideHistory: row.original,
			subsystemAccountReference,
			removeAlert,
		}} />);
	};

	const isAutoFilledTap = isAutoFilledTapFunc(row.original);
	const transitModeIcon = getTransitModeIcon(capitalize(value), { overrideClass: tables.transportIcon });

	return (
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (
			<>
				{(startLocationDescription && endLocationDescription)
					? <div>
						<div className={tables.serviceLocation}>
							{transitModeIcon}
							<Station {...{ station: startLocationDescription, mode: travelModeDescription }} />
						</div>
						<div className={tables.serviceLocation}>
							{transitModeIcon}
							<Station {...{ station: endLocationDescription, mode: travelModeDescription }} />
							{isAutoFilledTap && <AutofilledTapTooltip {...{ cmsContent, rideId: row.original.rideId }} />}
							{isMissedTap(row.original) && <MissedTapTooltip {...{ cmsContent, rideId: row.original.rideId }} />}
						</div>
					</div>
					: <div className={tables.serviceLocation}>
						{transitModeIcon}
						{/*
                                api does not return startLocationDescription for Inspected tap,
                                can take stopPointDescription field from travelPresenceEvents array (one
                                element, so we can do travelPresenceEvents[ 0 ])
                                */}
						<Station {...{
							station: startLocationDescription ?? row.original.travelPresenceEvents?.[ 0 ]?.stopPointDescription,
							mode: row.original.travelModeDescription,
						}} />
						{isAutoFilledTap && <AutofilledTapTooltip {...{ cmsContent, rideId: row.original.rideId }} />}
						{isMissedTap(row.original) && <MissedTapTooltip {...{ cmsContent, rideId: row.original.rideId }} />}
					</div>
				}
				{isAutoFilledTap && !isCorrectionPending(row.original) && !isCorrectionCompleted(row.original) &&
					<CmsContentRenderer.Div
						className={tables.cellDeclined}
						contentKey={cms.autoResolvedTap}
						fallbackValue="Auto-resolved tap"
					/>
				}
				{isCorrectionPending(row.original) &&
					<CmsContentRenderedInline
						elementType="div"
						contentKey={cms.pendingStatus}
						fallbackValue="Pending"
					/>
				}
				{isCorrectionCompleted(row.original) &&
					<CmsContentRenderedInline
						elementType="div"
						contentKey={cms.resolvedStatus}
						fallbackValue="Resolved tap"
					/>
				}
				{((isOldAutoFilledTap(row.original) || isOldMissedTap(row.original)) && !isCorrectionPending(row.original) && !isCorrectionCompleted(row.original)) &&
					<CmsContentRenderer.Div
						className={tables.cellDeclined}
						contentKey={cms.oldMissedTap}
						fallbackValue="Not eligible to resolve"
					/>
				}
				{(isEligibleToCorrect(row.original) && loggedIn) && (
					<div className={tables.fixAutofilledTrip}>
						<Button
							onClick={onResolveTrip}
							additionalClassNames={cx(tables.rowActionBtn, tables.resolveBtn)}
							data-qa="ResolveTapBtn" >

							<CmsContentRenderer.Span
								contentKey={cms.resolve}
								fallbackValue="Resolve"
							/>
						</Button>
					</div>
				)}
			</>
		)}</CmsContentList>
	);
};

const DateTimeCell = ({ value, row }) => {
	const {
		startDateTime,
		endDateTime,
	} = row.original ?? {};

	return (<>
		<div className={tables.cellDateTime}>
			<div className={tables.date}>
				{value
					? <ServerDate
						dateISO={value}
						options={{ day: "numeric", month: "short" }}
						altFormat={ALT_DATE_FORMAT}
					/>
					: <div className={tables.autofilled}>&mdash;</div>
				}
			</div>
			<div className={tables.time}>
				{startDateTime
					? <ServerDate
						dateISO={startDateTime}
						options={{ hour: 'numeric', minute: 'numeric' }}
					/>
					: <div className={tables.autofilled}>&mdash;</div>
				}
				{endDateTime
					? <ServerDate
						dateISO={endDateTime}
						options={{ hour: 'numeric', minute: 'numeric' }}
					/>
					: null
				}
			</div>
		</div>
	</>);
};

export const TotalFareCell = ({
	value,
	row,
}) => {
	const {
		type,
		fareDescription,
		travelPresenceEvents,
	} = row;

	const validOrWarning = type === InspectionTap
		? checkValidOrWarningInspection(head(travelPresenceEvents)?.status)
		: false;

	const isAtLeastTablet = useMediaQueryMatches(MQ_TABLET);

	return (<>
		{(type === RejectedTap || type === InspectionTap)
			? <div className={cx(tables.cellDeclined,
				(type === InspectionTap && validOrWarning) && tables.validInspection)}>&mdash;</div>
			: (isMissedTap(row.original) && isOldMissedTap(row.original))
				? <div>
					{/*
                    temporarily workaround to avoid $NaN fare until BO will fix issue with matching recent taps,
                    07/79/2022 see slack https://reflexions.slack.com/archives/CAT36MFLZ/p1659114798018709
                    */}
					{value === undefined
						? <div>&mdash;</div>
						: <div>{centsToDisplay(value)}</div>}
					<CmsContentRenderer.Span
						className={tables.cellDeclined}
						contentKey={cms.fareMissedTap}
						fallbackValue="Missing tap fare"
					/>
				</div>
				: <div>
					{/*
                    temporarily workaround to avoid $NaN fare until BO will fix issue with matching recent taps,
                    07/79/2022 see slack https://reflexions.slack.com/archives/CAT36MFLZ/p1659114798018709
                    */}
					{value === undefined
						? <div>&mdash;</div>
						: <div>{centsToDisplay(value)}</div>}
					{isAtLeastTablet &&
						<div className={tables.fareDescription}>{fareDescription}</div>
					}
				</div>
		}
	</>);
};

export const getColumns = (setModal, removeAlert, transit_account_id, loggedIn) => [
	{
		accessor: 'startDateTime',
		canSort: true,
		Header: <CmsContentRenderer.Span
			contentKey={cms.colTime}
			fallbackValue="Time"
		/>,
		Cell: ({ value, row }) => {
			return <DateTimeCell {...{ value, row }} />;
		},
	},
	{
		accessor: 'travelMode',
		disableSortBy: true,
		Header: <CmsContentRenderer.Span
			contentKey={cms.colMode}
			fallbackValue="Mode / Stop"
		/>,
		Cell: ({ value, row }) => {
			return <ServiceLocationCell {...{
				value,
				row,
				setModal,
				subsystemAccountReference: transit_account_id,
				removeAlert,
				loggedIn,
			}} />;
		},
	},
	{
		accessor: 'tripPayments',
		disableSortBy: true,
		className: 'transitProduct',
		Header: <CmsContentRenderer.Span
			className={tables.cellTransitProduct}
			contentKey={cms.colTripProduct}
			fallbackValue={TRANSIT_PRODUCT}
		/>,
		Cell: ({ row }) => {
			const wsTripHistory = clone(row.original);
			return <TransitProductCell {...{ wsTripHistory }} />;
		},
	},
	{
		accessor: 'totalFare',
		disableSortBy: true,
		Header: <CmsContentRenderer.Span
			contentKey={cms.colFare}
			fallbackValue="Fare"
		/>,
		Cell: ({ row, value }) => {
			const { windowSize } = head(row.cells);
			return <TotalFareCell {...{ value, row: row.original, windowSize }} />;
		},
	},
];

// items order is important
const subRowHeaders = [
	TRANSIT_PRODUCT,
	FARE_DESCRIPTIONS,
];

const getSubRows = (wsTripHistory) => {
	const { riderClassDescription } = wsTripHistory;

	const getTransitProduct = (wsTripHistory) => (<TransitProductCell {...{ wsTripHistory }} />);

	// items order is important
	const subRowValues = [
		getTransitProduct(wsTripHistory),
		riderClassDescription,
	];

	const subRows = reduce(subRowHeaders, (acc, value, idx) => {
		if (subRowValues[ idx ]) {
			acc[ subRowHeaders[ idx ] ] = subRowValues[ idx ];
		}
		return acc;
	}, {});

	return subRows;
};

const mapTripHistory = (lineItems) => {
	const sortedLineItems = getSortedLineItems(lineItems, false, TABLE_TRAVEL);

	return map(sortedLineItems, wsTripHistory => {
		const rows = getSubRows(wsTripHistory);

		return {
			...wsTripHistory,
			subRows: [ rows ],
			subRowHeaders: rows,
		};
	});
};

const TripHistoryTable = ({
	showFilters = true,
	isUnregistered = false,
	limit = PublicAppVars.TRIPS_HISTORY_PAGE_SIZE,
	canPrint = true,
}) => {
	const { loginStage } = useLoginStage();
	const transit_account_id = useTransitAccountIdContext();
	const { removeAlert } = useTapsAlertsContext();
	const { setModal } = useModalContext();

	const [ activePage, setActivePage ] = useState(0);
	const [ filtersApplied, setFiltersApplied ] = useState({
		...NO_FILTERS,
		...defaultFilterDates,
	});

	const loggedIn = (loginStage === loginStages.loggedIn);
	const isAtLeastTablet = useMediaQueryMatches(MQ_TABLET);

	const columns = useMemo(() => getColumns(setModal, removeAlert, transit_account_id, loggedIn), [
		setModal,
		removeAlert,
		transit_account_id,
		loggedIn,
	]);

	const { lineItems, totalCount, loading, parsedError } = useRideHistory({
		subsystemAccountRef: transit_account_id,
		filtersApplied,
		offset: activePage * limit,
		limit,
		isMissedTaps: false,
	});

	const tripHistoryData = mapTripHistory(lineItems);

	return (
		<CmsContentList list={values(cms)}>{() => (
			<>
				{showFilters &&
					 <HistoryFilters
					 	parentError={parsedError}
					 	title={cms.filterLabel}
					 	applyFilter={newFilters => {
					 		setFiltersApplied(newFilters);
					 		setActivePage(0);
					 	}}
					 	filters={filtersApplied}
					 	filterType={FILTER_TYPE_TRIP}
					 />
				}
				{totalCount > 0 && !loading
					? <DataTable
						tableType={TABLE_TRAVEL}
						showPagination={totalCount > limit}
						itemsCountPerPage={limit}
						tableColumns={
							isAtLeastTablet
								? columns
								: filter(columns, column => column.accessor !== 'tripPayments')
						}
						tableData={tripHistoryData}
						transitAccountId={transit_account_id}
						totalCount={totalCount}
						{...{
							canPrint,
							activePage,
							setActivePage,
							isUnregistered,
							showFilters,
							filtersApplied,
						}}
						expandableRows
					/>
					: <DataTableZeroState isLoading={loading} />
				}
			</>
		)}</CmsContentList>
	);
};

TripHistoryTable.propTypes = {
	showFilters: PropTypes.bool,

	canPrint: PropTypes.bool,
};

export default TripHistoryTable;
