import React from "react";
import { Redirect } from "react-router-dom";

import routeKeys from "./CustomerRouteKeys.js";
import loginStages from "components/data/session-user/LoginStages.js";

import BaseLayout from "layouts/BaseLayout.js";

// pages
import Home from './pages/Home.js';
import RetailLocations from './pages/RetailLocations.js';
import ContactUs from "pages/ContactUs.js";
import DisputeRide from 'pages/DisputeRide.js';
import DisputePurchase from 'pages/DisputePurchase.js';

import ViewTransactionHistory from './pages/guest/ViewTransactionHistory.js';
import GuestCardOverview from './pages/guest/CardOverview.js';

import TermsOfService from './pages/eula/TermsOfService.js';
import EulaById from "./pages/eula/EulaById.js";
import EulasPending from "./pages/eula/EulasPending.js";

import SignIn from './pages/auth/SignIn.js';
import Register from './pages/auth/Register.js';
import PersonalInformation from './pages/auth/PersonalInformation.js';
import { AccountCreated, VerifyUpdateEmail } from 'pages/auth/ActivateAccount.js';
import ActivateAccountCsr from 'pages/auth/ActivateAccountCsr.js';
import VerificationCodePanel, { MODES as VerificationCodePanel_MODES } from "./pages/auth/VerificationCodePanel.js";

import ForgotPassword from './pages/auth/ForgotPassword.js';
import ResetPassword from './pages/auth/ResetPassword.js';
import TwoFactorAuth from './pages/auth/TwoFactorAuth.js';
import UnlockAccountRequest from './pages/auth/UnlockAccountRequest.js';

import CardSelection from './pages/account/CardSelection.js';

import AcctPersonalInformation from './pages/account/settings/PersonalInformation.js';
import Security from './pages/account/settings/Security.js';
import Notifications from './pages/account/settings/Notifications.js';
import PaymentMethods from './pages/account/settings/PaymentMethods.js';

import Overview from './pages/account/card/Overview.js';
import Settings from './pages/account/card/Settings.js';
import TripHistory from './pages/account/card/TripHistory.js';
import PurchaseHistory from './pages/account/card/PurchaseHistory.js';
import ConnectedServices from './pages/account/card/ConnectedServices.js';
import ReloadBalanceTakeover from './pages/account/card/ReloadBalanceTakeover.js';
import ReloadPassTakeover from './pages/account/card/ReloadPassTakeover.js';
import AutoloadBalance from './pages/account/card/AutoloadBalance.js';
import BlockOrReplaceFlow from './pages/account/card/BlockOrReplaceFlow.js';

import AutoloadPass from './pages/account/AutoloadPass.js';
import AddPaymentMethod from './pages/account/AddPaymentMethod.js';
import PurchaseCharlieCard from './pages/account/PurchaseCharlieCard.js';
import StandardPurchaseFlow from 'pages/account/purchase/StandardPurchaseFlow.js';

import LinkCharlieCard from './pages/account/LinkCharlieCard.js';
import AddContactlessEMVCard from './pages/account/AddContactlessEMVCard.js';
import AddMobileCard from './pages/account/AddMobileCard.js';
import TransferFlow from "pages/account/card/TransfersFlow.js";

import Document from "components/account/download-reports/pdf/Document.js";
import DeLinkCardFlow from "pages/account/DeLinkCardFlow.js";
import PrintPdfLayout from "layouts/PrintPdfLayout.js";
import DirectLink from "pages/DirectLink.js";
import UpgradeCard from "pages/account/card/UpgradeCard.js";



export const routes = ({
	/**
	 * Static Pages
	 */
	[ routeKeys.Home ]:
		<BaseLayout exact path="/"
			component={Home}
		/>,

	[ routeKeys.RetailLocations ]:
		<BaseLayout exact path="/retail-locations"
			component={RetailLocations}
		/>,

	[ routeKeys.ContactUs ]:
		<BaseLayout exact path="/contact-us"
			component={ContactUs}
		/>,
	[ routeKeys.ContactUsTransitAccount ]:
		<BaseLayout exact path="/contact-us/:transit_account_id"
			component={ContactUs}
		/>,

	[ routeKeys.DirectLink ]:
		<BaseLayout exact path="/direct-link/:key*"
			component={DirectLink}
		/>,

	/**
	 * Direct links to Locked pages
	 */
	[ routeKeys.DLPurchaseNewCharlieCard ]:
		<BaseLayout exact path="/purchase-new-charlie-card"
			minLoginLevel={loginStages.loggedIn}
			render={() =>
				<Redirect to="/account/:transit_account_id/purchase-new-charlie-card" />
			}
		/>,

	/**
	 * Unregistered Users
	 */
	[ routeKeys.ViewTransactionHistory ]:
		<BaseLayout exact path="/guest/view-history"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={ViewTransactionHistory}
		/>,
	[ routeKeys.GuestCardOverview ]:
		<BaseLayout exact path="/guest/card-overview"
			minLoginLevel={loginStages.unRegisteredLoggedIn}
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={GuestCardOverview}
		/>,


	/**
	 * EULAs
	 */
	[ routeKeys.EulaById ]:
		<BaseLayout exact path="/eula/:eula_id"
			component={EulaById}
			hideActions
			showFooter={false}
		/>,
	[ routeKeys.EulasPending ]:
		<BaseLayout exact path="/eulas/eula-pending"
			component={EulasPending}
			hideActions
			showFooter={false}
		/>,
	[ routeKeys.TermsOfService ]:
		<BaseLayout exact path="/terms-of-service"
			component={TermsOfService}
		/>,


	/**
	 * Auth
	 */
	[ routeKeys.SignIn ]:
		<BaseLayout exact path="/signin"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={SignIn}
		/>,
	[ routeKeys.Register ]:
		<BaseLayout exact path="/register"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={Register}
		/>,
	[ routeKeys.RegisterPersonalInformation ]:
		<BaseLayout exact path="/register/personal-information"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={PersonalInformation}
		/>,
	[ routeKeys.RegisterAccountCreated ]:
		<BaseLayout exact path="/register/account-created"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={AccountCreated}
		/>,
	[ routeKeys.RegisterVerify ]:
		<BaseLayout exact path="/register/verify"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={AccountCreated}
		/>,
	// Per Kirk, the current url path is CSA not CSR as used in other cities.
	// https://reflexions.slack.com/archives/CCF68M49M/p1669051049450739
	[ routeKeys.ActivateAccountCsr ]:
		<BaseLayout exact path="/register/verify/csa"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={ActivateAccountCsr}
		/>,
	[ routeKeys.TwoFactorAuth ]:
		<BaseLayout exact path="/two-factor-auth"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={TwoFactorAuth}
			showCharlieBar={false}
		/>,
	[ routeKeys.TwoFactorAuthVerify ]:
		<BaseLayout exact path="/two-factor-auth/verify"
			showCharlieBar={false}
			render={(props) =>
				<VerificationCodePanel {...props} mode={VerificationCodePanel_MODES.TwoFactorAuthVerify} />
			}
		/>,
	[ routeKeys.ForgotPassword ]:
		<BaseLayout exact path="/forgot-password"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={ForgotPassword}
			showCharlieBar={false}
		/>,
	[ routeKeys.UnlockAccount ]:
		<BaseLayout exact path="/unlock-account"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={UnlockAccountRequest}
		/>,
	[ routeKeys.UnlockAccountTokenVerify ]:
		<BaseLayout exact path="/unlock-account/verify"
			showCharlieBar={false}
			render={(props) =>
				<VerificationCodePanel {...props} mode={VerificationCodePanel_MODES.UnlockAccountTokenVerify} />
			}
		/>,
	[ routeKeys.ResetPassword ]:
		<BaseLayout exact path="/reset-password"
			maxLoginLevel={loginStages.unRegisteredLoggedIn}
			component={ResetPassword}
			showCharlieBar={false}
		/>,

	// Account pages need `requireLogin` once its fully re-implemented
	/**
	 * Account Settings
	 */
	[ routeKeys.AccountPersonalInformation ]:
		<BaseLayout exact path="/account/settings/personal-information"
			minLoginLevel={loginStages.loggedIn}
			component={AcctPersonalInformation}
		/>,
	[ routeKeys.AccountSecurity ]:
		<BaseLayout exact path="/account/settings/security"
			minLoginLevel={loginStages.loggedIn}
			component={Security}
		/>,
	[ routeKeys.AccountNotifications ]:
		<BaseLayout exact path="/account/settings/notifications"
			minLoginLevel={loginStages.loggedIn}
			component={Notifications}
		/>,
	[ routeKeys.AccountPaymentMethods ]:
		<BaseLayout exact path="/account/settings/payment-methods"
			minLoginLevel={loginStages.loggedIn}
			component={PaymentMethods}
		/>,
	[ routeKeys.AccountAddPaymentMethod ]:
		<BaseLayout exact path="/account/settings/payment-methods/add"
			minLoginLevel={loginStages.loggedIn}
			component={AddPaymentMethod}
			showCharlieBar={false}
		/>,
	[ routeKeys.VerifyUpdateEmail ]:
		<BaseLayout exact path="/update-email/verify"
			component={VerifyUpdateEmail}
		/>,

	/**
	 * Account Card Management
	 */
	[ routeKeys.AccountCardSelection ]:
		<BaseLayout exact path="/account/cards"
			minLoginLevel={loginStages.loggedIn}
			component={CardSelection}
		/>,
	[ routeKeys.LinkCharlieCard ]:
		<BaseLayout exact path="/account/link-charlie-card"
			minLoginLevel={loginStages.loggedIn}
			component={LinkCharlieCard}
			showCharlieBar={false}
		/>,
	[ routeKeys.AddContactlessEMVCard ]:
		<BaseLayout exact path="/account/add-EMV-card"
			minLoginLevel={loginStages.loggedIn}
			component={AddContactlessEMVCard}
			showCharlieBar={false}
		/>,
	[ routeKeys.AddMobileCard ]:
		<BaseLayout exact path="/account/add-mobile-card"
			minLoginLevel={loginStages.loggedIn}
			component={AddMobileCard}
			showCharlieBar={false}
		/>,
	[ routeKeys.PurchaseCharlieCard ]:
		<BaseLayout exact path="/account/purchase-charlie-card"
			minLoginLevel={loginStages.loggedIn}
			component={PurchaseCharlieCard}
			showCharlieBar={false}
		/>,

	[ routeKeys.DeLinkCard ]:
		<BaseLayout exact path="/account/:transit_account_id/delink-card"
			minLoginLevel={loginStages.loggedIn}
			component={DeLinkCardFlow}
			showCharlieBar={false}
		/>,

	/**
	 * Account Token Main Pages
	 */
	[ routeKeys.AccountCardOverview ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/overview"
			minLoginLevel={loginStages.loggedIn}
			component={Overview}
		/>,
	[ routeKeys.AccountCardSettings ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/settings"
			minLoginLevel={loginStages.loggedIn}
			component={Settings}
		/>,
	[ routeKeys.AccountCardTransferProducts ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/transfer-products"
			minLoginLevel={loginStages.loggedIn}
			component={TransferFlow}
		/>,
	[ routeKeys.AccountCardRideHistory ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/ride-history"
			minLoginLevel={loginStages.loggedIn}
			component={TripHistory}
		/>,
	[ routeKeys.AccountCardPurchaseHistory ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/purchase-history"
			minLoginLevel={loginStages.loggedIn}
			component={PurchaseHistory}
		/>,
	[ routeKeys.AccountCardServices ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/connected-services"
			minLoginLevel={loginStages.loggedIn}
			component={ConnectedServices}
		/>,

	/**
	 * Account Token Management
	 */
	[ routeKeys.AccountCardReloadBalance ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/reload-balance"
			minLoginLevel={loginStages.unRegisteredLoggedIn}
			component={ReloadBalanceTakeover}
		/>,
	[ routeKeys.AccountCardReloadPass ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/reload-pass/:product_id"
			minLoginLevel={loginStages.unRegisteredLoggedIn}
			component={ReloadPassTakeover}
		/>,
	[ routeKeys.AutoloadBalance ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/autoload-balance"
			minLoginLevel={loginStages.loggedIn}
			component={AutoloadBalance}
		/>,
	[ routeKeys.AutoloadPass ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/autoload-pass/:product_sku"
			minLoginLevel={loginStages.loggedIn}
			component={AutoloadPass}
		/>,
	[ routeKeys.AccountCardBlockOrReplace ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/:replacedTravelTokenId/freeze-replace"
			component={BlockOrReplaceFlow}
			minLoginLevel={loginStages.loggedIn}
		/>,
	[ routeKeys.AccountCardUpgrade ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/upgrade"
			component={UpgradeCard}
			minLoginLevel={loginStages.loggedIn}
			showCharlieBar={false}
		/>,
	[ routeKeys.AccountDisputeRide ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/dispute-fare"
			component={DisputeRide}
			minLoginLevel={loginStages.unRegisteredLoggedIn}
		/>,
	[ routeKeys.AccountDisputePurchase ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/dispute-purchase"
			component={DisputePurchase}
			minLoginLevel={loginStages.unRegisteredLoggedIn}
		/>,

	/**
	 * Purchase New Products
	 */
	[ routeKeys.AccountPurchaseProduct ]:
		<BaseLayout exact path="/account/cards/:transit_account_id/purchase-product"
			minLoginLevel={loginStages.unRegisteredLoggedIn}
			component={StandardPurchaseFlow}
			showCharlieBar={false}
		/>,

	// Utility
	[ routeKeys.pdfExport ]:
		<BaseLayout
			exact path="/account/pdf-export/:pdf_type"
			minLoginLevel={loginStages.unRegisteredLoggedIn}
			component={Document}
			childComponent={PrintPdfLayout}
		/>,
});
