/**
 * This Banner appears on Card Overview and Trip History page if there’s a missing tap
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { values, head } from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { useLoginStage } from 'components/data/session-user/LoggingIn.js';
import loginStages from "components/data/session-user/LoginStages.js";

import Banner, { TYPE_ERROR } from 'components/Banner.js';
import Button, {
	buttonTypeStylePlain,
	GhostAlt,
} from 'components/Button.js';

import { ALT_DATE_FORMAT } from 'utils/Constants.js';
import { getDateStringSync } from 'components/ServerDate.js';
import PreventDefault from 'utils/PreventDefault.js';
import ManageTap from 'components/forms/ManageTap.js';
import { useModalContext } from "context/ModalProvider.js";

import * as style from './MissedTap.module.css';


import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { useTapsAlertsContext } from 'context/TapsAlertsContext.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';

const cms = {
	title: 'miscText["banner-missedtap.title"]',
	text: 'miscText["banner-missedtap.description"]',
	unRegTitle: 'miscText["guest-banner-missedtap.title"]',
	unRegText: 'miscText["guest-banner-missedtap.description"]',
	cta: 'miscText["banner-missedtap.cta"]', // learn more
	unRegCta: 'miscText["guest-banner-missedtap.cta"]', // Contact customer service
	ctaLink: 'miscText["banner-missedtap.cta-url"]',
	unRegCtaLink: 'miscText["guest-banner-missedtap.cta-url"]',
	resolveTapBtn: 'miscText["banner-missedtap.button"]',
	unRegButton: 'miscText["guest-banner-missedtap.button"]',
};

const MissedTap = ({}) => {

	const { loginStage } = useLoginStage();
	const transit_account_id = useTransitAccountIdContext();
	const { tapsAlerts, removeAlert } = useTapsAlertsContext();
	const wsTripRideHistory = head(tapsAlerts) ?? {};
	const unRegisteredLoggedIn = (loginStage === loginStages.unRegisteredLoggedIn);

	const { setModal } = useModalContext();

	const openCorrectTapModal = () => {

		setModal(<ManageTap {...{
			wsTripRideHistory,
			removeAlert,
			subsystemAccountReference: transit_account_id,
		}} />);
	};

	const { startDateTime } = wsTripRideHistory;
	const date = getDateStringSync({ dateISO: startDateTime, options: { day: "numeric", month: "long", year: 'numeric' }, altFormat: ALT_DATE_FORMAT });

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Banner
				type={TYPE_ERROR}
				title={<CmsContentRenderedInline
					contentKey={ unRegisteredLoggedIn ? cms.unRegTitle : cms.title }
					fallbackValue={`You missed a tap on ${date}`}
					variables={{ date }}
				/>}
				body={<CmsContentRenderer.Span
					contentKey={unRegisteredLoggedIn ? cms.unRegText : cms.text}
					fallbackValue={unRegisteredLoggedIn
						? "Contact customer service to correct this tap, or register an account to enable online tap correction."
						: "You missed a tap in or out of a trip. Please correct this missing tap to avoid being overcharged. You may resolve up to 3 missed taps per month."}
					className={style.body}
				/>}
				link={
					<Button typeStyle={buttonTypeStylePlain}
						type="button"
						to={ cmsContent[ unRegisteredLoggedIn ? cms.unRegCtaLink : cms.ctaLink ] }
						additionalClassNames={style.plainBtn}
						target="_blank"
						external
					>
						<CmsContentRenderer.Span contentKey={unRegisteredLoggedIn ? cms.unRegCta : cms.cta}
							fallbackValue={unRegisteredLoggedIn ? "Contact customer service" : "Learn more about missing taps"}
						/>
					</Button>
				}
				rightSide={unRegisteredLoggedIn
					? <Button to={getPathByRoute(routeKeys.Register)}
						typeStyle={GhostAlt}
						data-qa="GhostRegisterBtn"
					>
						<CmsContentRenderer.Span contentKey={cms.unRegButton} fallbackValue="Register" />
					</Button>
					: <Button
						isPrimary
						onClick={PreventDefault(() => openCorrectTapModal())}
						additionalClassNames={style.btn}
						data-qa="ResolveTapBtn"
					>
						<CmsContentRenderer.Span contentKey={cms.resolveTapBtn}
							fallbackValue="Resolve missing tap"
						/>
					</Button>
				}
			/>
		)}</CmsContentList>
	);
};

MissedTap.propTypes = { };

export default MissedTap;
