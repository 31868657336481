import { gql } from "@apollo/client";
import React from 'react';
import StdQuery from 'components/data/StdQuery.js';
import PropTypes from 'prop-types';
import useStdQuery from "components/data/hooks/useStdQuery.js";
import PublicAppVars from 'utils/PublicAppVars.js';
import {  useLanguage } from "../Language";

export const GET_PRODUCT_CATALOG = gql`
	query SubsystemProductCatalogGET (
		$riderClassId: Int!
		$operatorId: Int!
		$deviceTypeId: Int!
		$lang: String
	) {
		SubsystemMutationRoute {
			getProductCatalog (
				riderClassId: $riderClassId
				operatorId: $operatorId
				deviceTypeId: $deviceTypeId
				lang: $lang
			) {
				valueIncrement
				maxAddValue
				minAddValue
				products {
					id
					productSKU
					fareProductTypeId
					fareProductFamilyId
					name
					nameShort
					nameLong
					validityDuration
					validityDurationType
					validityStartDtm
					validityEndDtm
					price
					permittedUsers {
						id
						operatorId
						operatorName
					}
					displayGroup
					displayOrder
					display {
						group
						order
					}
					maxAddPass
				}
				mediaOptions {
					id
					mediaSKU
					mediaType
					subsystemTokenType
					inventoryPartNumber
					name
					nameShort
					nameLong
					price
					enablementFeeAmount
					displayGroup
					displayOrder
					display {
						group
						order
					}
				}
				valueOptions {
					id
					name
					amount
					displayGroup
				}
				operators {
					id
					operatorId
					operatorName
					travelMode
				}
			}
		}
	}
`;

export const useGetSubsystemProductCatalog = ({
	// riderClassId is the "riderClass" object returned in the GET Transit Account API
	riderClassId,

	// operatorId is always 0
	operatorId = 0,

	// hard code 301 - since it stands for "Website"
	deviceTypeId = 301,

	queryOptions,
}) => {

	const language = useLanguage();
	const lang = language ?? PublicAppVars.LANGUAGE;

	const variables = {
		riderClassId,
		operatorId,
		deviceTypeId,
		lang,
	};

	const result = useStdQuery(GET_PRODUCT_CATALOG, { variables, ...queryOptions });

	const {
		minAddValue,
		maxAddValue,
		autoloadMinThresholdValue,
		autoloadMaxThresholdValue,
		valueIncrement,
		valueOptions,
		maxAddPass,
		products,
		mediaOptions,
		operators,
	} = result?.data?.SubsystemMutationRoute.getProductCatalog ?? {};

	return {
		minAddValue,
		maxAddValue,
		autoloadMinThresholdValue,
		autoloadMaxThresholdValue,
		valueIncrement,
		valueOptions,
		maxAddPass,
		products,
		mediaOptions,
		operators,
		...result,
	};
};

const ProductCatalog = ({
	children,
	query = GET_PRODUCT_CATALOG,

	// Per Alessandro (10/6):
	// https://reflexions.slack.com/archives/GA82SPCTV/p1602007780182200?thread_ts=1602004419.168900&cid=GA82SPCTV

	// riderClassId is the "riderClass" object returned in the GET Transit Account API
	riderClassId,

	// operatorId is always 0
	operatorId = 0,

	// hard code 301 - since it stands for "Website"
	deviceTypeId = 301,
	...rest
}) => {

	const language = useLanguage();
	const lang = language ?? PublicAppVars.LANGUAGE;

	const options = {
		query,
		variables: {
			riderClassId,
			operatorId,
			deviceTypeId,
			lang,
		},
		displayName: "Subsystem Product Catalog Query",
		...rest,
	};

	return <StdQuery {...options}>
		{(queryResult) => {

			const {
				SubsystemMutationRoute: {
					getProductCatalog: {
						products,
						transitValueOptions,
						minAddValue,
						maxAddValue,
					},
				},
			} = queryResult.data;

			return children({
				products,
				transitValueOptions,
				maxAddValue,
				minAddValue,
			}, queryResult);
		}}
	</StdQuery>;
};

ProductCatalog.propTypes = {
	riderClassId: PropTypes.number.isRequired,
	operatorId: PropTypes.number,
	deviceTypeId: PropTypes.number,
	lang: PropTypes.string,
};

export default ProductCatalog;
