import { gql } from "@apollo/client";
import React from 'react';
import LoadingIcon, { SIZES } from 'components/icons/LoadingIcon.js';
import useStdQuery from "components/data/hooks/useStdQuery.js";
import ProductCatalogContext from "components/data/transit-account/ProductCatalogContext.js";
import { useLanguage } from "../Language";
import PublicAppVars from 'utils/PublicAppVars.js';

export const GET_PRODUCT_CATALOG = gql`
	query transitAcctProductCatalogGET (
		$subsystemAccountReference: ID!
		$lang: String
	) {
		TransitAccountRoute {
			getProductCatalog (
				subsystemAccountReference: $subsystemAccountReference
				lang: $lang
			) {
				valueIncrement
				maxAddTransitValue
				minAddTransitValue
				products {
					id
					productSku
					productTypeId
					productFamilyId
					productName
					productShortName
					productLongName
					validityDuration
					validityDurationType
					validityStartDtm
					validityEndDtm
					price
					displayGroup
					displayOrder
					display {
						group
						order
					}
					permittedUsers {
						id
						operatorId
						operatorName
					}
					maxAddPass
					supportsAutoload
				}
				transitValueOptions {
					id
					name
					amount
					displayGroup
				}
				operators {
					id
					operatorId
					operatorName
					travelMode
				}
			}
		}
	}
`;

export const useTransitAccountProductCatalog = ({
	/**
	 * @type string(20)
	 * (Required) Unique identifier for the transit account.
	 * */
	subsystemAccountReference,

	queryOptions,
}) => {

	const language = useLanguage();
	const lang = language ?? PublicAppVars.LANGUAGE;

	const variables = {
		subsystemAccountReference,
		lang,
	};

	const result = useStdQuery(GET_PRODUCT_CATALOG, { variables, ...queryOptions });
	const {
		products,
		mediaOptions,
		transitValueOptions,
		minAddTransitValue,
		maxAddTransitValue,
		valueIncrement,
		operators,
	} = result?.data?.TransitAccountRoute.getProductCatalog ?? {};

	return {
		products,
		mediaOptions,
		transitValueOptions,
		minAddTransitValue,
		maxAddTransitValue,
		valueIncrement,
		operators,
	};
};

const ProductCatalog = ({
	children,
	query = GET_PRODUCT_CATALOG,
	subsystemAccountReference,
	spinnerSize,
	...rest
}) => {
	const language = useLanguage();
	const lang = language ?? PublicAppVars.LANGUAGE;

	const queryResults = useStdQuery(query, {
		variables: { subsystemAccountReference, lang },
		displayName: "Product Catalog Query",
		...rest,
	});

	const { data, loading } = queryResults;

	if (loading) {
		return <LoadingIcon size={spinnerSize ?? SIZES.component} />;
	}

	const {
		TransitAccountRoute: {
			getProductCatalog: {
				products,
				mediaOptions,
				transitValueOptions,
				minAddTransitValue,
				maxAddTransitValue,
				valueIncrement,
				operators,
			},
		},
	} = data;

	const productCatalogResult = {
		products,
		mediaOptions,
		transitValueOptions,
		minAddTransitValue,
		maxAddTransitValue,
		valueIncrement,
		operators,
	};

	return <ProductCatalogContext.Provider value={productCatalogResult}>
		{children(productCatalogResult, queryResults)}
	</ProductCatalogContext.Provider>;
};

export default ProductCatalog;
