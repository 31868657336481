import React, {
	useState,
} from 'react';
import PropTypes from 'prop-types';
import { map, values } from 'lodash';
import cx from 'classnames';
import {
	object as yup_object,
	date as yup_date,
} from "yup";
import {
	levels,
	noticeError,
} from 'utils/Logger.js';
import { ISODatePart } from 'utils/FormatHelpers.js';

import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { useCmsContentList } from "components/data/CmsContentList.js";
import CmsContentListContext from "components/data/CmsContentListContext.js";

import CmsContentRenderer, { findContentOrFallback } from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";

import Toast from "components/Toast.js";
import Modal from 'components/Modal.js';
import Button, { Primary } from "components/Button.js";

import * as forms from 'components/forms/Forms.module.css';
import * as style from '../../pages/account/LinkCharlieCard.module.css';
import * as styleCalendar from 'components/HistoryFilters.module.css';
import * as pauseAutoloadStyles from './PauseAutoload.module.css';
import * as tabs from '../../styles/Tabs.module.css';

import FormHelperProvider from "utils/form-helper/FormHelperProvider";
import PreventDefault from "utils/PreventDefault";
import { RadioGroup } from "@headlessui/react";
import useFormHelper from "utils/form-helper/useFormHelper";
import Input from 'components/forms/Input.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware';
import { UPDATE_AUTOLOAD } from 'components/data/transit-account/GetAutoloadPaymentMethods';
import WSAutoloadSubscriptionUpdate from 'server/api-types/WSAutoloadSubscriptionUpdate';
import useStandardMutation from 'components/data/hooks/useStandardMutation';
import { GET_TRANSIT_ACCOUNT } from 'components/data/transit-account/TransitAccount';
import { addYears } from 'date-fns';
import { ALT_DATE_FORMAT } from 'utils/Constants.js';
import { getDateStringSync } from 'components/ServerDate.js';
import { Calendar } from 'components/Icon.js';
import { WSAUTOLOAD_TYPES } from 'server/api-types/WSAutoloadFactory.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';

const PAUSE_AUTOLOAD_INDEFINITELY_TYPE = 'PauseAutoLoadIndefinitely';
const PAUSE_AUTOLOAD_WITH_DATE_TYPE = 'PauseAutoLoadWithDate';
const RESUME_AUTOLOAD_NOW = 'ResumeAutoLoadNow';
const RESUME_AUTOLOAD_ON_SPECIFIC_DATE = 'ResumeAutoLoadOnSpecificDate';


const cms = {
	headerPauseLabel: "miscText.autoload-pause-modal-header",
	subHeaderPauseLabel: "miscText.autoload-pause-modal-option",
	indefinitelyDatePauseLabel: "miscText.autoload-pause-modal-option-indefinitely",
	unitSpecificDatePauseLabel: "miscText.autoload-pause-modal-option-date",
	submitPauseAutoload: "miscText.autoload-pause-modal-submit",
	confirmatioPauseMsgIndefinitely: "miscText.autoload-pause-confirmation-indefinitely",
	confirmatioPauseMsgUntilDate: "miscText.autoload-pause-confirmation-until",

	headerResumeLabel: 'miscText.autoload-resume-modal-header',
	subHeaderResumeLabel: "miscText.autoload-resume-modal-option",
	nowResumeLabel: 'miscText.autoload-resume-modal-option-now',
	optionDateResumeLabel: 'miscText.autoload-resume-modal-option-date',
	submitResumeAutoload: 'miscText.autoload-resume-modal-submit',
	confirmationResumeMsgUntil: 'miscText.autoload-resume-confirmation-now',
	confirmationResumeMsgDate: 'miscText.autoload-resume-confirmation-date',

	balanceText: 'miscText.autoload-balance-product-name',
};

const buildOptionTimeTypesCms = (pause) => pause ?
	[
		{
			key: cms.nowResumeLabel,
			value: RESUME_AUTOLOAD_NOW,
			fallbackValue: 'Now',
		},
		{
			key: cms.optionDateResumeLabel,
			value: RESUME_AUTOLOAD_ON_SPECIFIC_DATE,
			fallbackValue: 'On a specific date',
		},
	]
	:
	[
		{
			key: cms.indefinitelyDatePauseLabel,
			value: PAUSE_AUTOLOAD_INDEFINITELY_TYPE,
			fallbackValue: 'Indefinitely',
		},
		{
			key: cms.unitSpecificDatePauseLabel,
			value: PAUSE_AUTOLOAD_WITH_DATE_TYPE,
			fallbackValue: 'Until a specific date',
		},
	];



const buildYupSchema = (pauseType) => () => {
	const validations =
		pauseType === PAUSE_AUTOLOAD_WITH_DATE_TYPE || pauseType === RESUME_AUTOLOAD_ON_SPECIFIC_DATE ?
			{
				startDateTime: yup_date(),
			} :
			{};

	return yup_object().shape(validations);
};


const MODAL_HEADER_ID = 'pauseAutoloadModalHeaderId';

const PauseAutoload = ({
	onModalClose,
	currentSubscriptionInfo,
	currentAccountReference,
	pause = false,
}) => {

	const cmsContentList = useCmsContentList({
		list: values(cms),
	});

	const [ mutator ] = useStandardMutation(
		UPDATE_AUTOLOAD,
		{
			refetchQueries: [ {
				query: GET_TRANSIT_ACCOUNT,
				variables: {
					subsystemAccountReference: currentAccountReference,
					subscriptions: true,
					archivedTokens: false,
					programMemberships: false,
					transferProductInfo: false,
					productPayments: false,
				},
			} ],
		}
	);

	const { setToast, removeToast } = useGlobalToastsContext();
	const [ pauseType, setPauseType ] = useState(pause ? RESUME_AUTOLOAD_ON_SPECIFIC_DATE : PAUSE_AUTOLOAD_INDEFINITELY_TYPE);

	const optionTimeTypesCms = buildOptionTimeTypesCms(pause);
	const today = ISODatePart(new Date());

	const getYupSchema = buildYupSchema(pauseType);

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema });

	const product = currentSubscriptionInfo.autoload.type === WSAUTOLOAD_TYPES.PurchaseSubsystemProduct
		? currentSubscriptionInfo.autoload.productName
		: findContentOrFallback(cmsContentList.cmsContent, cms.balanceText, 'Balance');

	const onSuccess = ({ startDate }) => {

		const date = pauseType === PAUSE_AUTOLOAD_INDEFINITELY_TYPE
			? null
			: getDateStringSync({
				dateISO: startDate.toISOString(),
				options: { day: "numeric", month: "long", year: 'numeric' },
				altFormat: ALT_DATE_FORMAT,
			});

		return setToast(
			<Toast
				type="success"
				title={
					pauseType === PAUSE_AUTOLOAD_INDEFINITELY_TYPE
						? <CmsContentRenderedInline
							cmsContent={cmsContentList.cmsContent}
							contentKey={cms.confirmatioPauseMsgIndefinitely}
							fallbackValue={`Autoload on ${product} has been paused. You may resume at any time using the manage menu`}
							variables={{ product }}
						/>
						: <CmsContentRenderedInline
							cmsContent={cmsContentList.cmsContent}
							contentKey={cms.confirmationResumeMsgDate}
							fallbackValue={'Balance autoload has been canceled.'}
							variables={{ date, product }}
						/>}
				onClosed={removeToast}
			/>
		);
	};

	const getStartDate = ({ startDateTime, pause }) => {
		const currentDate = new Date();

		// if we are suspending indefinitely we dont have a startDateTime
		if (!startDateTime && !Boolean(pause)) {
			// NOTE: BO do not support pause indefinitely so we set start date 300 years
			return addYears(currentDate, 300).toISOString();
		}

		// NOTE: BO is expecting future date that's way we set it up with 3 seconds later
		return startDateTime || new Date(currentDate.setSeconds(currentDate.getSeconds() + 3));
	};

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(null, levels.info, errorReport, `filters validation failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		const startDate = getStartDate({ startDateTime: validated.startDateTime, pause });

		const { autoload, criteria } = currentSubscriptionInfo;

		const subscriptionArgs = {
			isPaused: true,
			startDate,
			...(pause && {
				loadValueBehavior: autoload.loadValueBehavior,
				loadValue: autoload.value,
				criteriaType: criteria.type,
				timePeriodRecurrence: criteria.recurrence,
				timePeriodDay: criteria.day,
				timePeriodFrequency: criteria.frequency,
				thresholdValue: criteria.thresholdValue,
			}),
		};


		try {

			const variables = {
				subscriptionId: currentSubscriptionInfo.subscriptionId,
				subscription: new WSAutoloadSubscriptionUpdate({
					...subscriptionArgs,
				}),
				subsystemAccountId: currentAccountReference,
			};

			await graphqlErrorMiddleware(mutator({ variables }));

			onModalClose();
			onSuccess({
				startDate: subscriptionArgs.startDate,
			});
			setSubmitting(false);

		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			// noticeError(null, levels.info, errorReport, `Purchase Value Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
		}
	};

	return (
		<CmsContentListContext.Provider value={cmsContentList.cmsContent}>
			<Modal
				headerId={MODAL_HEADER_ID}
				title={<CmsContentRenderer
					contentKey={pause ? cms.headerResumeLabel : cms.headerPauseLabel}
					fallbackValue={pause ? 'Resume autoload' : 'Pause autoload'}
				/>}>
				<div data-qa="PauseAutoloadContent">
					<FormHelperProvider formHelperContext={{ formHelper }} >
						<form
							method="post"
							data-qa="addPaymentMethodForm"
							ref={formRef}
							onSubmit={PreventDefault(kickoffSubmit)}
						>
							{formHelper.getFieldErrorJsx('')}
							<div >
								<RadioGroup
									value={pauseType}
									onChange={setPauseType}
									className={cx(forms.radioGroup, style.radioCharlieType)}
								>
									<RadioGroup.Label>
										<CmsContentRenderer.H2
											contentKey={pause ? cms.subHeaderResumeLabel : cms.subHeaderPauseLabel}
											className={tabs.title}
											fallbackValue={pause ? "" : "How long would you like to pause this autoload?"}
											data-qa="PauseAutoHeader"
										/>
									</RadioGroup.Label>

									<div className={forms.radioGroupOptions}>
										{map(optionTimeTypesCms, ({ key, fallbackValue, value }) => (
											<RadioGroup.Option
												key={key}
												value={value}
												className={style.radioCharlieOption}
											>
												{({ checked }) => (
													<div className={pauseAutoloadStyles.checkboxContainer}>
														<span className={cx(forms.radioLabel, checked && forms.radioChecked)}>
															<CmsContentRenderer.Span
																contentKey={key}
																className={style.radioCharlieLabel}
																fallbackValue={fallbackValue}
															/>
														</span>
														{[ PAUSE_AUTOLOAD_WITH_DATE_TYPE, RESUME_AUTOLOAD_ON_SPECIFIC_DATE ].includes(value) && (
															<div className={pauseAutoloadStyles.calendarInputWrapper}>
																<Input
																	hideLabel={true}
																	name="startDateTime"
																	data-qa="allDatesOption"
																	type="date"
																	min={today}
																	disabled={!checked}
																	overrideClass={styleCalendar.calendarInput}
																	aria-label={"Start date filter input"}
																	error={Boolean(formHelper.getFieldError('startDateTime'))}
																/>

																<Calendar
																	overrideClass={cx(styleCalendar.calendarIcon, styleCalendar.dateRange)}
																	aria-hidden="true"
																	data-qa="calendar-icon"
																/>
															</div>
														)}
													</div>
												)}
											</RadioGroup.Option>))}
									</div>
								</RadioGroup>
							</div>

							<div>
								<Button
									theme={Primary}
									data-qa="PauseAutoloadBtn"
									{...{ submitting }}
								>
									<CmsContentRenderer.Span
										contentKey={pause ? cms.submitResumeAutoload : cms.submitPauseAutoload}
										fallbackValue="Confirm" />
								</Button>
							</div>
						</form>
					</FormHelperProvider>
				</div>
			</Modal>
		</CmsContentListContext.Provider>
	);
};

PauseAutoload.propTypes = {
	pause: PropTypes.bool,
};

export default PauseAutoload;
