import React from 'react';
import { values } from "lodash";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import Toast from "components/Toast.js";
import { centsToDisplay } from "utils/FormatHelpers";
import CmsContentList from 'components/data/CmsContentList';
import { DAY_NAMES, MONTHLY } from 'utils/Constants.js';
import { AUTO_LOAD_CRITERIA_TYPES } from 'server/api-types/WSAutoloadCriteria.js';
import { BIWEEKLY_FREQUENCY } from 'components/account/purchases/AutoLoadCriteriaContext.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';


import * as style from 'pages/account/card/AutoloadBalance.module.css';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';


const cms = {
	addValue: 'miscText.autoload-confirmation-balance-how-addvalue',
	setValue: 'miscText.autoload-confirmation-balance-how-setvalue',
	threshold: 'miscText.autoload-confirmation-balance-when-threshold',
	weekly: 'miscText.autoload-confirmation-balance-when-weekly',
	biweekly: 'miscText.autoload-confirmation-balance-when-biweekly',
	month: 'miscText.autoload-confirmation-balance-when-month',
};

const NewAutoloadToast = ({ autoLoadInfo = {} }) => {
	const { removeToast } = useGlobalToastsContext();
	const {
		type,
		recurrence,
		frequency,
		amount,
		thresholdValue,
		day,
	} = autoLoadInfo;

	let autoloadDescription;

	if (type === AUTO_LOAD_CRITERIA_TYPES.threshold) {

		autoloadDescription = <CmsContentRenderedInline
			contentKey={cms.threshold}
			fallbackValue={`when it drops below ${centsToDisplay(thresholdValue)}.`}
			variables={{ amount: centsToDisplay(thresholdValue) }}
		/>;
	} else {
		if (recurrence === MONTHLY) {

			autoloadDescription = <CmsContentRenderedInline
				contentKey={cms.month}
				fallbackValue={`every month on the ${day} of the month.`}
				variables={{ day }}
			/>;
		} else if (frequency === BIWEEKLY_FREQUENCY) {

			autoloadDescription = <CmsContentRenderedInline
				contentKey={cms.biweekly}
				fallbackValue={`every 2 weeks on ${DAY_NAMES[ day ]}.`}
				variables={{ day: DAY_NAMES[ day ] }}
			/>;
		} else {

			autoloadDescription = <CmsContentRenderedInline
				contentKey={cms.weekly}
				fallbackValue={`every week on ${DAY_NAMES[ day ]}.`}
				variables={{ day: DAY_NAMES[ day ] }}
			/>;
		}
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Toast
				type="success"
				title={
					<CmsContentRendered.Span
						contentKey={cms.addValue}
						fallbackValue={`You will automatically add ${centsToDisplay(amount)} to your balance via autoload`}
						variables={{ amount: centsToDisplay(amount) }}
					/>
				}
				text={<>
					<CmsContentRendered.Span
						className={style.toastDescription}
						contentKey={cms.setValue}
						fallbackValue={`${centsToDisplay(amount)} will automatically be reloaded to your balance`}
						variables={{ amount: centsToDisplay(amount) }}
					/>
					{autoloadDescription}
				</>}
				onClosed={removeToast}
			/>
		)}
		</CmsContentList>
	);
};

export default NewAutoloadToast;
