import { gql, useApolloClient } from '@apollo/client';
import React, {
	useState,
} from 'react';
import {
	Redirect,
	useLocation,
} from 'react-router-dom';
import {
	map,
} from 'lodash';
import {
	object as yup_object,
	string as yup_string,
} from 'yup';
import cx from 'classnames';

import {
	levels,
	noticeError,
} from 'utils/Logger.js';

import routeKeys from 'CustomerRouteKeys.js';
import { getPathByRoute } from 'App.js';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import PreventDefault from 'utils/PreventDefault.js';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import { addYupMethod } from 'utils/YupValidators.js';

import { ALT_DATE_FORMAT } from 'utils/Constants.js';
import { BO_ERRORS, COLLECTIONS, getErrorKey } from 'utils/GetErrorKey.js';
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import WSRefundMethodFactory, {
	rawTypeToGraphqlFactoryType,
} from "server/api-types/WSRefundMethodFactory.js";
import { getRefundMethods, REQUEST_REFUND_METHODS } from "components/data/order/sale/RefundMethods.query.js";
import { getTransitAccountRefetchQueries } from 'components/data/transit-account/TransitAccount.js';
import { getSessionTransitAccountRefetchQueries } from "components/data/transit-account/SessionTransitAccount.js";
import { getDateStringSync } from "components/ServerDate.js";
import Checkbox from "components/forms/Checkbox.js";
import Textarea from 'components/forms/Textarea.js';

import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';

import TakeOverLayout from 'layouts/TakeOverLayout.js';
import {
	Email,
	FullName,
	Phone,
} from 'components/data/session-user/SessionUser.js';
import {
	IfLoggedIn,
	IfNotLoggedIn,
	useLoginStage,
} from 'components/data/session-user/LoggingIn.js';
import loginStages from "components/data/session-user/LoginStages.js";
import { PAYMENT_HISTORY_TA_GET } from 'components/data/transit-account/PaymentHistoryTA.query.js';
import Toast from "components/Toast.js";
import Button from 'components/Button.js';
import Panel from 'components/Panel.js';
import Input from 'components/forms/Input.js';
import { Chevron } from 'components/icons/UI.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { useReasonCodes, REASON_CODES } from 'components/data/customer-service/ReasonCodes.query.js';

import * as style from 'pages/DisputePurchase.module.css';
import * as takeOverStyle from 'layouts/TakeOverLayout.module.css';
import * as forms from 'components/forms/Forms.module.css';
import * as collapsible from 'components/Collapsible.module.css';
import useFormHelper from 'utils/form-helper/useFormHelper';


const DISPUTE_PURCHASE = gql`
	mutation RefundSale (
		$saleOrderId: Int!
		$refundMethods: [ InputWSRefundMethodFactory! ]!
		$reasonCode: String!
		$notes: String
		$unregisteredEmail: String
	) {
		OrderRoute {
			id
			postRefundSale (
				saleOrderId: $saleOrderId
				refundMethods: $refundMethods
				reasonCode: $reasonCode
				notes: $notes
				unregisteredEmail: $unregisteredEmail
			) {
				orderId
			}
		}
	}`;

// TODO update cms keys
const cms = {
	header: 'miscText.dispute-purchase-header',
	description: 'miscText.dispute-purchase-description',

	orderSubHeader: 'miscText.dispute-purchase-order-subheader',

	headingAccount: 'miscText.dispute-purchase-contact-subheader',
	orderDetails: 'miscHtml.dispute-purchase-order-details',

	selectProductSubheader: 'miscText.dispute-purchase-select-subheader',
	allProductsCheckboxLabel: 'miscText.dispute-purchase-select-all',

	expandRowAria: 'miscText.general-table-row-expand-aria',
	collapseRowAria: 'miscText.general-table-row-collapse-aria',

	duplicate: 'miscText.dispute-purchase-reason-duplicate',
	duplicateDetails: 'miscText.dispute-purchase-reason-duplicate-details',
	return: 'miscText.dispute-purchase-reason-return',
	returnDetails: 'miscText.dispute-purchase-reason-return-details',
	other: 'miscText.dispute-purchase-reason-other',
	otherDetails: 'miscText.dispute-purchase-reason-other-details',
	otherDisclaimer: 'miscText.dispute-purchase-reason-other-disclaimer',

	email: 'miscText.',

	selectReason: 'miscText.dispute-purchase-reason-subheader',

	submitButton: 'miscText.dispute-purchase-submit',
	legal: 'miscText.dispute-purchase-disclaimer',

	successTitle: 'miscHtml.dispute-purchase-confirmation',

	srAnnounceMessage: 'miscText.dispute-purchase-reason-details-aria',
};


const getYupSchema = ({ formHelper, reasonCode }) => {
	addYupMethod("validEmail");

	return yup_object().shape({
		reasonCode: yup_string()
			.required('miscText.customer-service-error-reason')
			.trim(),
		email: yup_string(),
		[ `${reasonCode}_message` ]: yup_string(),
	}).transform(function (current, original) {
		// email validation only for unlogged and unregistered user to send email info to BO
		this.fields.email = original?.email?.length === undefined
			? yup_string()
			: yup_string()
				.email(getErrorKey(COLLECTIONS.orderDispute, 'unregisteredEmail', BO_ERRORS.general.invalidEmail))
				// TODO clarify max value when hook up
				.max(50, getErrorKey(COLLECTIONS.orderDispute, 'unregisteredEmail', BO_ERRORS.general.tooLong))
				.validEmail('miscText["errors.general.email.cannot.start.with.special.characters"]')
				.required('miscText["errors.general.blankfield"]');
		this.fields[ `${reasonCode}_message` ] = yup_string()
			.max(PublicAppVars.DISPUTE_PURCHASE_MAX_CHAR_LENGTH, getErrorKey(COLLECTIONS.contactCustomerService, 'feedbackMessage', BO_ERRORS.general.tooLong))
			.trim();

		return current;
	});
};

const DisputePurchase = () => {
	const { reasonCodeTypes } = useReasonCodes({ typeId: REASON_CODES.RefundOrder });

	const { loginStage } = useLoginStage();
	const location = useLocation();
	const { wsPaymentHistory, filtersApplied } = location.state ?? {};
	const {
		paymentRefNbr,
		paymentHistoryDateTime,
		origin,
		amount,
		orderLineItems,
		orderId,
	} = wsPaymentHistory ?? {};

	const date = getDateStringSync({
		dateISO: paymentHistoryDateTime,
		options: { month: 'short', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' }, altFormat: ALT_DATE_FORMAT });

	const subsystemAccountReference = useTransitAccountIdContext();

	const [ reasonCode, setReasonCode ] = useState('');
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ redirect, setRedirect ] = useState(null);
	const [ announceMessage, setAnnounceMessage ] = useState(true);

	const isUnRegisteredLoggedIn = loginStage === loginStages.unRegisteredLoggedIn;

	const apolloClient = useApolloClient();

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema: () => getYupSchema({ formHelper, reasonCode }) });

	const onSuccess = (cmsContent) => setToast(
		<Toast
			type="success"
			title={<CmsContentRenderedInline
				rawHtml
				cmsContent={cmsContent}
				contentKey={cms.successTitle}
				fallbackValue={`<p>Your refund request has been submitted and a ticket has been created for this issue. Please note that you cannot initiate another purchase dispute until the current one is resolved.</p><br /><p>You will receive confirmation shortly with further instructions.</p>`}
			/>}
			onClosed={removeToast}
		/>,
	);

	const kickoffSubmit = async (cmsContent) => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
			formHelper.clearAllErrors();
		}
		catch (errorReport) {
			setSubmitting(false);
			// yup validation failed, but those errors are already in state
			noticeError(null, levels.verbose, errorReport, `Dispute Order Form validation: ${errorReport.message}`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		let refundMethodsResponse;
		try {
			refundMethodsResponse = await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: REQUEST_REFUND_METHODS,
					variables: { saleOrderId: Number(orderId) },
				}));
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Get Refund Payments Failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);

			return;
		}

		const {
			saleOrderRefundMethods,
		} = getRefundMethods(refundMethodsResponse);

		const variables = {
			saleOrderId: orderId,
			unregisteredEmail: isUnRegisteredLoggedIn ? validated.email : null,
			refundMethods: map(saleOrderRefundMethods, wsRefundMethod => ({
				[ rawTypeToGraphqlFactoryType(wsRefundMethod.type) ]: WSRefundMethodFactory
					.fromBackoffice(wsRefundMethod)
					.toResolver(),
			})),
			reasonCode: validated.reasonCode,
			notes: validated[ `${validated.reasonCode}_message` ],
		};

		try {
			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: DISPUTE_PURCHASE,
					variables,
					refetchQueries: [
						{ query: PAYMENT_HISTORY_TA_GET,
							variables: {
								subsystemAccountRef: subsystemAccountReference,
								filtersApplied,
							},
						},
						...getTransitAccountRefetchQueries(subsystemAccountReference),
						...getSessionTransitAccountRefetchQueries({ subsystemAccountReference }),
					],
				}));
		}
		catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			setSubmitting(false);
			noticeError(null, levels.info, errorReport, `Dispute Purchase Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent("User is submitting the Dispute Purchase Form");

		setSubmitting(false);

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(
					isUnRegisteredLoggedIn
						? routeKeys.GuestCardOverview
						: routeKeys.AccountCardOverview, { transit_account_id: subsystemAccountReference }
				),
				state: { success: true },
			}} />
		);

		onSuccess(cmsContent);

	};

	if (redirect) {
		return redirect;
	}

	const reasonCodes = [
		...(reasonCodeTypes?.[ 0 ]?.reasonCodes ?? []),
	];


	return (
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (
			<TakeOverLayout title={cmsContent[ cms.header ] || "Refund Request"}
				showCancel
			>
				<div className={takeOverStyle.contactContainer}>
					<CmsContentRenderer.Div
						contentKey={cms.description}
						className={style.description}
						fallbackValue="You may dispute this purchase if you believe you have been charged in error."
					/>

					<form
						method="post"
						id="DisputeOrderForm"
						data-qa="disputeOrderForm"
						onSubmit={PreventDefault(() => kickoffSubmit(cmsContent))}
						ref={formRef}
					>
						<section>
							<CmsContentRenderer.H2
								className={takeOverStyle.sectionHeading}
								contentKey={cms.headingAccount}
								fallbackValue="Dispute charge for:"
							/>

							<IfLoggedIn>
								<Panel>
									<div className={takeOverStyle.contactDetailsWrapper}>
										<div>
											<div className={takeOverStyle.contactValue}><FullName /></div>
											<div className={takeOverStyle.contactValue}><Email /></div>
											<div className={takeOverStyle.contactValue}><Phone /></div>
										</div>
									</div>
								</Panel>
							</IfLoggedIn>

							<IfNotLoggedIn>
								<Input
									type="email"
									name="email"
									label={cmsContent[ cms.email ] || "Your email address"}
								/>
								{formHelper.getFieldErrorJsx('email')}
							</IfNotLoggedIn>
						</section>

						<section>
							<CmsContentRenderer.H2
								className={takeOverStyle.sectionHeading}
								contentKey={cms.orderSubHeader}
								fallbackValue="Order Details"
							/>
							<Panel>
								<div className={cx(takeOverStyle.contactDetailsWrapper)}>
									<CmsContentRenderedInline rawHtml
										elementType="div"
										contentKey={cms.orderDetails}
										fallbackValue={`<p><b>Order number:</b> ${paymentRefNbr}<br /><b>Purchased on:</b> ${date}<br /><b>Purchased at:</b> ${origin}<br/><b>Order total:</b> ${centsToDisplay(amount)}</p>`}
										variables={{ order: paymentRefNbr, date: date, where: origin, amount: centsToDisplay(amount) }}
									/>
								</div>
							</Panel>
						</section>
						<section>
							<CmsContentRenderer.H2
								className={takeOverStyle.sectionHeading}
								contentKey={cms.selectProductSubheader}
								fallbackValue="Select product(s) to be refunded"
							/>
							<div className={style.checkboxContainer}>
								<Checkbox
									name="allProducts"
									label={<CmsContentRenderer.Span
										contentKey={cms.allProductsCheckboxLabel}
										fallbackValue="Entire order"
										className={cx(forms.label, forms.checkboxLabel, style.checkboxOverride)}
									/>}
									labelFirst={true}
									value={"allProducts"}
									controlled={true}
								/>
							</div>
							{orderLineItems?.map((orderLineItem, index) => {
								const isPassProduct = Boolean(orderLineItem.passSerialNbr);

								return (
									<div key={`${orderLineItem.passSerialNbr}-${index}`} className={style.checkboxWrapper}>
										<Checkbox
											name={isPassProduct ? orderLineItem.passSerialNbr : orderLineItem.productName}
											label={<span className={cx(forms.label, forms.checkboxLabel, style.checkboxOverride)}>
												{isPassProduct ? orderLineItem.productName : `${orderLineItem.lineItemDesc} ${centsToDisplay(orderLineItem.itemTotalAmount)}`}
											</span>}
										/>
									</div>
								);
							})}

						</section>
						<section className={takeOverStyle.contactSection}>
							<div className={forms.radioGroup}>
								<CmsContentRenderer.H2
									className={takeOverStyle.sectionHeading}
									contentKey={cms.selectReason}
									fallbackValue="Select a reason for the refund"
								/>

								<div className={forms.radioGroupOptions} role="radiogroup" aria-labelledby="radioGroupLabel">
									<span id="radioGroupLabel" className={style.srOnly}></span>
									{reasonCodes.map(({ description, reasonCodeId }) =>	{

										const isSelectedOption = reasonCodeId === reasonCode ?? false;

										return (
											<div
												key={reasonCodeId}
												onClick={() => setReasonCode(reasonCodeId)}
												className={cx(forms.radioGroupOption)}
											>
												<div
													className={cx(
														forms.radioLabel,
														isSelectedOption && forms.radioChecked,
														isSelectedOption ? collapsible.isOpen : null
													)}
												>
													<div className={style.reason}>{description}</div>
													<div
														className={style.toggle}
														aria-label={
															cmsContent[
																isSelectedOption
																	? cms.collapseRowAria
																	: cms.expandRowAria
															]
														}
													>
														<Chevron overrideClass={collapsible.guestToggleChevron} />
													</div>
												</div>

												{isSelectedOption &&
													<div>
														<CmsContentRenderer.P
															contentKey={cms.duplicateDetails}
															fallbackValue={"Additional details"}
															className={style.details}
														/>
														<Textarea
															maxLength={PublicAppVars.DISPUTE_PURCHASE_MAX_CHAR_LENGTH}
															name={`${reasonCode}_message`}
															id={`${reasonCodeId}_message`}
															aria-labelledby={`${reasonCodeId}_message_label`}
															onFocus={() => { setAnnounceMessage(false); }}
														/>
														<label
															htmlFor={`${reasonCodeId}_message`}
															id={`${reasonCodeId}_message_label`}
															className={style.srOnly}
															onFocus={() => { setAnnounceMessage(true); }}
															onBlur={() => { setAnnounceMessage(false); }}
														>
															{announceMessage ? cmsContent[ cms.duplicateDetails ] : ''}
														</label>
														{announceMessage &&
															<CmsContentRenderer.Div
															 	id={`${reasonCodeId}_message_description`}
															 	className={style.srOnly}
															 	aria-live="polite"
															 	onFocus={() => { setAnnounceMessage(true); }}
															 	onBlur={() => { setAnnounceMessage(false); }}

															 	contentKey={cms.srAnnounceMessage}
															 	fallbackValue={"To enter additional details, press Control+Alt+Shift+Down Arrow (pc) / Control+Option+Shift+Down Arrow (mac). To deselect, press Control+Alt+Shift+Up Arrow (pc) / Control+Option+Shift+Up Arrow (mac)."}
															/>
														}
													</div>
												}
											</div>
										);
									})}
								</div>
							</div>
							<Input
								key={reasonCode}
								type="hidden"
								name='reasonCode'
								value={reasonCode}
								controlled={true}
								hideLabel={true}
							/>
						</section>
						<input
							type="hidden"
							name="orderId"
							value={orderId}
						/>

						<div className={forms.actions}>
							<Button
								isPrimary
								additionalClassNames={forms.action}
								submitting={submitting}
							>
								<CmsContentRenderer.Span
									contentKey={cms.submitButton}
									fallbackValue="Submit"
								/>
							</Button>
							{formHelper.getFieldErrorJsx('')}
							<CmsContentRenderer.P
								className={takeOverStyle.legal}
								contentKey={cms.legal}
								fallbackValue="Responses may take up to 5 business days."
							/>
						</div>
						{formHelper.getFieldErrorJsx('reasonCode')}
					</form>
				</div>
			</TakeOverLayout>
		)}</CmsContentList>
	);
};

DisputePurchase.propTypes = {};

export default DisputePurchase;
