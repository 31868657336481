import React, { useState } from "react";
import { map, reduce, values } from "lodash";
import cx from "classnames";

import CmsContentRenderer, { findContentOrFallback } from "components/data/CmsContentRenderer.js";
import { formatPhoneNumberInternational, PhoneWithType, Email } from "components/data/session-user/SessionUser.js";
import Panel from "components/Panel.js";
import Select from "components/forms/inputs/Select.js";
import Button, { buttonTypeStylePlain } from "components/Button.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import PhoneTypes from "utils/constants/PhoneTypes.js";
import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';
import FormHelper from "utils/FormHelper";
import CmsContentList from "components/data/CmsContentList";

import * as typography from "styles/typography.module.css";
import * as style from "./NotificationContacts.module.css";


const cms = {
	contactSubHeader: "miscText.notifications-contact-subheader",
	editPersonalInformation: "miscText.notifications-contact-edit-cta",
	email: "miscText.notifications-contact-email",
	phone: "miscText.notifications-contact-sms",
	smsDisclaimer: "miscText.notifications-contact-sms-disclaimer",

	homePhone: "miscText.register-info-phonetype-home",
	officePhone: "miscText.register-info-phonetype-office",
	mobilePhone: "miscText.register-info-phonetype-mobile",
	otherPhone: "miscText.register-info-phonetype-other",
};



export const phoneTypeMap = (type,cmsContent) => {
	switch (type) {
		case PhoneTypes.Mobile:
			return cmsContent[ cms.mobilePhone ];
		case PhoneTypes.Work:
			return cmsContent[ cms.officePhone ];
		case PhoneTypes.Other:
			return cmsContent[ cms.otherPhone ];
		default:
			return cmsContent[ cms.homePhone ];
	}
	;
};

const NotificationContacts = ({
	selectedSms,
	updateNotifications,
}) => {
	const [ errorMessage, setErrorMessage ] = useState('');

	const handleSmsChange = async (event, callback) => {
		setErrorMessage('');
		try {
			await graphqlErrorMiddleware(callback({
				variables: {
					phoneNumber: event.value,
				},
			}));
		} catch (error) {
			console.error(error);
			// FIX ME!!! I'm only placeholder text for unexpected errors while toggling checkboxes.
			// https://reflexions.slack.com/archives/CDTQPPXJ8/p1633718568260400
			setErrorMessage('There was a problem with your request. Please try again.');
		}
	};

	return (
		<Panel>
			<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (<>

				<div className={style.headerWrapper}>
					<div className={style.sectionWrapper}>
						<CmsContentRenderer.H2
							contentKey={cms.contactSubHeader}
							fallbackValue="How You'll Receive Notifications"
							className={cx(typography.h8, style.header)}
						/>

						<Button to={getPathByRoute(routeKeys.AccountPersonalInformation)}
							typeStyle={buttonTypeStylePlain}
						>
							<CmsContentRenderer.Span
								fallbackValue="Edit in Personal Information"
								className={style.link}
								contentKey={cms.editPersonalInformation}
							/>
						</Button>
					</div>
				</div>

				<div>
					<div className={cx(style.sectionWrapper, style.email)}>
						<CmsContentRenderer.Span
							fallbackValue="Email"
							contentKey={cms.email}
							className={style.label}
						/>
						<div className={style.emailText}>
							<Email />
						</div>
					</div>

					<div className={style.sectionWrapper}>
						<CmsContentRenderer.Span
							contentKey={cms.phone}
							fallbackValue="Phone"
							className={cx(style.label, style.phoneLabel)}
						/>

						<div>
							<PhoneWithType>{phones => {
								const phoneOptions = reduce(phones, (filteredPhones, { type, number, country }) => {
									if (country !== 'US') {
										return filteredPhones;
									}

									const phoneNumber = formatPhoneNumberInternational(number, country);
									const phoneType = phoneTypeMap(type, cmsContent);

									return [ ...filteredPhones, {
										label: `${phoneNumber} (${phoneType})`,
										value: number,
									} ];
								}, []);

								return <Select
									error={errorMessage && FormHelper.errorJsx(errorMessage)}
									defaultValue={selectedSms}
									options={phoneOptions}
									className={style.phoneDropdown}
									classNamePrefix={style.phoneDropdown}
									hideLabel={true}
									ariaLabel={findContentOrFallback(cmsContent, cms.phone, 'Phone')}
									onChange={event => handleSmsChange(event, updateNotifications)}
								/>;
							}}</PhoneWithType>

							<CmsContentRenderer.Span
								contentKey={cms.smsDisclaimer}
								fallbackValue="Regular messaging rates apply"
								className={style.disclaimer}
							/>
						</div>
					</div>
				</div>
			</>)}</CmsContentList>
		</Panel>
	);
};

export default NotificationContacts;
