import PublicAppVars from 'utils/PublicAppVars';
import { keys } from 'lodash';

export const APP_NAME = 'Boston App';


/** @deprecated */
export const ERROR_NUMBERS = {
	APOLLO_PRERENDER_ERROR: 1,
	SIGNIN_API_SUCCESS_FALSE: 10,
	SIGNIN_UNKNOWN_2: 11,
	SUCCESS_FALSE: 20,
	REGISTER_API_FAIL: 21,
	REGISTER_PAYMENT_API_SUCCESS_FALSE: 30,
	REGISTER_PAYMENT_API_FAIL: 31,
};

// TODO: move these somewhere specific to tokens
export const active = 'Active';
export const suspended = 'Suspended';
export const closed = 'Closed';
export const lostStolen = 'Lost/Stolen';
export const replaced = 'Replaced';
export const terminated = 'Terminated';
export const expired = 'Expired';
export const STORED_VALUE = 'StoredValue';
export const ParkingOnly = 'ParkingOnly';
export const TransitOnly = 'TransitOnly';
export const Refundable = 'Refundable';
export const Unrestricted = 'Unrestricted';
export const Inactive = 'Inactive';
export const PendingActivation = "Pending Activation";
export const IssueSerializedToken = "Issue Serialized Token";


export const TOKEN = {
	ACTION: {
		primary: "Primary",
		block: 'Block',
		unblock: 'Unblock',
		terminate: 'Terminate',
	},
	STATUS: {
		active,
		suspended,
		closed,
		lostStolen,
		replaced,
		terminated,

		// Note "expired" is a FE mapped value not an actual token BO status.
		expired,
	},
	DESCRIPTION: {
		PendingActivation,
		Inactive,
		Active: active,
		PENDING: 'Pending activation',
		WRITEOFF: 'Write-off',
		LOST: 'Lost',
		FRAUD: 'Potential fraud',
		INACTIVE: 'Inactive',
		FROZEN: 'Frozen',
		CLOSING: 'Close account in progress',
		NEGATIVE: 'Negative balance',
		DEFECIVE: 'Defective',
		UPGRADED: 'Upgraded',
		EXPIRED: 'Expired',
		CLOSED: 'Account closed',
		STOLEN: 'Stolen',
	},
};

// Used in TakeOverLayout for Cancel btn to use `history.goBack()`
export const HEADER_GO_BACK = "go-back";

export const STORED_VALUE_WITH_SPACE = 'Stored Value';
export const PAYGO = 'PAYGO';

export const ALT_DATE_FORMAT = string => string.replace(/^(?!(May))(\D\D\D)\s/, '$2. ');

export const UNRESTRICTED = 'Unrestricted';

export const POPULAR_TAB = "Most Popular";
export const SUBWAY_TAB = "Subway";
export const BUS_TAB = "Bus";
export const COMMUTER_RAIL_TAB = "Commuter Rail";
export const FERRY_TAB = "Ferry";
export const RAIL = 'Rail';

// Updated tab index mapping
// https://reflexions.slack.com/archives/CCF68M49M/p1648065213923429?thread_ts=1647958459.012789&cid=CCF68M49M
// Fare option screen           displayGroup ID
// Most Popular                     1
// Subway                           2
// Bus                              3
// Commuter Rail                    4
// Ferry                            5
// Add Money (General)    			10 (edited)

// as of 08/11/2022
// https://reflexions.atlassian.net/browse/MBTA-2129
// Per discussion w/ Kirk on Slack, please update the catalogue product sorting as follows:
// 10 - Stored value
// 1 - Most Popular
// 2 - Subway
// 3 - Bus
// 4 - Commuter Rail, (disabled until Pilot 3)
// 5 - Ferry
export const PRODUCT_TAB_TO_INDEX_mapping = {
	[ POPULAR_TAB ]: 1,
	[ SUBWAY_TAB ]: 2,
	[ BUS_TAB ]: 3,
	[ COMMUTER_RAIL_TAB ]: 4,
	[ FERRY_TAB ]: 5,
};

// dynamically generate list based on mapping order
export const PRODUCT_TABS_LIST = keys(PRODUCT_TAB_TO_INDEX_mapping);

export const DISPLAY_GROUP_MIN_RANGE = PRODUCT_TAB_TO_INDEX_mapping[ POPULAR_TAB ];
// remain max range = 4 since we anyways hide the COMMUTER_RAIL_TAB
export const DISPLAY_GROUP_MAX_RANGE = PRODUCT_TAB_TO_INDEX_mapping[ FERRY_TAB ];

export const ALL_MODES = 'All Modes';
export const BUS = 'Bus';
export const RAPID_TRANSIT = 'Rapid Transit';
export const COMMUTER_RAIL = 'Commuter Rail';
export const COMMUTER_FERRY = 'Commuter Ferry';
export const INNER_HARBOR_FERRY = 'Inner Harbor Ferry';
export const PC_BUS = 'PC Bus';
export const RETAILER = 'Retailer';

export const PREFIX_PRIMARY = 'primary';
export const PREFIX_BACKUP = 'backup';

export const RECURRING = 'Recurring';
export const THRESHOLD = 'Threshold';
export const BIWEEKLY = 'BiWeekly';
export const WEEKLY = 'Weekly';
export const MONTHLY = 'Monthly';

export const DAY_NAMES = {
	MON: 'Monday',
	TUE: 'Tuesday',
	WED: 'Wednesday',
	THU: 'Thursday',
	FRI: 'Friday',
	SAT: 'Saturday',
	SUN: 'Sunday',
};

// We'll want these translations to come from the CMS eventually
export const SHORT_EN_MONTHS = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'June',
	'July',
	'Aug',
	'Sept',
	'Oct',
	'Nov',
	'Dec',
];

export const SHORT_EN_DAYS = [
	'SUN',
	'MON',
	'TUE',
	'WED',
	'THU',
	'FRI',
	'SAT',
];

export const sequenceGen = (start, n) => {
	const seq = [ start ];
	for (let i = 1; i < n; i++) {
		seq.push(start + i);
	}
	return seq;
};

export const emotionCacheKey = 'our-cache-key';
export const SUBSYSTEM_ID = 'ABP';

export const APPLE_PAY = 'Apple Pay';
export const GOOGLE_PAY = 'Google Pay';

export const TRANSIT_ONLY = 'TransitOnly';

export const ACCOUNT_TYPE_CHECKING = 'Checking';
export const ACCOUNT_TYPE_SAVING = 'Saving';


export const CARD_TYPE_CHARLIE = "Smartcard";
export const CARD_TYPE_CONTACTLESS = "Bankcard"; // EMV
export const MEDIA_TYPE_STANDARD_BANKCARD = "StandardBankcard"; // EMV
export const BALANCE_PRODUCT = "Balance"; // Charlie card stored value
export const STORED_VALUE_UNRESTRICTED ="StoredValue Unrestricted";
export const STORED_VALUE_TRANSIT_ONLY ="StoredValue TransitOnly";
// https://reflexions.atlassian.net/issues/MBTA-3094?jql=fixVersion%20%3D%2011257%20ORDER%20BY%20priority%20DESC%2C%20created%20ASC
export const IOS_END_OR_V_TOKEN_MEDIA = 'IosEndorVTokenMedia';
export const EMV_VIRTUAL_CARD = "EMVVirtualCard";


export const CHARLIE_CARD_VIRTUAL = 'virtual';
export const CHARLIE_CARD_PHYSICAL = 'physical';

export const MOBILE_CARD_WALLET = 'Credit Card Mobile Wallet';


export const Load_Type_Autoload = 'Autoload';
export const IssueMedia = 'IssueMedia';

// dummy phone number for submit Contact Us form
// https://reflexions.slack.com/archives/CCF68M49M/p1635369774037000?thread_ts=1635357326.032900&cid=CCF68M49M
export const SAMPLE_PHONE_NUMBER = '7185120000';

export const countryCodeUS = 'US';

export const DIRECT_PAY = 'Direct Pay';

export const REFUND_STATUS = {
	PartialRefundCompleted: 'PartialRefundCompleted',
	FullRefundCompleted: 'FullRefundCompleted',
	PartialRefundPending: 'PartialRefundPending',
	FullRefundPending: 'FullRefundPending',
	PartialRefundFailed: 'PartialRefundFailed',
	FullRefundFailed: 'FullRefundFailed',
	PartialRefundRejected: 'PartialRefundRejected',
	FullRefundRejected: 'FullRefundRejected',
	PreAccepted: 'PreAccepted',
	NotApplicable: 'NotApplicable',
};

export const LONG_TERM_PASS_DAYS_MIN = 30;
