import { gql } from "@apollo/client";
import React from 'react';
import cx from "classnames";
import {
	map,
	values,
	startCase,
} from 'lodash';

import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import CmsContentList from 'components/data/CmsContentList.js';
import StdQuery from 'components/data/StdQuery.js';
import EulaData from 'components/data/EulaData.js';
import { ALT_DATE_FORMAT } from "utils/Constants";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline";
import { getDateStringSync } from 'components/ServerDate.js';

import Panel from 'components/Panel.js';

import * as panelStyles from 'components/Panel.module.css';
import * as style from "./TermsAndConditions.module.css";
import * as typography from "styles/typography.module.css";
import Button, {
	buttonTypeStylePlain,
} from 'components/Button.js';


const cms = {
	subheader: 'miscText.terms-subheader',
	description: 'miscText.terms-description',
	lastUpdate: 'miscText.terms-last-update',
};

export const GET_ACCEPTED_EULAS_IDS = gql`
	query AcceptedEulasIds {
		eulas {
			acceptedEulas (
				currentOnly: true,
			) {
				eulaId
			}
		}
	}
`;

const TermsAndConditions = () => {
	return (
		<CmsContentList list={values(cms)}>{() =>
			<Panel>
				<div className={style.acceptedTCHeaderWrapper}>
					<CmsContentRenderer.H2
						contentKey={cms.subheader}
						fallbackValue="Terms &amp; Conditions"
						className={cx(typography.h7, panelStyles.heading)}
						data-qa="PanelTermsTitle"
					/>
					<CmsContentRenderer.Span
						contentKey={cms.description}
						fallbackValue="Latest version of site terms and conditions you've agreed to."
						className={panelStyles.cardSubtitle}
					/>
				</div>

				<StdQuery query={GET_ACCEPTED_EULAS_IDS}>{({ data }) => {
					const { acceptedEulas } = data.eulas;

					return map(acceptedEulas, ({ eulaId }) => (
						<EulaData
							eulaId={eulaId}
							key={eulaId}
							returnDocument={true}
						>{({
								info: {
									name,
									noticeDate,
								},
								document: {
									document,
								},
							}) => {
								const formattedDate = getDateStringSync({
									dateISO: noticeDate,
									options: {
										day: "numeric",
										month: "numeric",
										year: 'numeric',
									},
									altFormat: ALT_DATE_FORMAT,
								});

								// BO returns the names with no spaces.
								// We will add the spaces and trim to make it a bit more uniform.
								// Incase they do fix this in the future we will make sure we are not adding double spaces
								const formattedName = startCase(name);

								return (
									<div className={style.acceptedTCWrapper}>
										<div className={style.tcName}>
											<Button
												typeStyle={buttonTypeStylePlain}
												target="_blank"
												external
												to={document}
											 >
												{formattedName}
											</Button>
										</div>
										<span className={style.tcDate}>
											<CmsContentRenderedInline
												contentKey={cms.lastUpdate}
												fallbackValue={`Updated ${formattedDate}`}
												variables={{ date: formattedDate }}
											/>
										</span>
									</div>
								);
							}}</EulaData>
					));
				}}</StdQuery>
			</Panel>
		}</CmsContentList>
	);
};

export default TermsAndConditions;
