import {
	get,
	clone,
} from "lodash";

// The second level of notification type category used to group notification types within a category.
export default class WSNotificationTypeSubCategoryInfo {
	constructor(obj = {}) {
		this.name = get(obj, "name", null);
		this.description = get(obj, "description", null);
	}

	static fromBackoffice(data) {
		const result = new WSNotificationTypeSubCategoryInfo(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSNotificationTypeSubCategoryInfoType = [ `
	type WSNotificationTypeSubCategoryInfo {
		name: String
		description: String
	}
` ];
