import { gql } from "@apollo/client";
import {
	get,
	filter,
	head,
} from 'lodash';
import React from 'react';
import StdQuery from '../StdQuery.js';
import { parsePhoneNumber } from "libphonenumber-js";
import Untranslatable from "../../Untranslatable.js";
import { passFragment, tokenFragment } from "../transit-account/SessionTransitAccount.js";
import ServerDate from "components/ServerDate.js";
import { TRAVEL_TOKEN_TYPES } from "server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";

const customerSelector = "session.customer";
const contactSelector = `${customerSelector}.contact`;

export const GET_FULLNAME = gql`query FullName {
	session {
		customer {
			contact {
				name {
					firstName
					middleInitial
					lastName
				}
				id
			}
			id
		}
		id
	}
}`;

export const GET_CONTACT_IDS = gql`query ContactIds {
	session {
		id
		customer {
			id
			contact {
				id
			}
		}
	}
}`;

export const useCustomerName = () => {
	const { data: customerNameData } = useStdQuery(GET_FULLNAME);
	return customerNameData?.session.customer?.contact.name ?? null;
};

export const useContactIds = () => {
	const queryResult = useStdQuery(GET_CONTACT_IDS);

	return {
		contactIds: queryResult?.data?.session.customer?.contact.id,
		queryResult,
	 };
};


export const FullName = ({ initials = false }) => {
	const name = useCustomerName();

	if (!name) {
		return null;
	}

	const {
		firstName = '',
		middleInitial = '',
		lastName = '',
	} = name;

	return <Untranslatable elementType="span">
		{initials
			? get(firstName, '0', '') + get(lastName, '0', '')
			: `${firstName} ${middleInitial && middleInitial + ' '}${lastName}`
		}
	</Untranslatable>;
};

export const GET_FIRSTNAME = gql`query FirstName {
	session {
		customer {
			contact {
				name {
					firstName
				}
				id
			}
			id
		}
		id
	}
}`;

export class FirstName extends React.Component {
	render() {
		return (
			<StdQuery query={GET_FIRSTNAME}>
				{({ data }) =>
					<Untranslatable elementType="span">
						{get(data, contactSelector + ".name.firstName")}
					</Untranslatable>}
			</StdQuery>
		);
	}
}

export const GET_EMAIL = gql`query Email {
	session {
		customer {
			contact {
				email
				id
			}
			id
		}
		id
	}
}`;

export class ActiveSince extends React.Component {
	render() {
		return (
			<StdQuery query={gql`query ActiveSince {
				session {
					customer {
						id
						insertedDtm
					}
					id
				}
			}
		`}>
				{({ data }) => (
					<ServerDate
						dateISO={get(data, customerSelector + ".insertedDtm")}
						// expected format 'MMMM DD, YYYY'
						options={{ month: 'long', day: "numeric", year: "numeric" }}
					/>)}
			</StdQuery>
		);
	}
}


export class Email extends React.Component {
	render() {
		return (
			<StdQuery query={GET_EMAIL}>
				{({ data }) =>
					<Untranslatable elementType="span">{get(data, contactSelector + ".email")}</Untranslatable>}
			</StdQuery>
		);
	}
}

export const GET_USERNAME = gql`query GET_USERNAME {
	session {
		customer {
			contact {
				username
				id
			}
			id
		}
		id
	}
}`;

export class Username extends React.Component {
	render() {
		return (
			<StdQuery query={GET_USERNAME}>
				{({ data }) => get(data, contactSelector + ".username")}
			</StdQuery>
		);
	}
}


export const GET_CUSTOMER_CONTACT_IDS = gql`query GET_CUSTOMER_CONTACT_IDS {
	session {
		customer {
			contact {
				id
			}
			id
		}
		transitAccountId
		id
	}
}`;
export const GetCustomerContactIds = (props) => (
	<StdQuery query={GET_CUSTOMER_CONTACT_IDS}>
		{(data) => props.children({
			...data,
			customerId: get(data.data, customerSelector + ".id"),
			contactId: get(data.data, contactSelector + ".id"),
			transitAccountId: get(data.data, 'session.transitAccountId'),
		})}
	</StdQuery>
);


export const PIN_QUERY = gql`query PIN_QUERY {
	session {
		customer {
			contact {
				pin
				id
			}
			id
		}
		id
	}
}`;

export class Pin extends React.Component {
	render() {
		return (
			<StdQuery query={PIN_QUERY}>
				{({ data }) => get(data, contactSelector + ".pin")}
			</StdQuery>
		);
	}
}

export const GET_SYSTEMGENPASSWORD_SETTING = gql`query systemGenPassword {
	session {
		customer {
			contact {
				systemGenPassword
				id
			}
			id
		}
		id
	}
}`;

export const PIN_AND_SYSTEMGENPASSWORD_QUERY = gql`query PIN_AND_SYSTEMGENPASSWORD_QUERY {
	session {
		customer {
			contact {
				pin
				systemGenPassword
				id
			}
			id
		}
		id
	}
}`;

export const SUBSYSTEM_ACCOUNT_INFO_FIELDS = gql`
	fragment SubsystemAccountInfoFields on WSSubsystemAccountInfo {
		riderClassId
		riderClassDescription
		subsystemAccountReference
		subsystemAccountDetailedInfo {
			purses {
				nickname
				balance
				purseType
				purseTypeDescription
				purseRestriction
				purseRestrictionDescription
				supportsTransfer
			}
			accountStatus
			tokens(archived: false) {
				${tokenFragment}
			}
			id
			debtCollectionInfo {
				action
				amountDue
				bankcardCharged {
					maskedPan
					expirationMonth
					expirationYear
					creditCardType
				}
			}
			passes {
				${passFragment}
			}
		}
		id
	}
`;

export const USER_TRAVEL_CARDS_QUERY = gql`
	${SUBSYSTEM_ACCOUNT_INFO_FIELDS}
	query UserPaymentMethods {
		subsystemAccountInfoQ {
			...SubsystemAccountInfoFields
		}
	}`;

export const USER_PAYMENT_METHODS_QUERY = gql`
	query UserPaymentMethods {
		subsystemAccountInfoQ {
			subsystemAccountReference
			subsystemAccountDetailedInfo {
				purses {
					nickname
					balance
					purseType
					purseTypeDescription
					purseRestriction
					purseRestrictionDescription
				}
				accountStatus
				tokens(archived: false) {
					${tokenFragment}
				}
				id
				debtCollectionInfo {
					action
					amountDue
					bankcardCharged {
						maskedPan
						expirationMonth
						expirationYear
						creditCardType
					}
				}
			}
			id
		}
	}
`;

export const USER_PAYMENTS_FOR_CREDIT_PANEL = gql`
	query CreditPanel {
		subsystemAccountInfoQ {
			subsystemAccountReference
			subsystemAccountDetailedInfo {
				purses {
					balance
					purseTypeDescription
				}
				tokens {
					tokenId
					status
					statusDescription
					frontendStatus
					subsystemTokenType
					tokenInfo {
						maskedPan
						expirationMonth
						expirationYear
						creditCardType
						tokenNickname
					}
					id
				}
				id
			}
			id
		}
	}
`;

// debtCollectionInfoQ returns [WSSubsystemAccountInfo]
export const USER_DEBT_QUERY = gql`query USER_DEBT_QUERY {
	debtCollectionInfoQ {
		nickname
		subsystemAccountReference
		subsystemAccountDetailedInfo {
			tokens(archived: false) {
				${tokenFragment}
			}
			debtCollectionInfo {
				action
				amountDue
				bankcardCharged {
					maskedPan
					expirationMonth
					expirationYear
					creditCardType
				}
			}
		}
	}
}`;

export const FILTER_DATE_RANGE_QUERY = gql`
	query FilterDateRangeQuery (
		$startDateTime: String
		$endDateTime: String
		$specificDate: String
		$paymentMethod: String
		$retrievalRefNbr: String
		$bankChargeStatusFilter: Int
	){
		ridesChargesQ (
			startDateTime: $startDateTime
			endDateTime: $endDateTime
			specificDate: $specificDate
			paymentMethod: $paymentMethod
			retrievalRefNbr: $retrievalRefNbr
			bankChargeStatusFilter: $bankChargeStatusFilter
		){
			lineItem {
				paymentId
				openedDateTime
				settledDateTime
				requestedAmount
				authorizedAmount
				maxAggregationAmount
				totalUsedAmount
				remainingAmount
				retrievalRefNbr
				response
				responseDescription
				status
				statusDescription
				isCorrectable
				numberOfTrips
				bankcardCharged {
					cardBrand
					maskedPan
					expirationMonth
					expirationYear
				}
			}
			transitId
			subsystem
		}
	}
`;


export const formatPhoneNumberInternational = (phoneStr, country) => {
	const phoneNumber = parsePhoneNumber(phoneStr, country);
	return phoneNumber.formatInternational();
};

export const GET_PHONE = gql`query Phone {
	session {
		customer {
			contact {
				phone {
					number
					type
					country
				}
				id
			}
			id
		}
		id
	}
}`;

export class Phone extends React.Component {
	render() {
		return (
			<StdQuery
				query={GET_PHONE}
			>
				{({ data }) => {
					const phone = formatPhoneNumberInternational(
						get(data, contactSelector + ".phone[0].number", ""),
						get(data, contactSelector + ".phone[0].country", "US"),
					);
					return phone;
				}}
			</StdQuery>
		);
	}
}


export const GET_SUMMARY = gql`
	query getMonthlySummary(
		$startDateTime: String
		$endDateTime: String
		$specificDate: String
		$paymentMethod: String
	){
		monthlySummary(
			startDateTime: $startDateTime
			endDateTime: $endDateTime
			specificDate: $specificDate
			paymentMethod: $paymentMethod
		){
			id
			spent
			rides
			summaryDateIso
		}
	}
`;

export class Phone1withType extends React.Component {
	render() {
		return (
			<StdQuery
				query={GET_PHONE}
			>
				{({ data }) => {
					const phone1 = formatPhoneNumberInternational(
						get(data, contactSelector + ".phone[0].number", ""),
						get(data, contactSelector + ".phone[0].country", "US"),
					);
					const phoneType = get(data, contactSelector + ".phone[0].type", "");
					return `${phone1} ${phoneType}`;
				}}
			</StdQuery>
		);
	}
}

export const PhoneWithType = ({ children }) => {
	return (
		<StdQuery query={GET_PHONE}>
			{({ data }) => {
				const phones = get(data, contactSelector + ".phone", []);
				// filter out deleted phones which are returned from BO as a string like '+100'
				return children(phones);
			}}
		</StdQuery>
	);
};

export const GET_SESSION_TRANSIT_ACCOUNT_ID = gql`query TransitAccountId {
	session {
		id
		transitAccountId
	}
}
`;

export const SingleTokenNumber = ({ subsystemAccountReference }) => {
	return (
		<StdQuery
			query={USER_PAYMENT_METHODS_QUERY}
		>
			{({ data }) => {
				const subsystemAccountInfoQ = get(data, 'subsystemAccountInfoQ', []);

				const filteredCard = head(filter(subsystemAccountInfoQ, subsystemAccountInfoQItem =>
					subsystemAccountInfoQItem.subsystemAccountReference === subsystemAccountReference));

				const token = head(filteredCard?.subsystemAccountDetailedInfo.tokens);
				const tokenInfo = get(token, 'tokenInfo', {});
				const tokenNumber = tokenInfo.tokenType === TRAVEL_TOKEN_TYPES.bankcard
					? tokenInfo.maskedPan
					: tokenInfo.serialNumber.substring(15, 20);

				return ` ...${tokenNumber}`;
			}}
		</StdQuery>
	);
};
