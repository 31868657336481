import React, { useState } from 'react';
import cx from "classnames";
import {
	map,

	values,
	filter,
} from 'lodash';
import {
	FullName,
	Email,
	PhoneWithType,
	formatPhoneNumberInternational,
} from "components/data/session-user/SessionUser.js";

import Panel from 'components/Panel';
import EditPersonalInformation from 'components/modals/EditPersonalInformation.js';
import EditAddress, { MANAGE_ADDRESS } from 'components/modals/EditAddress.js';
import Addresses, { GET_CUSTOMER_ADDRESSES } from "components/data/session-user/Addresses.query.js";
import { useModalContext } from 'context/ModalProvider.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import Toast from "components/Toast.js";
import preventDefault from "utils/PreventDefault.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import StandardMutation from 'components/data/StandardMutation.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import WSAddress from 'server/api-types/WSAddress.js';
import { log, levels } from 'utils/Logger.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { phoneTypeMap } from "components/account/panels/NotificationContacts.js";
import { cityStateZipStr } from 'components/data/session-user/Address.js';
import StdQuery from 'components/data/StdQuery.js';
import {
	TYPE_DANGER,
	OPTION_EDIT,
	OPTION_REMOVE,
} from 'components/Dropdown';
import ManageMenu from '../ManageMenu.js';
import LoadingIcon from 'components/icons/LoadingIcon.js';

import CmsContent from "components/data/CmsContent.js";
import { IsNotAlternate } from "components/data/alternates/SessionAlternate.js";
import EditEmail from "components/modals/EditEmail.js";
import {
	GET_NOTIFICATION_PREFERENCES,
} from "components/data/notification-preferences/NotificationPreferences.query.js";
import Button, { buttonTypeStylePlain } from 'components/Button.js';

import * as panelStyle from 'components/Panel.module.css';
import * as buttonStyles from 'components/Button.module.css';
import * as personalInformationStyle from 'components/account/panels/PersonalInformation.module.css';
import * as typography from "styles/typography.module.css";
import useStdQuery from 'components/data/hooks/useStdQuery.js';
import { FUNDING_SOURCES_GET_QUERY } from 'graphql-queries/FundingSources.js';

const cms = {
	infoSubheader: "miscText.personal-info-subheader",
	infoName: "miscText.personal-info-name",
	edit: "miscText.general-edit",
	infoEditAria: "miscText.personal-info-edit-aria",
	infoPhoneOne: 'miscText["personal-info-edit-phone1.label"]',
	infoPhoneTwo: 'miscText["personal-info-edit-phone2.label"]',
	infoPhoneThree: 'miscText["personal-info-edit-phone3.label"]',
	email: 'miscText.personal-info-email',
	emailAria: 'miscText.personal-info-email-edit-aria',
	infoSmsNotifications: "miscText.personal-info-smsnotifications",
	addressesSubheader: "miscText.personal-addresses-subheader",
	addAddressCta: "miscText.personal-addresses-add-cta",
	addAddressCtaAria: 'miscText["personal-addresses-add-cta-aria"]',
	// duplicated in <ManageMenu />
	// manageLabel: 'miscText["personal-addresses-manage.label"]',
	manageAria: 'miscText["personal-addresses-manage.aria"]',
	manageEdit: "miscText.personal-addresses-manage-edit",
	manageDelete: "miscText.personal-addresses-manage-delete",
	defaultShippingLabel: 'miscText["general-address.default-shipping"]',
	defaultBillingLabel: 'miscText["general-address.default-billing"]',
	lastAddressToastTitle: 'miscText["personal-addresses-manage-delete-error.header"]',
	lastAddressToastDescription: 'miscText["personal-addresses-manage-delete-error.description"]',
	deleteDefaultAddressToastTitle: 'miscText["personal-addresses-manage-delete-error-default.header"]',
	deleteDefaultAddressToastDescription: 'miscText["personal-addresses-manage-delete-error-default.description"]',
	deleteAssociatedAddressToastTitle: 'miscText["personal-addresses-manage-delete-error-payment.header"]',
	deleteAssociatedAddressToastDescription: 'miscText["personal-addresses-manage-delete-error-payment.description"]',

	homePhone: "miscText.register-info-phonetype-home",
	officePhone: "miscText.register-info-phonetype-office",
	mobilePhone: "miscText.register-info-phonetype-mobile",
	otherPhone: "miscText.register-info-phonetype-other",
};

const PersonalInformation = () => {
	const { setModal } = useModalContext();
	const [ smsNotification, setSmsNotification ] = useState(null);
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ loading, setLoading ] = useState(false);
	const response = useStdQuery(FUNDING_SOURCES_GET_QUERY);

	const {
		fundingSources,
	} = response?.data?.session ?? {};



	const onModalClose = () => {
		setModal(null);
	};

	const openEditInfoModal = (smsNotification) => {
		setModal(
			<EditPersonalInformation
				onModalClose={onModalClose}
				smsNotification={smsNotification}
			/>,
		);
	};

	const openEditEmailModal = () => {
		setModal(
			<EditEmail onModalClose={onModalClose} />,
		);
	};

	const openEditAddressModal = ({ address, manageAddressMutation }) => {
		setModal(
			<EditAddress
				onModalClose={onModalClose}
				address={address}
				manageAddressMutation={manageAddressMutation}
			/>,
		);
	};


	const errorMessageCallback = (cmsKeys, fallbackValues, cmsContent) => {
		setToast(<Toast
			type="error"
			title={<CmsContentRenderer
				cmsContent={cmsContent}
				contentKey={cmsKeys.title}
				fallbackValue={fallbackValues.title}
			/>}
			text={<CmsContentRenderer
				cmsContent={cmsContent}
				contentKey={cmsKeys.description}
				fallbackValue={fallbackValues.description}
			/>}
			onClosed={removeToast}
		/>);
	};

	const handleManageAddress = async ({ option, address, callbackMutation, isLastAddress = false, cmsContent }) => {
		if (option === OPTION_EDIT) {
			openEditAddressModal({
				address,
				manageAddressMutation: callbackMutation,
			});
		} else if (option === OPTION_REMOVE) {


			if (address.primaryBilling || address.primaryShipping) {
				errorMessageCallback({
					title: cms.deleteDefaultAddressToastTitle,
					description: cms.deleteDefaultAddressToastDescription,
				}, {
					title:"Can't remove default address",
					description: "You cannot delete the default billing or shipping address. Set another address to be the default before deleting this one.",
				},
				cmsContent);
				return;
			}

			if (isLastAddress) {
				errorMessageCallback({
					title: cms.lastAddressToastTitle,
					description: cms.lastAddressToastDescription,
				}, {
					title: "Can't remove address",
					description: "You must have at least one saved address on your account",
				},
				cmsContent);
				return;
			}
			const associatedPaymentMethods = filter(fundingSources, ((paymentMethod) => paymentMethod.billingAddressId === address.addressId )) || [];

			if (associatedPaymentMethods.length) {
				errorMessageCallback({
					title: cms.deleteAssociatedAddressToastTitle,
					description: cms.deleteAssociatedAddressToastDescription,
				}, {
					title: "Can't remove a saved payment's address",
					description: "You cannot delete this address, as it is associated with one or more of your saved payment methods.",
				},
				cmsContent);
				return;
			}


			setLoading(true);
			try {
				await graphqlErrorMiddleware(callbackMutation({
					variables: {
						address: new WSAddress(address),
						addressId: address.addressId,
						primaryBilling: address.primaryBilling,
						primaryShipping: address.primaryShipping,
						remove: true,
					},
				}));
			} catch (errorReport) {
				log(
					null,
					levels.verbose,
					{
						message: `remove address error`,
						errorReport,
					},
				);
			} finally {
				setLoading(false);
			}
		}
	};

	const phoneLabelMap = (index) => {
		switch (index) {
			case 1:
				return { key: cms.infoPhoneOne, fallbackValue: "Phone 1" };
			case 2:
				return { key: cms.infoPhoneTwo, fallbackValue: "Phone 2" };
			case 3:
				return { key: cms.infoPhoneThree, fallbackValue: "Phone 3" };
		}

		return { fallbackValue: `Phone ${index}` };
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Panel>
				<div className={panelStyle.section}>
					<div className={panelStyle.header}>
						<CmsContentRenderer.H2 contentKey={cms.infoSubheader}
							fallbackValue="Personal Information"
							className={cx(typography.h7, panelStyle.heading)}
							data-qa="PanelInformationTitle"
						/>

						<IsNotAlternate>
							<Button
								typeStyle={buttonTypeStylePlain}
								additionalClassNames={cx(buttonStyles.link, buttonStyles.linkBlue)}
								onClick={preventDefault(() => openEditInfoModal(smsNotification))}
								data-qa="PanelInformationAction"
								aria-label={cmsContent[ cms.infoEditAria ]}
							>
								<CmsContentRenderer.Span contentKey={cms.edit} fallbackValue="Edit" />
							</Button>
						</IsNotAlternate>
					</div>

					<div className={panelStyle.info}>
						<CmsContentRenderer.Span
							className={panelStyle.infoLabel}
							contentKey={cms.infoName}
							fallbackValue="Name"
						/>
						<span className={cx(panelStyle.infoValue, panelStyle.contact)}
							data-qa="PanelInformationFullName"
						>
							<FullName />
						</span>
					</div>

					<StdQuery query={GET_NOTIFICATION_PREFERENCES}>{({ data }) => {
						const smsPhones = data.notificationsPreferences.map(pref => pref.smsPhone);

						return (
							<PhoneWithType>{phones => phones.map((phone, index) => {
								const unformatPhone = phone.number;
								const notificationPhoneNum = smsPhones.find(phone => phone === unformatPhone);

								const phoneNumber = formatPhoneNumberInternational(
									unformatPhone,
									phone.country ?? "US",
								);

								const phoneType = phoneTypeMap(phone.type, cmsContent);
								const phoneIndex = index + 1;

								if (notificationPhoneNum) {
									setSmsNotification(notificationPhoneNum);
								}

								const phoneLabel = phoneLabelMap(phoneIndex);

								return (
									<div key={`${phoneNumber}_${index}`}>
										<div className={cx(panelStyle.info, notificationPhoneNum && panelStyle.sms)}>
											<CmsContentRenderer.Span contentKey={phoneLabel.key}
												fallbackValue={phoneLabel.fallbackValue}
												className={panelStyle.infoLabel} />
											<span className={panelStyle.infoValue} data-qa="PanelInformationPhone">
												{`${phoneNumber} (${phoneType})`}
											</span>
										</div>
										{notificationPhoneNum ?
											<div className={panelStyle.smsIndicatorContainer}>
												<CmsContentRenderer.Span
													contentKey={cms.infoSmsNotifications}
													fallbackValue="This number will recieve SMS notifications"
													className={panelStyle.smsIndicator}
												/>
											</div>
											: null
										}
									</div>
								);
							})}</PhoneWithType>
						);
					}}</StdQuery>
				</div>

				<div className={panelStyle.section}>
					<div className={panelStyle.info}>
						<CmsContentRenderer.Span
							className={panelStyle.infoLabel}
							contentKey={cms.email}
							fallbackValue="Email"
						/>
						<span className={cx(panelStyle.infoValue, panelStyle.contact, panelStyle.truncate)}
							data-qa="PanelInformationEmail"
						>
							<Email />
						</span>
						<IsNotAlternate>
							<Button typeStyle={buttonTypeStylePlain}
								additionalClassNames={cx(buttonStyles.link, buttonStyles.linkBlue)}
								onClick={openEditEmailModal}
								data-qa="PanelEmailActions"
								aria-label={cmsContent[ cms.emailAria ]}
							>
								<CmsContentRenderer.Span contentKey={cms.edit} fallbackValue="Edit" />
							</Button>
						</IsNotAlternate>
					</div>
				</div>

				<IsNotAlternate>
					<StandardMutation
						mutation={MANAGE_ADDRESS}
						refetchQueries={[ { query: GET_CUSTOMER_ADDRESSES } ]}
						showLoadingState={false}
					>{manageAddress => (
							<div className={panelStyle.section}>
								<div className={panelStyle.header}>
									<CmsContentRenderer.H2 contentKey={cms.addressesSubheader}
										fallbackValue="Saved addresses"
										className={cx(typography.h7, panelStyle.heading)}
										data-qa="PanelAddressTitle"
									/>

									<Button typeStyle={buttonTypeStylePlain}
										additionalClassNames={cx(buttonStyles.link, buttonStyles.linkBlue)}
										onClick={preventDefault(() => openEditAddressModal({ manageAddressMutation: manageAddress }))}
										data-qa="PanelAddressAction"
										aria-label={cmsContent[ cms.addAddressCtaAria ]}
									>
										<CmsContentRenderer.Span contentKey={cms.addAddressCta} fallbackValue="Add" />
									</Button>
								</div>

								<div className={panelStyle.info}>
									<div className={panelStyle.addressContainer}
										data-qa="PanelAddressAddress"
									>
										{loading && <div className={personalInformationStyle.spinnerWrapper}>
											<LoadingIcon overrideClass={personalInformationStyle.spinner} />
										</div>}

										<Addresses>{addresses => addresses.map(wsAddressExt => {

											const street = wsAddressExt.address1 || wsAddressExt.address2 || wsAddressExt.address3;

											return (
												<CmsContent
													key={wsAddressExt.addressId}
													contentKey={cms.manageAria}
													variables={{ street }}
												>{({ content: manageAddressAriaLabel }) => (
														<div
															className={cx(personalInformationStyle.addressRow, loading && personalInformationStyle.invisible)}>
															<AddressDisplay {...wsAddressExt} />
															<ManageMenu
																id={`address${wsAddressExt.addressId}ManageMenu`}
																ariaLabel={manageAddressAriaLabel}
																options={[
																	{
																		label: <CmsContentRenderer.Span
																			contentKey={cms.manageEdit}
																			fallbackValue="Edit address"
																		/>,
																		action: () => handleManageAddress({
																			option: OPTION_EDIT,
																			address: wsAddressExt,
																			callbackMutation: manageAddress,
																		}),
																	},
																	{
																		type: TYPE_DANGER,
																		label: <CmsContentRenderer.Span
																			contentKey={cms.manageDelete}
																			fallbackValue="Delete address" />,
																		action: () => handleManageAddress({
																			option: OPTION_REMOVE,
																			address: wsAddressExt,
																			callbackMutation: manageAddress,
																			isLastAddress: addresses.length === 1,
																			cmsContent,
																		}),
																	},
																]}
															/>
														</div>
													)}</CmsContent>
											);
										})}</Addresses>
									</div>
								</div>
							</div>
						)}</StandardMutation>
				</IsNotAlternate>
			</Panel>
		)}</CmsContentList>
	);
};

const AddressDisplay = ({
	address1 = '',
	address2 = '',
	address3 = '',
	city = '',
	state = '',
	postalCode = '',
	primaryShipping,
	primaryBilling,
}) => <div className={personalInformationStyle.addressDisplay}>
	{address1 && <div>{address1}</div>}
	{address2 && <div>{address2}</div>}
	{address3 && <div>{address3}</div>}
	{cityStateZipStr({ city, state, postalCode })}
	<div className={personalInformationStyle.defaultAddressDisplay}>
		{primaryShipping && <CmsContentRenderer.Div
			contentKey={cms.defaultShippingLabel}
			fallbackValue="This is the default shipping address"
		/>}
		{primaryBilling && <CmsContentRenderer.Div
			contentKey={cms.defaultBillingLabel}
			fallbackValue="This is the default billing address"
		/>}
	</div>
</div>;

export default PersonalInformation;
