import React from 'react';

import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer, { findContentOrFallback } from 'components/data/CmsContentRenderer.js';
import { useModalContext } from 'context/ModalProvider.js';

import Button, {
	Primary,
	Secondary,
	buttonTypeStylePlain,
} from 'components/Button.js';
import useFormHelper from "utils/form-helper/useFormHelper.js";
import Input from 'components/forms/Input.js';
import Modal from 'components/Modal.js';

import AddedAccountManagerSuccess from 'components/toasts/alternates/AddedAccountManagerSuccess.js';
import { object as yup_object, string as yup_string } from "yup";
import { BO_ERRORS, getMiscTextErrorKey } from "utils/GetErrorKey.js";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import PreventDefault from "utils/PreventDefault.js";
import { GET_ALTERNATES_AND_MANAGED_CUSTOMERS } from "components/data/alternates/Alternates.query.js";
import { gql } from "@apollo/client";
import CmsContentRendered from "components/data/CmsContentRendered.js";

import { useEulaSearch, EulaFeatures } from 'components/data/eulas/EulaSearch.js';

import * as modalStyles from 'components/Modal.module.css';
import Phone from "components/forms/Phone.js";
import * as formStyles from "components/forms/Forms.module.css";
import * as inputStyles from "components/forms/Input.module.css";
import { useGlobalToastsContext } from 'context/ToastProvider';
import EulaData from 'components/data/EulaData.js';
import LoadingIcon from 'components/icons/LoadingIcon';
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { GET_EMAIL } from "components/data/session-user/SessionUser.js";

const cms = {
	header: "miscText.alternates-add-header",
	email: "miscText.alternates-add-email",
	phone: "miscText.alternates-add-phone",
	agreementLabel: "miscText.alternates-add-agreement-label",
	agreementText: "miscText.alternates-add-agreement-text",
	agreementCta: "miscText.alternates-add-agreement-view-cta",
	cancel: "miscText.alternates-add-cancel",
	submit: "miscText.alternates-add-submit",
	confirmation: "miscText.alternates-add-confirmation",
	phoneNumberAriaLabel: 'miscText["register-info-phonetype.number"]',
	phoneCountryAriaLabel: 'miscText["register-info-phonetype.country"]',
	phoneRequired: 'miscText.alternates-add-error-phone',
	emailRequired: 'miscText.alternates-add-error-email',
	errorSelf: 'miscText.alternates-add-error-self',
};

/**
 * Data-QA Tags:
 * AddAcctManagerModalTitle
 * AddAcctManagerModalForm, AddAcctManagerModalLabel, AddAcctManagerModalInput, AddAcctManagerModalBtn
 */

export const INVITE_ALTERNATE = gql`
	mutation inviteAlternate(
		$email: String!
		$phone: String!
	) {
		CustomerMutationRoute {
			alternates {
				inviteAlternate(
					email: $email
					phone: $phone
				)
			}
		}
	}
`;

const AddAcctManager = ({ limitReached = false }) => {
	const { setModal } = useModalContext();
	const { setToast } = useGlobalToastsContext();
	const [ inviteAlternate ] = useStandardMutation(INVITE_ALTERNATE);
	const { data: emailData, loading: getEmailLoading } = useStdQuery(GET_EMAIL);

	const usersEmail = emailData?.session.customer.contact.email;

	const getYupSchema = () => {
		const validations = {
			email: yup_string()
				.email(getMiscTextErrorKey(BO_ERRORS.general.invalidEmail))
				.required(cms.emailRequired)
				.notOneOf([ usersEmail ], cms.errorSelf),
			phone: yup_string()
				.required(cms.phoneRequired),
		};

		return yup_object().shape(validations);
	};

	const {
		formRef,
		formHelper,
		setSubmitting,
		submitting,
	} = useFormHelper({ getYupSchema });

	const { eulas, response } = useEulaSearch({
		features: [ EulaFeatures.NEW_TRAVELER_ALTERNATE_USER ],
	});

	if (response.loading || getEmailLoading) {
		return <LoadingIcon />;
	}

	const eulaId = eulas[ 0 ]?.eulaId;

	if (limitReached) {
		return <Modal
			title={<CmsContentRendered.Span
				contentKey={cms.header}
				fallbackValue="Add someone who can manage your account"
			/>}
		>
			<CmsContentRendered
				contentKey={"miscHtml.alternates-add-limit"}
				rawHtml={true}
				fallbackValue={`
					<p>You have reached the maximum number of alternates or pending alternates for account management.</p>
					<p>Delete invites or alternates in order to add more.</p>
				`}
			/>
		</Modal>;
	}

	const onSuccess = ({ email }) => {
		setModal(null);
		setToast(<AddedAccountManagerSuccess email={email} />);
	};

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);

			await graphqlErrorMiddleware(inviteAlternate({
				refetchQueries: [
					{ query: GET_ALTERNATES_AND_MANAGED_CUSTOMERS },
				],
				variables: validated,
			}));

			onSuccess({ email: validated.email });
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (
			<Modal
				title={<CmsContentRenderer.Span
					contentKey={cms.header}
					fallbackValue="Add someone who can manage your account"
				/>}
			>
				<form
					data-qa="InviteAlternate"
					ref={formRef}
					onSubmit={PreventDefault(kickoffSubmit)}
				>
					{formHelper.getFieldErrorJsx('')}
					<Input
						type="email"
						name="email"
						error={formHelper.getFieldError('email')}
						label={<CmsContentRenderer
							contentKey={cms.email}
							fallbackValue="Their Charlie account email"
						/>}
					/>

					<Phone
						withHiddenInput={true}
						formHelper={formHelper}
						legend={<CmsContentRenderer
							contentKey={cms.phone}
							fallbackValue="Their Charlie account phone number"
							className={inputStyles.label}
						/>}
						name="phone"
						phoneNumberAriaLabel={findContentOrFallback(cmsContent, cms.phoneNumberAriaLabel, 'phone number')}
						phoneCountryAriaLabel={findContentOrFallback(cmsContent, cms.phoneCountryAriaLabel, 'phone number country')}
						className={formStyles.phoneInput}
					/>
					<div>
						<CmsContentRenderer
							className={formStyles.label}
							contentKey={cms.agreementLabel}
							fallbackValue="Authorization agreement" />
						<CmsContentRenderer
							contentKey={cms.agreementText}
							fallbackValue="I authorize this individual to make purchases on my account, view my travel and service history, and change my preferences."
						/>
						<div>
							{eulaId
								? <EulaData eulaId={eulaId} returnDocument={true}>{({ document: { document } }) =>
									<Button
										typeStyle={buttonTypeStylePlain}
										target="_blank"
										external
										to={document}
									>
										<CmsContentRenderer.Span
											contentKey={cms.agreementCta}
											fallbackValue="View the full agreement"
										/>
									</Button>
								}</EulaData>
								: null
							}
						</div>
					</div>

					<div className={modalStyles.actionButtons}>
						<Button
							type="button"
							typeStyle={Secondary}
							onClick={() => setModal(null)}
							additionalClassNames={cx(modalStyles.btn, modalStyles.leftButton)}
							data-qa="AddAccountManagerModalBtn"
						>
							<CmsContentRenderer.Span contentKey={cms.cancel} fallbackValue="Cancel" />
						</Button>

						<Button
							type="submit"
							typeStyle={Primary}
							submitting={submitting}
							additionalClassNames={cx(modalStyles.btn, modalStyles.rightButton)}
						>
							<CmsContentRenderer.Span contentKey={cms.submit} fallbackValue="Send Invite" />
						</Button>
					</div>
				</form>
			</Modal>
		)}</CmsContentList>
	);
};

export default AddAcctManager;
