import React, { useState } from "react";
import cx from "classnames";
import { values } from "lodash";

import CmsContentList from "../../data/CmsContentList";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import Collapsible from "components/Collapsible.js";
import Panel from "components/Panel.js";
import Checkbox from "components/forms/Checkbox.js";
import { BlueArrow, Lock } from "components/Icon.js";
import FormHelper from "utils/FormHelper";

import * as style from "./NotificationPreferences.module.css";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";



const cms = {
	subHeader: "miscText.notifications-subheader",
	subHeaderManaged: "miscText.notifications-managed-subheader",
	email: "miscText.notifications-email",
	sms: "miscText.notifications-sms",
	required: "miscText.notifications-required",
	requiredDescriptions: "miscHtml.notifications-required-description",
	optional: "miscText.notifications-optional",
	optionalDescription: "miscHtml.notifications-optional-description",
	account: "miscText.notifications-account",
	accountDescription: "miscHtml.notifications-account-description",
	order: "miscText.notifications-order",
	orderDescription: "miscHtml.notifications-order-description",
	usage: "miscText.notifications-usage",
	usageDescription: "miscHtml.notifications-usage-description",
};

const PREFERENCE_CATEGORY_NAMES = {
	required: 'required notifications',
	optional: 'optional notifications',
	account: 'account updates',
	order: 'order updates',
	balanceUsage: 'balance and usage',
};


const EMAIL_CHANEL = "EMAIL";
const SMS_CHANEL = "SMS";

class CategoryData {
	constructor() {
		this.emailCounts = { enabled: 0, disabled: 0 };
		this.smsCounts = { enabled: 0, disabled: 0 };
		this.notificationTypeKeys = [];
	}
}

class AdditionalSubCategoryData extends CategoryData {
	constructor(subCategoryName = null, subCategoryDescription = null) {
		super();
		this.subCategoryDisplayName = subCategoryName;
		this.subCategoryDisplayDescription = subCategoryDescription;
	}
}

const NotificationPreferences = ({
	updateNotifications,
	isPrimary = false,
	managedCustomerId = null,
	notificationPreferences: { contactId, firstName, preferences },
}) => {

	// When BO provides the data for this component, it is a long list of granular notification preferences: List<WSNotificationTypePreferenceInfo>.
	//
	// This component needs to aggregate these by category, subCategory, and channel in order to derive the notification categories we want (required, account updates, order updates, balance and usage) per the designs and display them to the user.
	// We also support BO adding new optional sub categories that may not yet have CMS content, and this component will render those dynamically with `additionalSubCategories`.
	//
	// Gotcha #1: The data I looked at was not consistent. In theory, after we aggregate into the groupings (category, subCategory, and channel) all of the notifications for a given channel (email or sms) should be ALL enabled or ALL disabled.
	// This is not the case IRL. In some cases the data is out of phase. Please see this Slack thread for context: https://reflexions.slack.com/archives/CCF68M49M/p1633983260357600?thread_ts=1633977005.355000&cid=CCF68M49M
	//
	// Per Jon Wandke's comments, this component handles that inconsitency by deriving a single boolean value for each checkbox by comparing how many in a given grouping are enabled vs. disabled.
	// Then when a user toggles a checkbox, we send all the `notificationTypeKeys` for a given grouping back to BO as ALL enabled or ALL disabled (such as it should be in a perfect world.)
	const normalizeString = str => str?.toLowerCase().trim();

	const buildInitialPreferencesObject = () => {
		const initialPreferences = {};
		for (const category of Object.keys(PREFERENCE_CATEGORY_NAMES)) {
			initialPreferences[ category ] = new CategoryData();
		}
		return initialPreferences;
	};

	const populateCategoryData = (categoryData, pref) => {
		let emailEnabled, smsEnabled;
		for (const channel of pref.channels) {
			switch (normalizeString(channel.channel)) {
				case 'email':
					emailEnabled = channel.enabled;
					break;
				case 'sms':
					smsEnabled = channel.enabled;
					break;
				default:
			}
		}
		categoryData.emailCounts[ emailEnabled ? 'enabled' : 'disabled' ]++;
		categoryData.smsCounts[ smsEnabled ? 'enabled' : 'disabled' ]++;
		categoryData.notificationTypeKeys.push(pref.notificationType);
	};

	const initialPreferences = {
		...buildInitialPreferencesObject(),
		additionalSubCategories: [],
	};

	const updateNotificationsCallback = ({ variables }) => updateNotifications({
		variables: {
			contactId,
			managedCustomerId,
			...variables,
		},
	});

	for (const pref of preferences) { // List<WSNotificationTypePreferenceInfo>
		const categoryName = normalizeString(pref.category.name);
		switch (categoryName) {
			case PREFERENCE_CATEGORY_NAMES.required:
				populateCategoryData(initialPreferences.required, pref);
				break;
			case PREFERENCE_CATEGORY_NAMES.optional:
				const subCategoryName = normalizeString(pref.subCategory.name);
				switch (subCategoryName) {
					case PREFERENCE_CATEGORY_NAMES.account:
						populateCategoryData(initialPreferences.account, pref);
						break;
					case PREFERENCE_CATEGORY_NAMES.order:
						populateCategoryData(initialPreferences.order, pref);
						break;
					case PREFERENCE_CATEGORY_NAMES.balanceUsage:
						populateCategoryData(initialPreferences.balanceUsage, pref);
						break;
					default:
						const categoryData = new AdditionalSubCategoryData(pref.subCategory.name, pref.subCategory.description);
						populateCategoryData(categoryData, pref);
						initialPreferences.additionalSubCategories.push(categoryData);
				}
				break;
			default:
		}
	}

	const {
		required: { notificationTypeKeys: requiredNotificationTypeKeys, smsCounts: requiredSmsCounts },
		account: {
			notificationTypeKeys: accountNotificationTypeKeys,
			emailCounts: accountEmailCounts,
			smsCounts: accountSmsCounts,
		},
		order: {
			notificationTypeKeys: orderNotificationTypeKeys,
			emailCounts: orderEmailCounts,
			smsCounts: orderSmsCounts,
		},
		balanceUsage: {
			notificationTypeKeys: balanceUsageNotificationTypeKeys,
			emailCounts: balanceUsageEmailCounts,
			smsCounts: balanceUsageSmsCounts,
		},
	} = initialPreferences;

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Panel overrideClass={style.panelWrapper}>
				<Collapsible
					initialOpenState={isPrimary}
					additionalClasses={style.container}
					toggleOpenState={<PanelHeader isPrimary={isPrimary} firstName={firstName} />}
					toggleClosedState={<PanelHeader isPrimary={isPrimary} firstName={firstName} collapse={false} />}
				>
					<div>
						<div className={cx(style.headerSection, style.body)}>
							<div className={cx(style.section, style.subHeaderLabel)}>
								<CmsContentRenderer.H4
									className={cx(style.leftColumn, style.mainHeader, style.body)}
									contentKey={cms.required}
									fallbackValue="Required"
								/>
								<PanelLabels />
							</div>

							<div className={style.section}>
								<div className={style.leftColumn}>
									<CmsContentRenderer.Div
										className={style.description}
										contentKey={cms.requiredDescriptions}
										fallbackValue="<p>Notifications related to key account events. You cannot unsubscribe from these.</p>"
									/>
								</div>

								<div className={style.rightColumn}>
									<div className={style.labels}>
										<Checkbox
											hideLabel={true}
											overrideClass={style.checkboxIcon}
											checked={true}
											locked={true}
											label={<MobileLabel locked={true} />}
										/>
									</div>
									<div className={style.labels}>
										<NotificationToggle
											channel={SMS_CHANEL}
											updateNotifications={updateNotificationsCallback}
											initialCheckedState={requiredSmsCounts.enabled >= requiredSmsCounts.disabled}
											notificationTypeKeys={requiredNotificationTypeKeys}
										/>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div className={cx(style.section, style.optionalSection)}>
								<div className={style.leftColumn}>
									<CmsContentRenderer.H4
										className={cx(style.mainHeader, style.body)}
										contentKey={cms.optional}
										fallbackValue="Optional"
									/>
									<CmsContentRenderer.Div
										className={cx(style.description, style.optional)}
										contentKey={cms.optionalDescription}
										fallbackValue="<p>Set your delivery method for all optional notifications below.</p>"
									/>
								</div>
								<PanelLabels />
							</div>

							<div className={cx(style.section, style.border)}>
								<div className={style.leftColumn}>
									<CmsContentRenderer.H4
										className={style.header}
										contentKey={cms.account}
										fallbackValue="Account Updates"
									/>
									<CmsContentRenderer.Div
										className={style.description}
										contentKey={cms.accountDescription}
										fallbackValue="<p>Changes to your account, including who can access it.</p>"
									/>
								</div>
								<div className={style.rightColumn}>
									<div className={style.labels}>
										<NotificationToggle
											channel={EMAIL_CHANEL}
											updateNotifications={updateNotificationsCallback}
											initialCheckedState={accountEmailCounts.enabled >= accountEmailCounts.disabled}
											notificationTypeKeys={accountNotificationTypeKeys}
										/>
									</div>
									<div className={style.labels}>
										<NotificationToggle
											channel={SMS_CHANEL}
											updateNotifications={updateNotificationsCallback}
											initialCheckedState={accountSmsCounts.enabled >= accountSmsCounts.disabled}
											notificationTypeKeys={accountNotificationTypeKeys}
										/>
									</div>
								</div>
							</div>

							<div className={cx(style.section, style.border)}>
								<div className={style.leftColumn}>
									<CmsContentRenderer.H4
										className={style.header}
										contentKey={cms.order}
										fallbackValue="Order Updates"
									/>
									<CmsContentRenderer.Div
										className={style.description}
										contentKey={cms.orderDescription}
										fallbackValue="<p>Includes updates when you reload or when a payment has been processed.</p>"
									/>
								</div>
								<div className={style.rightColumn}>
									<div className={style.labels}>
										<NotificationToggle
											channel={EMAIL_CHANEL}
											updateNotifications={updateNotificationsCallback}
											initialCheckedState={orderEmailCounts.enabled >= orderEmailCounts.disabled}
											notificationTypeKeys={orderNotificationTypeKeys}
										/>
									</div>
									<div className={style.labels}>
										<NotificationToggle
											channel={SMS_CHANEL}
											updateNotifications={updateNotificationsCallback}
											initialCheckedState={orderSmsCounts.enabled >= orderSmsCounts.disabled}
											notificationTypeKeys={orderNotificationTypeKeys}
										/>
									</div>
								</div>
							</div>

							<div className={cx(style.section, {
								[ style.border ]: initialPreferences.additionalSubCategories.length > 0,
							})}>
								<div className={style.leftColumn}>
									<CmsContentRenderer.H4
										className={style.header}
										contentKey={cms.usage}
										fallbackValue="Balance and Usage"
									/>
									<CmsContentRenderer.Div
										className={style.description}
										contentKey={cms.usageDescription}
										fallbackValue="<p>Includes reminders when a pass is expiring, when we’ve assumed the location of a missed tap, or when your balance is low.</p>"
									/>
								</div>
								<div className={style.rightColumn}>
									<div className={style.labels}>
										<NotificationToggle
											channel={EMAIL_CHANEL}
											updateNotifications={updateNotificationsCallback}
											initialCheckedState={balanceUsageEmailCounts.enabled >= balanceUsageEmailCounts.disabled}
											notificationTypeKeys={balanceUsageNotificationTypeKeys}
										/>
									</div>
									<div className={style.labels}>
										<NotificationToggle
											channel={SMS_CHANEL}
											updateNotifications={updateNotificationsCallback}
											initialCheckedState={balanceUsageSmsCounts.enabled >= balanceUsageSmsCounts.disabled}
											notificationTypeKeys={balanceUsageNotificationTypeKeys}
										/>
									</div>
								</div>
							</div>

							{initialPreferences.additionalSubCategories.map(({
								subCategoryDisplayName,
								subCategoryDisplayDescription,
								emailCounts,
								smsCounts,
								notificationTypeKeys,
							}, i) => (
								<div key={i} className={cx(style.section, {
									[ style.border ]: initialPreferences.additionalSubCategories.length - 1 !== i,
								})}>
									<div className={style.leftColumn}>
										<h4 className={style.header}>{subCategoryDisplayName ?? ''}</h4>
										<p className={style.description}>{subCategoryDisplayDescription ?? ''}</p>
									</div>
									<div className={style.rightColumn}>
										<div className={style.labels}>
											<NotificationToggle
												channel={EMAIL_CHANEL}
												updateNotifications={updateNotificationsCallback}
												initialCheckedState={emailCounts.enabled >= smsCounts.disabled}
												notificationTypeKeys={notificationTypeKeys}
											/>
										</div>
										<div className={style.labels}>
											<NotificationToggle
												channel={SMS_CHANEL}
												updateNotifications={updateNotificationsCallback}
												initialCheckedState={smsCounts.enabled >= smsCounts.disabled}
												notificationTypeKeys={notificationTypeKeys}
											/>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</Collapsible>
			</Panel>
		)}</CmsContentList>
	);
};

const NotificationToggle = ({ initialCheckedState, updateNotifications, notificationTypeKeys, channel }) => {
	const [ checked, setChecked ] = useState(initialCheckedState);
	const [ errorMessage, setErrorMessage ] = useState('');

	async function onSubmit(e) {
		setErrorMessage('');
		setChecked(e.target.checked);
		try {
			await graphqlErrorMiddleware(updateNotifications({
				variables: {
					enabled: e.target.checked,
					notificationTypeKeys,
					notificationChannel: channel,
				},
			}));
		} catch (error) {
			console.error(error);
			// FIX ME!!! I'm only placeholder text for unexpected errors while toggling checkboxes.
			// https://reflexions.slack.com/archives/CDTQPPXJ8/p1633718568260400
			setErrorMessage('There was a problem with your request. Please try again.');
		}
	}

	return <div className={cx({ [ style.errorCheckboxWrapper ]: !!errorMessage })}>
		<Checkbox
			onChange={e => onSubmit(e)}
			checked={checked}
			overrideClass={style.checkboxIcon}
			label={<MobileLabel contentKey={channel === EMAIL_CHANEL ? cms.email : cms.sms }  />}
		/>
		{errorMessage && FormHelper.errorJsx(errorMessage)}
	</div>;
};

const PanelHeader = ({ collapse = true, isPrimary, firstName }) => (
	<div className={style.headerSection}>
		<div className={cx(style.headerWrapper, collapse && style.collapse)}>
			<h4 className={style.mainHeader}>
				<CmsContentRenderedInline
					contentKey={isPrimary ? cms.subHeader : cms.subHeaderManaged}
					fallbackValue="Notifications for your account"
					variables={{ name: firstName }}
				/>
			</h4>
			<div className={cx(style.arrowWrapper, collapse && style.collapse)}>
				<BlueArrow />
			</div>
		</div>
	</div>
);

const PanelLabels = () => (
	<div className={cx(style.rightColumn, style.displayLabels)}>
		<CmsContentRenderer.Div
			className={style.labels}
			contentKey={cms.email}
			fallbackValue="Email"
		/>
		<CmsContentRenderer.Div
			className={style.labels}
			contentKey={cms.sms}
			fallbackValue="SMS/Text"
		/>
	</div>
);

const MobileLabel = ({ locked = false, contentKey = cms.email }) => {
	const isLeft = contentKey === cms.email;

	return ( <>
		{locked && <Lock overrideClass={style.lockIcon} />}
		<CmsContentRenderer.Span
			contentKey={contentKey}
			fallbackValue={isLeft ? "Email" : "SMS/Text"}
			className={cx(style.labels, isLeft ? style.mobileLabelLeft : style.mobileLabelRight)}
		/>
	</>
	);
};

export default NotificationPreferences;
