import { useApolloClient } from "@apollo/client";
import React, {
	useState,
	useRef,
} from 'react';
import { values } from 'lodash';

import {
	object as yup_object,
} from "yup";

import {
	levels,
	noticeError,
} from 'utils/Logger.js';

import FormHelper from "utils/FormHelper.js";
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { ALT_DATE_FORMAT } from 'utils/Constants.js';
import { getDateStringSync } from 'components/ServerDate.js';
import { cancelAutoloadMutation } from 'pages/account/CancelReload.js';

import Toast from "components/Toast.js";
import Modal from 'components/Modal.js';
import Button from "components/Button.js";

import * as style from 'components/modals/ResumeUseCard.module.css';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline";

const cms = {
	header: "miscText.autoload-manage-cancel-header",
	balanceDescription: "miscText.autoload-manage-cancel-description-balance",
	passDescription: "miscText.autoload-manage-cancel-description-pass",
	confirmSubmit: "miscText.autoload-manage-cancel-submit",
	balanceSuccessTitle: "miscText.autoload-manage-cancel-confirmation-balance",
	passSuccessTitle: "miscText.autoload-manage-cancel-confirmation-pass",
	cancelAutoloadError: 'miscText.',
};

const yupSchema = () => {
	return yup_object().shape({});
};

const DeleteAutoload = ({
	subsystemAccountReference,
	subscriptionId,
	onModalClose,
	isBalance,
	productName = null,
	expirationDateTime = null,
}) => {

	const { setToast, removeToast } = useGlobalToastsContext();
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});

	const formRef = useRef(null);

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));
	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, yupSchema);

	const apolloClient = useApolloClient();

	const date = !isBalance
		? getDateStringSync({
			dateISO: expirationDateTime,
			options: { day: "numeric", month: "long", year: 'numeric' },
			altFormat: ALT_DATE_FORMAT,
		})
		: null;

	const onSuccess = (cmsContent) => setToast(
		<Toast
			type="success"
			title={<CmsContentRenderedInline
				cmsContent={cmsContent}
				contentKey={isBalance ? cms.balanceSuccessTitle : cms.passSuccessTitle}
				fallbackValue={isBalance
					? 'Balance autoload has been canceled.'
					: `${productName} autoload has been canceled.`}
				variables={{ pass: productName }}
			/>}
			onClosed={removeToast}
		/>,
	);

	const kickoffSubmit = async (cmsContent) => {
		setSubmitting(true);

		try {
			await cancelAutoloadMutation(
				apolloClient,
				subscriptionId,
				subsystemAccountReference,
			);
		} catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Cancel reload subscription failed`);
			setValidationState({
				[ FormHelper.getStateErrorField('cancel-subscription') ]: (
					<CmsContentRenderer.Span
						contentKey={cms.cancelAutoloadError}
						fallbackValue={'An error occurred while trying to cancel autoload on this card.'}
					/>
				),
			});
			setSubmitting(false);

			// Error is consumed. Lets terminate the loop.
			return;
		}
		setSubmitting(false);
		onModalClose();

		onSuccess(cmsContent);
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal
				title={
					<CmsContentRenderer
						contentKey={cms.header}
						fallbackValue={"Confirm cancel autoload"}
					/>
				}
			>
				<CmsContentRendered.P
					id="confirmationTitle"
					className={style.boldStyle}
					contentKey={isBalance ? cms.balanceDescription : cms.passDescription}
					fallbackValue={isBalance
						? 'Your existing balance will remain on your account.'
						: `Your current ${productName} will remain valid through ${date}.`}
					variables={{ pass: productName, date }}
				/>
				<div className={style.actions}>
					<Button
						isPrimary
						type="submit"
						onClick={() => kickoffSubmit(cmsContent)}
						disabled={submitting}
					>
						<CmsContentRenderer.Span contentKey={cms.confirmSubmit}
							fallbackValue="Confirm"
						/>
					</Button>
				</div>
			</Modal>
		)}</CmsContentList>

	);
};

export default DeleteAutoload;
