import { clone } from "lodash";

// WSNotificationChannelPreferenceInfo represents the channels preferences for a notification type for a customer contact.
export default class WSNotificationChannelPreferenceInfo {
	constructor(obj = {}) {
		this.channel = obj.channel;
		this.enabled = obj.enabled;
		this.reqType = obj.reqType;
	}

	static fromBackoffice(data) {
		const result = new WSNotificationChannelPreferenceInfo(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSNotificationChannelPreferenceInfoType = [ `
	type WSNotificationChannelPreferenceInfo {
		channel: String!
		enabled: Boolean!
		reqType: String!
	}
` ];
