import React from 'react';
import PropTypes from 'prop-types';
import {
	join,
	map,
	values,
} from 'lodash';
import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { centsToDisplay , dateTimeToAbbreviatedMonthDayYear } from 'utils/FormatHelpers.js';
import { getGroupedModes, getTransitModeIcon } from 'components/icons/TransitModes.js';

import InCart from "components/account/products/InCart.js";
import { Caret } from 'components/Icon.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import { useLanguage } from "components/data/Language.js";
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';

import * as card from 'components/account/ProductCard.module.css';

import PublicAppVars from 'utils/PublicAppVars';
import { useProductCatalogContext } from "components/data/transit-account/ProductCatalogContext.js";


const cms = {
	currentBalance: 'miscText.purchase-balance-description1',
	learnMore: 'miscText.purchase-pass-learnmore-cta',
	firstTap: "miscText.cardinfo-pass-firsttap",
	selectionDescription: 'miscText.purchase-value-passes',
	startsOn: 'miscText.cardinfo-pass-starts',
	passPurchaseLimit: 'miscText.purchase-pass-limit',
};


const getProductUrl = (productId) => {
	return `${PublicAppVars.MBTA_CHARLIE_PASS_URL}${productId}`;
};

const Pass = ({
	wsTransitAccountProduct,
	isInCart = false,
	children,
}) => {
	const language = useLanguage();
	const { purseTotal } = usePurseBalanceContext();
	const { operators } = useProductCatalogContext();
	const groupedModes = wsTransitAccountProduct.permittedUsers ? getGroupedModes(wsTransitAccountProduct, operators) : null;

	return (
		<CmsContentList list={values(cms)}>{() =>
			<div className={card.cardContentClosed} key={wsTransitAccountProduct.id}>
				<div className={card.info}>
					<h3
						className={card.cardTitle}
						data-qa="ProductCardTitle"
					>
						{wsTransitAccountProduct.productLongName}

						{isInCart && <InCart />}
					</h3>
					<div className={card.amtDiv}>
						<p className={card.amountTitle}>
							{centsToDisplay(wsTransitAccountProduct.price)}
						</p>
						<div className={card.mediaContainer}>
							{groupedModes && map(groupedModes, (mode, key) => (
								<a key={key}
									data-qa={key}
									title={join(map(mode, wsSubsystemOperator => wsSubsystemOperator?.operatorName), ', ')}
									className={card.modeIcons}
								>
									{getTransitModeIcon(key)}
								</a>
							))}

							<Button external
								typeStyle={buttonTypeStylePlain}
								additionalClassNames={cx(card.learnMoreText, card.hideOnMobile)}
								to={getProductUrl(wsTransitAccountProduct.id)}
							>
								<CmsContentRenderedInline
									contentKey={cms.learnMore}
									fallbackValue='Learn more'
								/>

								<Caret overrideClass={card.learnMoreIcon} />
							</Button>
						</div>
					</div>
					<div className={card.details}>
						{wsTransitAccountProduct.transitValueOption
							? (
								<div>
									<CmsContentRenderedInline
										contentKey={cms.selectionDescription}
										className={card.hideOnMobile}
										fallbackValue="Selecting this option will add value to your balance."
									/>
									<CmsContentRendered.P
										contentKey={cms.currentBalance}
										fallbackValue={`Your current balance is ${centsToDisplay(purseTotal)} of a maximum ${centsToDisplay(PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE)} on this card.`}
										variables={{ balance: centsToDisplay(purseTotal), max: centsToDisplay(PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE) }}
									/>
								</div>
							)
							: wsTransitAccountProduct.validityStartDtm
								?<p>
									<CmsContentRenderedInline
										contentKey={cms.startsOn}
										fallbackValue={`Valid starting on ${dateTimeToAbbreviatedMonthDayYear(language, wsTransitAccountProduct.validityStartDtm)}`}
										variables={{ date: dateTimeToAbbreviatedMonthDayYear(language, wsTransitAccountProduct.validityStartDtm) }}
									/>
								</p>
								: <CmsContentRenderer.P contentKey={cms.firstTap} fallbackValue={'Valid from first tap'} />}
						{wsTransitAccountProduct?.maxAddPass
							? <div className={card.passPurchaseLimitWrapper}>
								<CmsContentRenderedInline
									contentKey={cms.passPurchaseLimit}
									className={card.passPurchaseLimit}
									fallbackValue="Purchase limit:"
								/>
								{wsTransitAccountProduct?.maxAddPass}
							</div>
							: null}
					</div>
				</div>

				<div className={card.actions}>
					{children}
				</div>
			</div>
		}</CmsContentList>
	);
};

Pass.propTypes = {
	wsTransitAccountProduct: PropTypes.object.isRequired,

	currentBalance: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),

	maxAddTransitValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),

	isInCart: PropTypes.bool,

	children: PropTypes.node,
};

export default Pass;
